@import 'legacy/legacy-core';

$date-range-table-spacing: to-rem($spacing-four-fifths);

/* ============================================================================
   @COMPONENTS -> DateRange
   ========================================================================= */

.date-range {
  @import '~react-day-picker/lib/style';
  font-size: to-rem($font-size-xxx-small);
  position: absolute;
  background-color: $color-white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.32);
  padding: to-rem($spacing-half) to-rem($spacing-base);
  margin-top: to-rem($spacing-half);
  z-index: index($z-index-elements-global, form-datepicker);
  right: 0;
  top: 0;
}

.date-range .DayPicker-wrapper {
  padding-bottom: 0;
}

.date-range .DayPicker .DayPicker-Day--selected {
  background-color: $color-orange-flamingo !important;
  opacity: 0.5;
  color: $color-white;
}

.date-range .DayPicker .DayPicker-Day {
  border-radius: 0 !important;
}

.date-range .DayPicker .DayPicker-Day--start,
.date-range .DayPicker .DayPicker-Day--end {
  opacity: 1;
}

.date-range .DayPicker .DayPicker-Day--end {
  opacity: 1;
}

.date-range .DayPicker .DayPicker-Day--outside {
  background-color: transparent !important;
}

.date-range .DayPicker-Caption > div {
  font-size: to-rem($font-size-xxx-small);
  text-transform: uppercase;
}

.date-range .DayPicker-Month {
  background-color: $color-white;
  margin-left: 0;
  margin-right: 0;
  margin-top: $date-range-table-spacing;
  margin-bottom: 0;
  width: 100%;

  @include respond-to(lap) {
    margin-left: $date-range-table-spacing;
    width: auto;
  }
}

.date-range .DayPicker-Month:first-child {
  @include respond-to(lap) {
    margin-left: 0;
  }
}

.date-range .DayPicker-NavButton {
  right: 0;
}

.date-range .DayPicker-Day--today {
  color: $color-blue-interaction;
}

.date-range__button {
  font-size: to-rem(12);
  background-color: transparent;
  border: 1px solid $color-grey-loblolly;
  border-radius: 3px;
  color: $color-blue-bismark;
  padding-left: to-rem($spacing-half);
  padding-right: to-rem($spacing-half);
  transition: all 0.3s ease;
  font-weight: $font-weight-bold;
}
