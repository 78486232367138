@import 'legacy/legacy-core';

/**
* Global styles for hiding the Intercom messenger on small devices.
*/

/* stylelint-disable-next-line selector-max-specificity */
@media (max-width: to-rem($breakpoint-medium)) {
  #intercom-container, .intercom-lightweight-app {
    display: none;
  }
}
