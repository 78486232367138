/* ============================================================================
   @COMPONENTS -> FORM
   ========================================================================= */

/**
 * Settings.
 */

$c-form-spacing-xx-small: 6;

$c-form-spacing-x-small: 12;

$c-form-spacing-small: 18;

$c-form-spacing-base: 24;

$c-form-spacing-large: 36;

$c-form-spacing-x-large: 48;

$c-form-spacing-xx-large: 60;

$c-form-footer-apply-at-breakpoints: (palm max) !default;

.c-form {
  @include respond-to(lap) {
    margin-right: to-rem(250);
  }
}

.c-form__header {
  font-size: to-rem(20);
}

/**
 * Legend.
 */

.c-form__legend {
  font-size: to-rem(20);
  font-weight: $font-weight-bold;
  margin-bottom: to-rem($c-form-spacing-large); // + 1/2
}

.c-form__legend--spacing-small {
  margin-bottom: to-rem($c-form-spacing-small);
}

.c-form__legend--grey {
  font-size: to-rem(20);
  margin-bottom: to-rem($c-form-spacing-small);
  color: $color-grey-slate;
}

/**
 * Form Row.
 */

.c-form__row {
  display: block;
  margin-bottom: to-rem($c-form-spacing-large); // + 1/2
  position: relative;
}

/**
 * Modifier: Row vertical spacing
 */

//tiny
.c-form__row--spacing-tiny {
  margin-bottom: to-rem($c-form-spacing-x-small);
}

//small
.c-form__row--spacing-small {
  margin-bottom: to-rem($c-form-spacing-small);
}

//x-large
.c-form__row--spacing-x-large {
  margin-bottom: to-rem($c-form-spacing-x-large);
}

//xx-large
.c-form__row--spacing-xx-large {
  margin-bottom: to-rem($c-form-spacing-xx-large);
}

/**
 * Form Element.
 */

.c-form-element {
  display: block;
  width: 100%;

  //change form control on error
  &.has-error {
    input,
    select,
    textarea,
    .c-fancy-select,
    .redactor-editor {
      border-color: $color-brand;
    }
  }

  //add space between element and error message
  .c-feedback {
    margin-top: to-rem(10);
  }
}

//make a form collapsible
.c-form--collapsible {
  display: none;

  &.is-visible {
    display: block;
  }
}

//make a form element collapsible
.c-form-element--collapsible {
  display: none;

  &.is-visible {
    display: block;
  }
}

/**
 * Modifier: Large
 */

.c-form-element--large {
  margin-bottom: to-rem($c-form-spacing-x-large); // x2 huge
}

.c-form-element--fancy {
  display: block;
  width: 100%;
  background-color: $color-grey-athens;
  border: 1px solid $text-input-and-select-border-color;
  border-radius: $border-radius * 1px;
  font-size: to-rem($form-elements-text-size);
  line-height: $line-height-ratio;
  margin: 0;
  position: relative;
  transition: background-color 0.4s ease-in-out;
  padding: to-rem(11);
  padding-right: to-rem(45);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  /**
   * Hover, focus and active states
   */

  &:hover,
  &:focus,
  &:active {
    border-color: $color-blue-bismark;
  }

  &--slim {
    width: 250px;
  }
}

.c-form-element__inner {
  background-color: $color-grey-athens;
  border: 1px dashed $color-grey-loblolly;
  padding: to-rem($c-form-spacing-small); // 3/4 of base
}

/**
 * Form Element: wysiwyg
 */

.c-form-element__wysiwyg {
  z-index: index($z-index-elements-main, form-wysiwyg);
}

/**
 * Form section divider
 */

.c-form__section {
  border-bottom: 3px solid $color-grey-mystic;
  margin-bottom: to-rem($c-form-spacing-xx-large); // x2 + 1/2
}

.c-form__section--custom {
  border-bottom: 1px solid $color-grey-loblolly;
  margin-bottom: to-rem($c-form-spacing-xx-large);

  &:last-child {
    border-bottom: none;
  }
}

.c-form__section--header {
  margin-bottom: to-rem($c-form-spacing-large);
  font-size: to-rem(20);
}

/**
 * Label
 */

.c-form-element__label {
  display: block;
  margin-bottom: to-rem($c-form-spacing-xx-small); // 1/4 of base
  position: relative;
  font-weight: $font-weight-bold;
}

/**
 * Modifier: Labels are not always bold
 */

.c-form-element__label--thin {
  font-weight: $font-weight-light;
}

/**
 * Modifier: Labels are not always on a separate line
 */

.c-form-element__label--inline {
  display: flex;
}

/**
 * Optional text label
 */

.c-form-element__optional-label {
  font-style: italic;
}

/**
 * Description
 *
 * N.B. text beyond what is provided by the `label` such as "optional"
 */

.c-form-element__label__description {
  display: block;
  font-size: to-rem(12); // base - 4
  font-weight: $font-weight-light;
  color: $color-grey-slate;
  margin-top: to-rem($c-form-spacing-xx-small);
}

.c-form-element__label__description--right-align {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-top: 0;
}

/**
 * Section Description
 *
 * N.B. text beyond what is provided by the `label` such as "optional"
 */

.c-form-element__description {
  display: block;
  font-size: to-rem(14); // base - 2
  color: $color-grey-slate;
}

/**
 * Form help
 */

.c-form__element__help {
  /**
   * Animation won't work with display: none;
   */

  //hide help by default
  display: none;

  &.is-visible {
    display: block;
  }

  &.is-invisible {
    display: none;
  }

  @include respond-to(lap, max) {
    margin-bottom: to-rem($c-form-spacing-small);

    /**
     * Extend `.c-help` in _c-help.scss.
     *
     * Make some visual changes to help when it appears in the linear flow of
     * a form.
     */

    .c-help {
      margin: 0;
      border-width: 0;
    }

    .c-help__arrow {
      display: none;
    }

    .c-help__content {
      padding: 0;
    }
  }

  /**
   * Position `c-help` component when removed from the linear flow of a form.
   */

  @include respond-to(lap) {
    position: absolute;
    top: 0;
    right: to-rem(-240);
    max-width: to-rem(200);

    /**
     * Position the inline help visually from the bottom
     * instead of the top
     */

    &.c-form__element__help--upload-project {
      top: to-rem(-120);

      .c-help__arrow {
        top: 50%;
      }
    }

    &.c-form__element__help--budget {
      top: to-rem(-80);
    }

    &.c-form__element__help--wide {
      max-width: to-rem(225);
    }
  }
}

/**
 * Control used in linear layout
 */

.c-form__element__help__control {
  display: none;

  @include respond-to(lap, max) {
    display: block;
    margin-bottom: to-rem($c-form-spacing-small);
  }
}

.c-button-faux__icon {
  .c-form__element__help__control.is-open & {
    transform: rotate(180deg);
  }
}

/**
 * Form Submit
 *
 */

.c-form__submit-terms {
  margin-top: to-rem($c-form-spacing-small);
}

/**
 * Budget Section
 */

.c-form__budget-flex {
  display: flex;
  flex: 1 0 0%;

  /**
   * Fall-back for non-supporting Flexbox browsers (IE9).
   */

  html:not(.flexboxtweener).no-flexbox & {
    display: inline-block;
  }
}

.c-form__budget-flex-wrapper {
  display: block;

  @include respond-to(450) {
    display: flex;
    flex: 1 0 0%;
  }

  @include respond-range(954, 1100) {
    display: block;
  }
}

.c-form__budget-flex__currency {
  @include respond-to(450) {
    margin-right: to-rem(12);
  }
}

.c-form-budget__flex-rate-unit {
  @include respond-to(450) {
    margin-left: to-rem(12);
  }

  @include respond-range(954, 1100) {
    margin-left: 0;
  }

  html:not(.flexboxtweener).no-flexbox & {
    margin-left: 0;
    width: to-rem(254);
  }
}

.c-form__budget-flex-up-to-425 {
  @include respond-to(425, max) {
    display: block;
  }
}

.c-form__budget-flex__fill-space {
  flex: 1 0 0%;

  html:not(.flexboxtweener).no-flexbox & {
    display: inline-block;
  }
}

.c-form__budget-flex__no-shrink {
  flex-shrink: 0;

  html:not(.flexboxtweener).no-flexbox & {
    display: inline-block;
    vertical-align: top;
  }
}

.c-form__budget-flex--vertical-center {
  align-self: center;
}

/**
 * Default textarea height
 */

.c-form__textarea {
  min-height: to-rem(130);
  border-color: $color-grey-mystic;
}

/**
 * Textarea for wysiwyg
 */

.c-form__textarea--wysiwyg {
  min-height: to-rem(280);
}

/**
 * Modifier: Single line textarea field
 */

.c-form__textarea--single-line {
  height: auto;
  min-height: auto;

  @include respond-to(lap) {
    height: to-rem(50);
    min-height: to-rem(50);
  }
}

/**
 * Modifier: Divide rows with a border and padding
 */

.c-form__row--divider {
  border-bottom: 1px solid $color-grey-mystic;
  margin-bottom: to-rem($c-form-spacing-large);

  &::after {
    display: block;
    content: '';
    height: 1px;
    background-color: $color-white;
    position: relative;
    top: 2px;
  }

  & > :last-child {
    margin-bottom: to-rem($c-form-spacing-base);
  }
}

/**
  * Align sticky strap buttons
  */

.c-form__sticky-strap {
  @include respond-to(lap) {
    margin-right: to-rem(250);
  }
}

/**
 * Modal form
 */

.c-form__row--with-background {
  background: $color-grey-athens-2;
}

.c-form__row--with-border-top {
  border-top: 1px solid $color-grey-mystic;
}

.c-form__row--footer {
  padding: to-rem($c-form-spacing-base);
  padding-top: 0;
  @include respond-to(palm, max) {
    padding-top: to-rem($c-form-spacing-base);
    background: $color-white;
    border-top: 1px solid $color-grey-mystic;
  }
  @include respond-to(lap) {
    background: $color-grey-athens-2;
  }
}

// fixed the form footer for mobile view
@include generate-at-breakpoints('.c-form__row--fixed-footer{bp}',
  $c-form-footer-apply-at-breakpoints) {
  position: fixed;
  bottom: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
  z-index: 2;
}

.c-form__nav {
  border-bottom: 1px solid $color-grey-loblolly;
  background-color: $color-white;
  padding: to-rem(20) to-rem(100);
  margin-bottom: to-rem(50);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include respond-to(palm, max) {
    padding: to-rem(20) to-rem(20);
  }
}

.c-form__alert {
  color: $color-white;
  height: to-rem(40);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: disappear 1.5s normal forwards ease-in-out;

  &--info {
    background-color: $color-green-asparagus;
  }

  &--danger {
    background-color: $color-orange-flamingo;
  }
}

@keyframes disappear {
  from {
    height: to-rem(40);
    opacity: 1;
  }

  to {
    height: 0;
    opacity: 0;
  }
}

.c-form__spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 30px;
  height: 15px;

  & .c-form__spinner__path {
    stroke: $color-white;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
