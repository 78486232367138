/* ============================================================================
   @COMPONENTS -> FANCY SELECT
   ========================================================================= */

/**
 * Applying custom styling to a `select`
 *
 * @credit
 * http://chriswrightdesign.github.io/customforms/select-box-svg/
 */

/**
 * Settings.
 */

$c-fancy-select-font-size: $form-elements-text-size;

$c-fancy-select-border-color: $text-input-and-select-border-color;

$c-fancy-select-border-color-active-state: $color-blue-bismark;

/**
 * Remove default styles for `select`s.
 */

.c-fancy-select select {
  position: relative;
  z-index: index($z-index-elements-main, fancy-select);
  background-color: transparent;
  border-color: none;
  background-image: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  font-size: to-rem($c-fancy-select-font-size);
  // padding of text inputs minus the border width
  padding: to-rem(11);
  padding-right: to-rem(45);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  transition: background 0.4s ease;
  // This is hardcoded to bypass the lack of support for appearance in autoprefixer
  /* stylelint-disable */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* stylelint-enable */
  appearance: none;
}

/**
 * Hide select arrow on IE 10
 */

.c-fancy-select select::-ms-expand {
  display: none;
}

/**
 * Remove compensation for fancy select control on IE9
 */

.lt-ie9 .c-fancy-select select {
  padding-right: to-rem(11);
}

/**
 * The fancy select, where the magic happens
 */

.c-fancy-select {
  background-color: $color-grey-athens;
  border: 1px solid $c-fancy-select-border-color;
  border-radius: $border-radius * 1px;
  cursor: pointer;
  font-size: to-rem($c-fancy-select-font-size);
  font-weight: $font-weight-bold;
  line-height: $line-height-ratio;
  margin: 0;
  overflow: hidden;
  position: relative;
  transition: background-color 0.4s ease-in-out;

  /**
   * Hover, focus and active states
   */

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    border-color: $c-fancy-select-border-color-active-state;
  }

  &--slim {
    max-width: to-rem(250);
  }
}

/**
 * Control.
 */

.c-fancy-select__control {
  @extend %u-position-bottom-right;
  display: block;
  width: to-rem(40);
  border-left: 1px solid $c-fancy-select-border-color;
  height: 100%;
  z-index: index($z-index-elements-main, fancy-select-control);
}

.c-fancy-select__control__icon {
  @extend %u-position-middle;
  fill: $color-blue-bismark;
  //TODO: create modifier for non square icons.
  height: auto; //12px;
  width: 14px;
}

/**
 * Hide fancy select control for IE9
 */

.lt-ie9 .c-fancy-select__control {
  display: none;
}

.c-fancy-select--currency-picker {
  width: to-rem(100);
}

.c-fancy-select--rate-unit-picker,
.c-fancy-select--duration-unit-picker {
  width: to-rem(160);
}
