/* ============================================================================
   @COMPONENTS -> CARD
   ========================================================================= */

/**
 * Cards are used to apply a container around a related group of information.
 */

/**
 * Settings
 */

$c-card-spacing: $spacing-base;

$c-card-spacing-x-small: $spacing-half;

$c-card-avatar-image-huge: 162;

$c-card-avatar-offset: 64;

.c-card {
  background-color: $color-white;
  border-bottom: 2px solid $color-grey-mystic;
  border-radius: to-rem($border-radius);
  position: relative;
}

/**
 * Modifier: Spacing - All sides
 */

.c-card--all-sides-spacing {
  padding: to-rem($c-card-spacing);
}

/**
 * Modifier: Spacing - Vertical Small
 */

.c-card--spacing-vertical-x-small {
  padding-top: to-rem($c-card-spacing-x-small);
  padding-bottom: to-rem($c-card-spacing-x-small);
}

/**
 * Modifier: Bottom Spacing
 */

.c-card--spacing-bottom-large {
  padding-bottom: to-rem($spacing-base-plus-half);
}

.c-card__avatar {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  top: -45px;

  @include respond-to(745) {
    position: static;
    transform: none;
  }

  /**
   * Extend `.c-avatar` into `.c-card`
   */

  @include respond-to(745) {
    .c-avatar {
      height: to-rem($c-card-avatar-image-huge);
      width: to-rem($c-card-avatar-image-huge);
    }

    .c-avatar__image {
      width: to-rem($c-card-avatar-image-huge);
    }

    .c-avatar__text {
      font-size: to-rem(72);
    }
  }
}

/**
 * Offset section to make space for avatar.
 */

.c-card__section--with-avatar-offset {
  padding-top: to-rem($c-card-avatar-offset);
  text-align: center;

  @include respond-to(745) {
    padding-top: 0;
    text-align: left;
  }
}

/**
 * Divider
 */

.c-card__divider {
  border-bottom: 1px solid $color-grey-mystic;
}

.c-card__vertical-divider {
  border-right: 1px solid $color-grey-mystic;
}

.c-card__divider-hide-from-745 {
  border-bottom: 1px solid $color-grey-mystic;
  @include respond-to(745) {
    border-bottom: none;
  }
}

.c-card__vertical-divider-hide-up-to-745 {
  border-right: none;
  @include respond-to(745) {
    border-right: 1px solid $color-grey-mystic;
  }
}

.c-card__divider-hide-from-lap-large {
  border-bottom: 1px solid $color-grey-mystic;
  @include respond-to(lap-large) {
    border-bottom: none;
  }
}

.c-card__vertical-divider-hide-up-to-lap-large {
  border-right: none;
  @include respond-to(lap-large) {
    border-right: 1px solid $color-grey-mystic;
  }
}

/**
 * Dash border
 */

.c-card__section--with-border {
  border: 1px dashed $color-grey-loblolly;
}

.c-card__status-badge {
  position: absolute;
  top: 0;
  right: 0;
}

/**
 * Modifier: dark
 */

.c-card--dark {
  background-color: $color-grey-athens;
}

@include respond-to(lap-large, max) {
  .c-card--dark-up-to-lap-large {
    background-color: $color-grey-athens;
  }
}

/**
  * Light background color
  */

.c-card--color-light {
  background-color: $color-grey-athens;
}

/**
  * Stripe background color
  */

.c-card--color-stripe {
  background-color: $color-blue-danube;

  .body-copy {
    color: $color-white;
  }
}
