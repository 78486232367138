/* ============================================================================
   @COMPONENTS -> MODAL
   ========================================================================= */

/**
 * Full viewport modal dialog.
 */

/**
 * Settings.
 */

$c-modal-fullscreen-apply-at-breakpoints: (palm max) !default;

/**
 * The underlay is the wrapper of the modal dialog which is used to
 * position the dialog in the middle of the viewport.
 */

/*! autoprefixer: off */
.c-modal-underlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  overflow-y: auto;
  width: 100%;
  background-color: rgba($color-black, 0.6);
  transition: opacity 0.2s ease;
  z-index: index($z-index-elements-global, modal-underlay);
  opacity: 0;

  /**
   * Fall-back for non-supporting Flexbox browsers (IE9).
   */

  html:not(.flexboxtweener).no-flexbox & {
    display: block;
  }

  /**
   * Modal is visible.
   */

  &.is-visible {
    opacity: 1;
  }

  /**
   * Modal is dismissed.
   *
   * We need to add this so that we can interact with the UI underneath the
   * modal dialog *after* it has been dismissed as we're using `opacity`.
   *
   * N.B. use `!important` here to make sure we're overriding the
   * `html:not(.flexbox) &` selector for non-supporting Flexbox browsers.
   */

  &.is-dismissed {
    display: none !important;
  }
}

/**
 * The modal dialog
 */

.c-modal-dialog {
  background-color: $color-white;
  opacity: 0;
  max-width: 100%;
  width: 100%;
  min-height: 100%;
  margin: 0;

  @include respond-to(lap) {
    border-radius: to-rem($border-radius);
    box-shadow: 0 to-rem(10) to-rem(30) 0 rgba($color-black, 0.3);
    margin: to-rem(30) auto;
    max-width: to-rem(500);
    width: 90%;
    height: auto;
    // height: auto doesn't cancel the effect of min-height that is set above
    // hence we need explicitly set it to zero
    min-height: 0;
  }

  /**
   * Fall-back for non-supporting Flexbox browsers (IE9).
   */

  html:not(.flexboxtweener).no-flexbox & {
    margin: 0 auto;
    margin-top: to-rem(30);
    margin-bottom: to-rem(30);
    max-width: to-rem(500);
  }

  /**
   * Modal is visible.
   */

  &.is-visible {
    opacity: 1;
  }
}

/**
 * Modifier: large
 */

.c-modal-dialog--width-large {
  max-width: to-rem(650);
}

/**
 * Modal header
 */

.c-modal-dialog__header {
  position: relative;
  //magic
  min-height: 46px;
}

/**
 * Close
 */

.c-modal-dialog__close {
  @extend %u-position-top-right;
  @extend %o-button;
  // Increase the hit area
  padding: to-rem(12);
  font-weight: $font-weight-bold;
  color: $color-black;
  opacity: 0.2;
  text-shadow: 0 1px 0 $color-white;

  &:hover,
  &:focus {
    opacity: 0.5;
  }
}

/**
 * Modal content
 */

.c-modal-dialog__content {
  //more magic
  padding-left: to-rem(24); //base
  padding-right: to-rem(24); //base
  padding-bottom: to-rem(36); //base + 1/2
}

/**
 * This is all non-modal dialog UI that sits underneath the modal dialog when
 * it's launched. We need to prevent it from scrolling vertically when the
 * modal is launched.
 */

.c-modal-dialog-underneath.is-modal-visible {
  height: 100vh;
  overflow: hidden;
}

/**
 * Success Message
 */

.c-modal-dialog__success-message {
  display: none;

  &.show {
    display: block;
  }
}
