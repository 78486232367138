/* ============================================================================
   @COMPONENTS -> PAGINATION
   ========================================================================= */

/**
 * Pagination.
 */

/**
 * Settings.
 */

$c-pagination-item-background-colour: $color-white;

$c-pagination-item-foreground-colour: $color-blue-bismark;

$c-pagination-item-border-colour: $color-grey-mystic;

.c-pagination {
  @extend %o-list-inline;
  // Hack to remove whitespace between the `li`s
  font-size: 0;
}

/**
  * Pagination item.
  */

.c-pagination__item {
  &.last-child {
    a {
      margin-right: 0;
    }
  }

  &.disabled {
    a {
      opacity: 0.5;
    }
  }
}

/**
  * Pagination link.
  */

.c-pagination__item a,
.c-pagination__item__link {
  background-color: $c-pagination-item-background-colour;
  border-bottom: 1px solid $c-pagination-item-border-colour;
  color: $c-pagination-item-foreground-colour;
  display: block;
  padding: to-rem(5) to-rem(10);

  // Reapply font size due to hack above
  @include font-size($font-size-small, none);
  margin-right: to-rem($spacing-quarter);

  &:hover,
  &:focus {
    border-color: $c-pagination-item-foreground-colour;
    color: $c-pagination-item-foreground-colour;
  }

  /**
   * Current page.
   */

  &.c-pagination__item__link--active {
    background-color: $c-pagination-item-foreground-colour;
    border-color: $c-pagination-item-foreground-colour;
    color: $c-pagination-item-background-colour;
  }

  /**
    * Modifier: Previous item.
    */

  &.c-pagination__item__link--prev {
    margin-right: to-rem($spacing-three-quarters);
  }

  /**
    * Modifier: Next item.
    */

  &.c-pagination__item__link--next {
    margin-left: to-rem($spacing-half);
  }
}
