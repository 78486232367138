/* ============================================================================
   @CORE -> BASE -> MEDIA
   ========================================================================= */

/**
 * Settings.
 */

$apply-responsive-images: true !default;

$google-map-selector-to-turn-off-responsive-images: '.gm-style' !default;

/**
 * Remove the gap between media elements and the bottom of their containers.
 *
 * @credit
 * http://html5boilerplate.com/
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Images.
 *
 * 1. Make responsive.
 * 2. So that `alt` text is visually offset if images don't load.
 */

img {
  @if $apply-responsive-images {
    max-width: 100%; // [1]
    height: auto; // [1]

    /**
     * Google Maps breaks if `max-width: 100%` acts upon it; use their
     * selector to turn this off.
     */

    #{$google-map-selector-to-turn-off-responsive-images} & {
      max-width: none;
    }
  }

  font-style: italic; // [2]
}

/**
 * If responsive images are turned off but you still need to apply it in
 * certain cases.
 */

.img-responsive {
  max-width: 100%;
  height: auto;
}

/**
 * If responsive images are turned on but you need to turn it off in certain
 * cases.
 */

.img-not-responsive {
  max-width: none;
}

/**
 * Set the default behaviour for touch-based browsing in IE 10 on devices
 * running Windows 8.
 */

canvas {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -ms-touch-action: double-tap-zoom;
}
