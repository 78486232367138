/* ============================================================================
   @COMPONENTS -> BLOCK BADGE
   ========================================================================= */

/**
* Padding
*/
$c-block-badge-padding-ends: 18;

$c-block-badge-padding-sides: 12;

$c-block-badge-padding-ends-small: 12;

$c-block-badge-padding-ends-tiny: 8;

$c-block-badge-padding-sides-small: 6;

$c-badge-block-border-radius: 3;

.c-block-badge {
  align-items: center;
  background-color: transparent;
  border-radius: to-rem($c-badge-block-border-radius);
  color: $color-blue-bismark;
  display: inline-flex;
  font-size: to-rem($font-size-x-small);
  padding: to-rem($c-block-badge-padding-ends)
    to-rem($c-block-badge-padding-sides);
  transition: background-color 200ms ease-in;
  line-height: 0;
  white-space: nowrap;
}

/**
 * Modifier: Includes a decorative icon
 */
.c-block-badge--icon {
  padding-left: to-rem(12);

  .c-block-badge__text {
    padding-left: to-rem(12);
  }
}

/**
 * Modifier: Dismissible button on the right
 */

.c-block-badge--dismissible {
  border: 1px solid $color-grey-mystic;
  padding-right: 0;

  .c-icon-only-button {
    width: auto;
    height: auto;
    padding: 0 to-rem($spacing-three-quarters);
  }

  &:hover .c-icon-only-button {
    color: $color-orange-flamingo;
  }

  &:hover {
    background-color: $color-white;
  }
}

/**
 * Modifier: Small button
 */

.c-block-badge--small {
  padding: to-rem($c-block-badge-padding-ends-small)
    to-rem($c-block-badge-padding-sides-small);
  font-size: to-rem($font-size-xxx-small);
  font-weight: $font-weight-bold;
}

/**
 * Modifier: Tiny button
 */

.c-block-badge--tiny-up-to-palm {
  padding-top: to-rem($c-block-badge-padding-ends-tiny);
  padding-bottom: to-rem($c-block-badge-padding-ends-tiny);

  @include respond-to(lap) {
    padding-top: to-rem($c-block-badge-padding-ends-small);
    padding-bottom: to-rem($c-block-badge-padding-ends-small);
  }
}

/**
 * Modifier: background colours
 */

.c-block-badge--hired {
  background-color: $color-green-deep-sea;
  color: $color-white;
}

.c-block-badge--interviewed {
  background-color: $color-grey-mystic;
  color: $color-grey-slate;
}

.c-block-badge--fav {
  background-color: $color-grey-mystic;
  color: $color-grey-slate;

  &.c-block-badge--small {
    padding-top: to-rem($c-block-badge-padding-sides-small);
    padding-bottom: to-rem($c-block-badge-padding-sides-small);
  }
}
