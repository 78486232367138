/* ============================================================================
   @COMPONENTS -> BUTTON DROPDOWN
   ========================================================================= */

$c-button-dropdown-modal-offset: 58;
$c-button-dropdown-initial-state: 0.01, 0.5;
$c-button-dropdown-final-state: 1, 1;

@mixin modal-transition($state) {
  opacity: nth($state, 1);
  transform: scale(nth($state, 2));
  transition: all 200ms ease-in;

  @include respond-to(lap) {
    transform-origin: 100% 0;
  }
}

/**
 * Dropdown container
 */
.c-button-dropdown {
  position: relative;
  display: inline;
}

/**
 * Toggle
 */

.c-button-dropdown__toggle {
  border-left: 1px solid transparent;

  .c-icon-only-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

/**
 * Actions list
 */

.c-button-dropdown__actions-list > :first-child {
  border-bottom: 1px solid $color-grey-loblolly;
  padding-bottom: to-rem($spacing-half);
  margin-bottom: to-rem($spacing-half);
}

/**
 * Dialog container
 */

.c-button-dropdown__modal-dialog {
  background-color: $color-white;
  position: absolute;
  right: 0;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.3);
  width: to-rem(244);
  z-index: index($z-index-elements-global, modal-underlay);

  @include respond-to(palm, max) {
    position: fixed;
    left: 0;
    top: to-rem($site-header-height);
    width: 100%;
    height: 100%;
  }

  .c-button {
    white-space: nowrap;
    display: inline-block;
    color: $color-grey-shark;
    font-weight: $font-weight-light;

    &:hover,
    &:focus {
      color: $color-orange-flamingo;
    }
  }

  // Transition
  @include modal-transition($c-button-dropdown-final-state);
}

/**
 * Transitions
 */

.c-button-dropdown__modal-dialog-enter {
  @include modal-transition($c-button-dropdown-initial-state);
}

.c-button-dropdown__modal-dialog-leave {
  @include modal-transition($c-button-dropdown-initial-state);
}
