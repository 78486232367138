/* ============================================================================
   @CORE -> BASE -> VIEWPORT
   ========================================================================= */

/**
 * Add optional `@viewport` rule to future proof.
 *
 * N.B. leave out `-ms-` prefix, see here:
 * http://nostrongbeliefs.com/why-i-think-we-shouldnt-use-css-viewport-in-ie10-for-now/
 */

/**
 * Settings.
 */

$apply-viewport-device-width: true !default;

@if $apply-viewport-device-width {
  @-webkit-viewport {
    width: device-width;
  }
  @-moz-viewport {
    width: device-width;
  }
  /* stylelint-disable-next-line at-rule-no-vendor-prefix */
  @-o-viewport {
    width: device-width;
  }
  @viewport {
    width: device-width;
  }
}
