/* ============================================================================
   @SETTINGS
   ========================================================================= */

/**
 * All project specific settings.
 */

/* Typography
   ========================================================================= */

$font-family-base: 'Lato', sans-serif;

/**
 * Neutralise all the heading size variables in favour of applying styles via
 * the `.heading` class and its modifiers.
 */

$font-size-heading-1: 20;
$font-size-heading-2: 20;
$font-size-heading-3: 20;
$font-size-heading-4: 20;
$font-size-heading-5: 20;
$font-size-heading-6: 20;

/**
 * Weights for custom font: Lato
 */

$font-weight-light: 400;

$font-weight-bold: 900;

/* Colours
   ========================================================================= */

/**
 * Color Set.
 */

// Purple
$color-purple-studio: #804eae;

// Yellow
$color-yellow-anzac: #ffb545;

// Orange
$color-orange-peach: #ff7a62;
$color-orange-flamingo: #f16439;

// Red
$color-red-soft-peach: #f7dada;
$color-red-rose-fog: #e0b6ad;

// Green
$color-green-apple: #e3f0df;
$color-green-asparagus: #78a60a;
$color-green-deep-sea: #008663;

// Blue
$color-blue-danube: #6ea5d7;
$color-blue-interaction: #2e71c9;
$color-blue-bismark: #41617d;
$color-blue-outer-space: #2b333b;

// Grey
$color-grey-athens: #f7f8fa;
$color-grey-athens-2: #f0f2f5;
$color-grey-mystic: #e1e6eb;
$color-grey-loblolly: #bec3cc;
$color-grey-nepal: #97b1c5;
$color-grey-slate: #697686;
$color-grey-shark: #21282e;

// Social Networks
$color-social-twitter: #55acee;
$color-social-facebook: #3b5999;
$color-social-linkedin: #1a85bc;
$color-social-googleplus: #ca2e29;

/**
 * Color Settings
 */

$color-brand: $color-orange-flamingo;
$color-text-base: $color-grey-shark;
$color-state-error: $color-orange-flamingo;
$color-state-success: $color-green-deep-sea;

/* Site Header
   ========================================================================= */

/**
 * Height of the header
 * This is a magic number and temporarily hard coded. Eventually we will look
 * at using JS to calculate this number.
 */

$site-header-height: 67;

$apply-responsive-images: true;