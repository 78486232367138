/* ============================================================================
   @COMPONENTS -> FORM FLEXBOX
   ========================================================================= */

/**
 * Forms flexbox.
 */

.c-form-flexbox {
  display: flex;
}

.c-form-flexbox__fill-space {
  flex-grow: 1;

  & + .c-form-flexbox__fill-space-gap {
    margin-left: to-rem($spacing-quarter);
  }

  html:not(.flexboxtweener).no-flexbox & {
    display: inline-block;
    vertical-align: top;
  }
}

.c-form-flexbox__no-shrink {
  flex-shrink: 0;

  html:not(.flexboxtweener).no-flexbox & {
    display: inline-block;
    vertical-align: top;
  }
}

/**
 * Form validation - Email Subscribe
 *
 * This will be re-factored, as it doesn't belong here. When we build out form
 * styles we will have another look at how we handle validation
 */

.c-form-flexbox-validation {
  display: none;

  &.is-visible {
    display: block;
    padding-top: to-rem($spacing-half);
  }
}
