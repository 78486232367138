/* ============================================================================
   @COMPONENTS -> ICON WITH CAPTION
   ========================================================================= */

/**
 * An icon with caption component for short snippets of text with an associated
 * icon. Typically this component is stacked at small breakpoints and appears
 * with the icon and text snippet side by side at larger breakpoints. The
 * component includes generated breakpoints to allow for controlling the layout
 * in a more flexible manner.
 */

/**
 * Settings
 */

$c-icon-with-caption-apply-at-breakpoints: 745;

$c-icon-with-caption-apply-at-breakpoints-for-inline: true;

/**
 * Icon with caption centered below
 */

.c-icon-with-caption {
  align-items: center;
  color: $color-grey-slate;
  display: flex;
  flex-direction: column;
  font-size: to-rem($font-size-x-small);
  justify-content: flex-start;
  text-align: center;

  @include respond-to(lap) {
    text-align: left;
  }
}

.c-icon-with-caption:hover,
.c-icon-with-caption:hover:focus {
  .c-icon-with-caption__caption,
  .c-icon-with-caption__icon {
    color: $color-brand;
  }
}


/**
 * Modifier: inline, icon and text side by side.
 */

.c-icon-with-caption--inline {
  align-items: center;
  flex-direction: row;
  text-align: left;

  .c-icon-with-caption__caption {
    margin-left: to-rem($spacing-half);
    margin-top: 0;
  }

  /**
   * Modifier: Smaller spacing
   */

  .c-icon-with-caption__caption--spacing-small {
    margin-left: to-rem($spacing-quarter);
  }
}

@if $c-icon-with-caption-apply-at-breakpoints-for-inline {
  @include generate-at-breakpoints('.c-icon-with-caption--inline', $c-icon-with-caption-apply-at-breakpoints) {
    align-items: center;
    flex-direction: row;
    text-align: left;

    .c-icon-with-caption__caption {
      margin-left: to-rem($spacing-half);
      margin-top: 0;
    }

    .c-icon-with-caption__caption--spacing-small {
      margin-left: to-rem($spacing-quarter);
      margin-top: 0;
    }
  }
}

/**
 * Modifier: caption is a heading
 * This is currently used on the "why join" strap on the homepage/services lps
 */

.c-icon-with-caption__caption {
  margin-top: to-rem($spacing-quarter);
}

.c-icon-with-caption__caption__icon {
  color: $color-blue-danube;
  width: to-rem(50);
  height: to-rem(50);
}

.c-icon-with-caption--with-heading {
  @include respond-to(lap) {
    text-align: center;
  }
}

.c-icon-with-caption--with-heading .c-icon-with-caption__caption {
  margin-bottom: to-rem($spacing-quarter);
  margin-top: to-rem($spacing-quarter);
  color: $color-grey-shark;
  font-size: to-rem($font-size);

  @include respond-to(lap) {
    margin-bottom: to-rem($spacing-half);
    margin-top: to-rem($spacing-base);
  }
}

.c-icon-with-caption--with-heading .c-icon-with-caption__caption__icon {
  width: to-rem(35);
  height: to-rem(35);

  @include respond-to(lap) {
    margin-top: to-rem(40);
    width: to-rem(50);
    height: to-rem(50);
  }
}
