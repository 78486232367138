/* ============================================================================
   @COMPONENTS -> BUTTON FAUX
   ========================================================================= */

/**
 * A button that looks like a link.
 *
 * @markup
   <button class="c-button-faux">Button text</button>

  <button class="c-button-faux">
    <svg class="c-button-faux__icon">...</svg>
    <span class="c-button-faux__text">...</span>
  </button>

  <button class="c-button-faux">
    <span class="c-button-faux-is-not-selected">
      <svg class="c-button-faux__icon">...</svg>
      <span class="c-button-faux__text">...</span>
    </span>
    <span class="c-button-faux-is-selected">
      <svg class="c-button-faux__icon">...</svg>
      <span class="c-button-faux__text">...</span>
    </span>
  </button>
 *
 * @dependency
 * This component is dependent on the Button object therefore it is
 * `@extend`ed from this component.
 */

.c-button-faux {
  @extend %o-button;
  @include base-link();
  align-items: center;
  display: inline-flex;
  font-size: to-rem($font-size-x-small);
  font-weight: $font-weight-bold;
  line-height: 1;
}

.c-button-faux__inner {
  display: inline-flex;
  align-items: center;
}

/**
 * Modifier: full bleed.
 */

.c-button-faux--full-bleed {
  display: block;
  width: 100%;
  text-align: left;
}

/**
 * Buttons with icons.
 */

.c-button-faux__icon {
  margin-right: to-rem(10);
}

// icon on the right
.c-button-faux__text + .c-button-faux__icon {
  margin-left: to-rem(10);
}

/**
 * Modifier: inverse.
 */

.c-button-faux--inverse {
  color: $link-color-hover;

  &:hover,
  &:focus {
    color: $link-color;
  }
}

/**
 * Modifier: Floater.
 */

.c-button-faux--float {
  color: $color-white;
  text-decoration: underline;
  font-weight: normal;

  &:hover,
  &:focus {
    color: $color-grey-athens;
  }
}

/**
 * Modifier: inline with copy
 */

.c-button-faux--inline {
  vertical-align: initial;
}

/**
 * Modifier: size base font size
 */

.c-button-faux--size-base {
  font-size: to-rem($font-size);
}

/**
 * Modifier: stateful.
 */

/**
 * State of content when button has *NOT* been selected.
 */

.c-button-faux--stateful .c-button-faux-is-not-selected {
  align-items: center;
  display: inline-flex;
}

.c-button-faux--stateful .c-button-faux-is-selected {
  display: none;
}

/**
 * State of content when button has been selected
 */

.c-button-faux--stateful.is-selected .c-button-faux-is-not-selected,
.c-button-faux--stateful.is-open .c-button-faux-is-not-selected {
  display: none;
}

.c-button-faux--stateful.is-selected .c-button-faux-is-selected,
.c-button-faux--stateful.is-open .c-button-faux-is-selected {
  align-items: center;
  display: inline-flex;
}
