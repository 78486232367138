.c-nav-tabs {
  border-bottom: 1px solid $color-grey-loblolly;

  .c-nav-tabs__select__control,
  .c-nav-tabs__select {
    display: none;
  }

  &.collapse {
    border-top: 1px solid $color-grey-loblolly;

    @include respond-to(lap-large) {
      border-top-width: 0;
    }

    .c-nav-tabs__select {
      color: $color-blue-bismark;
      font-weight: $font-weight-bold;
      display: flex;
      align-items: center;
      width: 100%;

      //separate select from nav list
      &.is-open {
        border-bottom: 1px solid $color-grey-loblolly;

        // FIXME need to reduce the specificity here.
        /* stylelint-disable-next-line selector-max-specificity */
        .c-nav-tabs__select__control {
          transform: rotate(180deg);
        }
      }

      @include respond-to(lap-large) {
        display: none;
      }
    }
    /**
    * Label.
    *
    * Currently selected nav item.
    */

    .c-nav-tabs__select__label {
      flex-grow: 1;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      text-align: left;
      padding-left: to-rem($spacing-base);
      padding-top: to-rem(18); // 3/4
      padding-bottom: to-rem(18); // 3/4
      margin-right: to-rem($spacing-base);
    }

    /**
    * Select control.
    */

    .c-nav-tabs__select__control {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      margin-right: to-rem($spacing-base);
    }

    .c-nav-tabs__inner {
      display: none;

      &.is-visible {
        display: block;
      }

      @include respond-to(lap-large) {
        // Desktop menu should always display
        // Not rely on javascript.
        display: block;

        // Hack to remove whitespace between the `li`s
        font-size: 0;
      }
    }

    .c-nav-tabs__item {
      display: block;
      border-bottom: 1px solid $color-grey-loblolly;

      &:last-child {
        border-bottom: 0;
      }

      @include respond-to(lap-large) {
        display: inline-block;
        border-bottom-width: 0;
        margin-left: 0;
        margin-right: 0;
      }
    }

    .c-nav-tabs__item__link {
      padding-left: to-rem($spacing-base);
      padding-right: to-rem($spacing-base);

      @include respond-to(lap-large) {
        color: $color-blue-bismark;
        padding-left: to-rem($spacing-half);
        padding-right: to-rem($spacing-half);
      }

      @include respond-to(desk) {
        padding-left: to-rem($spacing-base);
        padding-right: to-rem($spacing-base);
      }

      &:hover,
      &:focus {
        color: $color-brand;
      }

      &.is-active {
        color: $color-brand;
      }

      &::before {
        display: none;
      }

      @include respond-to(lap-large) {
        &::before {
          position: absolute;
          display: block;
          left: 0;
          right: 0;
          bottom: 0;
          content: '';
          height: 3px;
          background-color: $color-blue-bismark;
          opacity: 0;
          transform: translateY(100%);
          transition: transform 0.19s linear, opacity 0.19s;
        }

        &.is-active {
          color: $color-grey-shark;
        }
        /**
        * Hover and active states.
        */

        &:hover::before,
        &:focus::before,
        &.is-active::before {
          background-color: $color-brand;
          opacity: 1;
          transform: translateY(calc(-100% + #{3px}));
          transition: transform 0.19s linear, opacity 0.19s;
        }
      }
    }
  }
}

.c-nav-tabs__inner {
  text-align: left;

  @include respond-to(lap-large) {
    // Desktop menu should always display
    // Not rely on javascript.
    display: block;

    // Hack to remove whitespace between the `li`s
    font-size: 0;
  }

  .c-nav-tabs__item {
    display: inline-block;
    // Reapply font size due to hack above
    @include font-size($font-size-small, none);

    &:last-of-type {
      .c-nav-tabs__item__link {
        margin-right: 0;
      }
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

.c-nav-tabs__item__link {
  padding: to-rem($spacing-half);
  color: $color-blue-bismark;
  display: block;
  position: relative;
  text-decoration: none;
  text-transform: capitalize;
  text-align: left;

  @include respond-to(lap-large) {
    color: $color-blue-bismark;
  }

  @include respond-to(desk) {
    padding-left: to-rem($spacing-base);
    padding-right: to-rem($spacing-base);
  }

  /**
    * Hover and active states.
    */

  &:hover,
  &:focus {
    color: $color-brand;
  }

  &.is-active {
    color: $color-grey-shark;
  }

  /**
    * Line that appears below the links on hover/active states when linear.
    */
  &::before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    height: 3px;
    background-color: $color-blue-bismark;
    opacity: 0;
    transform: translateY(100%);
    transition: transform 0.19s linear, opacity 0.19s;
  }

  /**
    * Hover and active states.
    */

  &:hover::before,
  &:focus::before,
  &.is-active::before {
    background-color: $color-brand;
    opacity: 1;
    transform: translateY(calc(-100% + #{3px}));
    transition: transform 0.19s linear, opacity 0.19s;
  }
}
