/* ============================================================================
   @CORE -> BASE -> HEADINGS
   ========================================================================= */

/**
 * Here we scope our Base Heading styles to classes instead of the `hx` element
 * selector so our styles are not tied to the semantics of the heading element,
 * i.e. if you want a `h4` to look like a `h2` you can easily do this.
 * /


/**
 * Neutralise all the heading elements in favour of applying styles via the
 * `.heading` class and its modifiers. We make all the heading elements use the
 * default typography styles.
 */

@include headings(1, 6, true) {
  font-size: to-rem($font-size);
  font-weight: $font-weight-bold;
  line-height: $line-height-ratio;
}

/**
 * Base `.heading` styles.
 */

.heading {
  color: $color-grey-shark;
  font-size: to-rem(20);
  font-weight: $font-weight-bold;
  line-height: $line-height-ratio;
}

/**
 * Grey uppercase spaced
 */
.heading--grey-spaced {
  line-height: $line-height-ratio;
  font-size: to-rem($font-size-x-small);
  color: $color-grey-slate;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: to-rem(2);
}

/**
 * Modifiers: sizes.
 */

// Decrease
.heading--size-small {
  font-size: to-rem(18);
}

.heading--size-x-small {
  font-size: to-rem(16);
}

.heading--size-xx-small {
  font-size: to-rem(14);
}

.heading--size-xxx-small {
  font-size: to-rem(12);
  text-transform: uppercase;
}

// Increase
.heading--size-large {
  font-size: to-rem(24);
  line-height: 1.3;
}

.heading--size-x-large {
  font-size: to-rem(30);
}

.heading--size-xx-large {
  font-size: to-rem(48);
}

// Variable
.heading--size-x-small-to-base {
  font-size: to-rem(16);

  @include respond-to(lap) {
    font-size: to-rem(20);
  }
}

.heading--size-xx-small-to-x-small {
  font-size: to-rem(14);

  @include respond-to(lap) {
    font-size: to-rem(16);
  }
}

/**
 * Modifiers: weights.
 */

// Light
.heading--weight-light {
  font-weight: $font-weight-light;
}

// Bold
.heading--weight-bold {
  font-weight: $font-weight-bold;
}

/**
 * Modifiers: colours.
 */

// Light
.heading--color-light {
  color: $color-grey-slate;
}

// Medium
.heading--color-medium {
  color: $color-blue-bismark;
}

// Blue
.heading--color-blue-danube {
  color: $color-blue-danube;
}

/**
 * Modifier: Underline
 * A special style of heading with a partial underline
 */

.heading--underline {
  font-weight: $font-weight-light;
  padding-bottom: to-rem(10);
  text-transform: uppercase;

  &::after {
    background-color: $color-blue-danube;
    content: '';
    display: block;
    height: to-rem(2);
    margin-top: to-rem(5);
    width: to-rem(50);
  }
}

/**
 * Modifiers: line-height
 * Temporary to handle specific headings on subscription landing page
 */
.heading--line-height-1 {
  line-height: 1.1;
}
