/* ============================================================================
   @COMPONENTS -> TABLE
   ========================================================================= */

/**
 * A basic table with modifiers for borders and striped rows.
 */

/**
 * Settings
 */

$c-table-border-color: $color-grey-mystic;

$c-table-stripe-color: $color-grey-athens;

$c-table-cell-padding: $spacing-half;

$c-table-cell-padding-condensed: calc($c-table-cell-padding / 2);

$c-table-caption-alignment: left;

$c-table-caption-font-size: $font-size-xx-small;

$c-table-caption-margin: $spacing-half;

/**
 * Table
 */

.c-table {
  background-color: $color-white;
  font-size: to-rem($font-size-x-small);
  width: 100%;

  th,
  td {
    padding: to-rem($c-table-cell-padding);
    text-align: left;
  }
}

/**
 * Caption
 */

.c-table__caption {
  text-align: $c-table-caption-alignment;
  font-size: to-rem($c-table-caption-font-size);
  margin-bottom: to-rem($c-table-caption-margin);
}

/**
 * Table header.
 */

.c-table__header {
  border-bottom: 2px solid $c-table-border-color;
}

/**
 * Modifier: condensed
 */

.c-table--condensed {
  th,
  td {
    padding: to-rem($c-table-cell-padding-condensed);
  }
}

/**
 * Modifier: Rounded corners
 */

.c-table--rounded-borders {
  border-radius: to-rem($border-radius);
  border-spacing: 0;
  border-collapse: separate;
}

/**
 * Modifier: border around table when not putting a border around all the cells
 */

.c-table--border {
  border: 1px solid $c-table-border-color;
}


/**
 * Modifier: border around each table cell.
 */
.c-table--border-cells {
  th,
  td {
    border: 1px solid $c-table-border-color;
  }
}

/**
 * Modifier: divider between rows
 */

.c-table__body--row-divider {
  tr:first-child {
    td {
      border-top: 0;
    }
  }

  td {
    border-top: 1px solid $c-table-border-color;
  }
}

/**
 * Modifier: Striped rows
 */

.c-table--striped {
  tbody > tr:nth-of-type(odd) {
    background-color: $c-table-stripe-color;
  }
}
