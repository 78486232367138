/* ============================================================================
   @UTILITIES -> FADE
   ========================================================================= */

/**
 * A utility for fade-in and fade-out
 *
   .u-fade-in
   .u-fade-out
   .u-fade-out--half
 */


.u-fade__element {
  transition: 300ms ease-in-out opacity;
}

.u-fade-in {
  opacity: 1;
}

.u-fade-out {
  opacity: 0;
}

.u-fade-out--half {
  opacity: 0.5;
}
