/* ============================================================================
   @CORE -> BASE -> HORIZONTAL RULE
   ========================================================================= */

/**
 * Settings.
 */

$hr-color: $color-grey-loblolly;

$hr-style: solid !default;

$hr-thickness: 1 !default;

hr {
  @extend %bottom-spacing;
  display: block;
  border: 0;
  border-top: strip-unit($hr-thickness) * 1px $hr-style $hr-color;
  padding: 0;
}
