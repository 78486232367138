/* ============================================================================
   @UTILITIES -> DISPLAY
   ========================================================================= */


/**
 * A utility for applying the most common types of box renderings.
 *
 * All the utilities (available as silent placeholder selectors also):
 *
   .u-display-block
   .u-display-inline
   .u-display-inline-block
 */


/**
 * Settings.
 */

/**
 * Apply at these breakpoints (turned off by default).
 */

$u-display-apply-at-breakpoints:                      $default-breakpoints !default;

// From the above breakpoints choose which utilities you wish to apply it too
$u-display-apply-at-breakpoints-for-block:            false !default;

$u-display-apply-at-breakpoints-for-inline:           false !default;

$u-display-apply-at-breakpoints-for-inline-block:     false !default;


/**
 * Block.
 */

%u-display-block,
.u-display-block {
  display: block !important;
}

@if $u-display-apply-at-breakpoints-for-block {
  @include generate-at-breakpoints('.u-display-block',
    $u-display-apply-at-breakpoints) {
    display: block !important;
  }
}


/**
 * Inline.
 */

%u-display-inline,
.u-display-inline {
  display: inline !important;
}

@if $u-display-apply-at-breakpoints-for-inline {
  @include generate-at-breakpoints('.u-display-inline',
    $u-display-apply-at-breakpoints) {
    display: inline !important;
  }
}


/**
 * Inline-block.
 */

%u-display-inline-block,
.u-display-inline-block {
  display: inline-block !important;
}

@if $u-display-apply-at-breakpoints-for-inline-block {
  @include generate-at-breakpoints('.u-display-inline-block',
    $u-display-apply-at-breakpoints) {
    display: inline-block !important;
  }
}