/* ============================================================================
   @LAYOUT -> PAGE NAV
   ========================================================================= */

/**
 * Page nav is currently designed to mimic the desktop page level navigation in
 * the legacy UI, it includes a very basic responsive solution, that can be
 * refactored when we no longer need to maintain consistence with the legacy UI
 */

.l-page-nav {
  border-bottom: 1px solid $color-grey-loblolly;
  padding-bottom: to-rem($spacing-half);
}

/**
 * Inline list items
 */

.l-page-nav__list-item {
  display: inline-block;
}

/**
 * Nav link
 */

.l-page-nav__nav-item {
  color: $color-blue-bismark;
  display: block;
  font-size: to-rem($font-size-x-small);
  padding-left: to-rem($spacing-base);
  padding-right: to-rem($spacing-base);

  &:first-child {
    padding-left: 0;
  }

  /**
   * Hover and active states
   */

  &:hover,
  &:focus,
  &.is-active {
    color: $color-brand;
  }

  @include respond-to(lap) {
    font-size: to-rem($font-size);
  }
}

/**
 * Modifier: Bottom spacing large
 */

.l-page-nav--spacing-bottom-large {
  padding-bottom: to-rem($spacing-half);

  @include respond-to(lap) {
    padding-bottom: to-rem($spacing-base);
  }
}
