/* ============================================================================
   @COMPONENTS -> RATING LIST
   ========================================================================= */

/**
 * An inline list of stars to indicate a rating
 */

.c-rating-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: to-rem(100);
}

.c-rating-list__item {
  margin-left: to-rem($spacing-micro);
  margin-top: to-rem(-$spacing-micro);
}
