$c-upvote-button-default-border-color: $color-grey-loblolly;
$c-upvote-button-default-border-color-hover: $color-grey-slate;
$c-upvote-button-icon: $color-grey-slate;
$c-upvote-button-highlight-votes-bg: $color-green-deep-sea;
$c-upvote-button-has-votes-bg: $color-grey-slate;
$c-upvote-button-icon-hover: $color-green-deep-sea;
$c-upvote-button-padding: 5;

.c-upvote-button {
  @extend %o-button;
  border-radius: to-rem($border-radius);
  border: 1px solid $c-upvote-button-default-border-color;
  font-size: to-rem($font-size-xxx-small);
  font-weight: 700;
  line-height: 1;
  display: flex;
  align-items: stretch;

  &:hover,
  &:focus {
    border: 1px solid $c-upvote-button-default-border-color-hover;
  }
}

.c-upvote-button__label {
  font-weight: 700;
  border-top-right-radius: to-rem($border-radius);
  border-bottom-right-radius: to-rem($border-radius);
  padding-top: to-rem($c-upvote-button-padding + 2);
  padding-bottom: to-rem($c-upvote-button-padding + 2);
}

.c-upvote-button__count {
  padding-right: to-rem($c-upvote-button-padding * 1.5);
  padding-left: to-rem($c-upvote-button-padding * 1.5);
}

.c-upvote-button__text {
  padding-right: to-rem($c-upvote-button-padding);
  padding-left: to-rem(calc($c-upvote-button-padding/2));
  display: inline-block;
  line-height: 0.8;
}


.c-upvote-button__icon {
  color: $c-upvote-button-icon;
  padding-top: to-rem($c-upvote-button-padding);
  padding-bottom: to-rem($c-upvote-button-padding);
  padding-left: to-rem($c-upvote-button-padding);
  padding-right: to-rem(calc($c-upvote-button-padding/2));
}

.c-upvote-button--has-votes,
.c-upvote-button--highlight-votes {
  .c-upvote-button__icon {
    padding-right: to-rem($c-upvote-button-padding);
  }

  .c-upvote-button__label {
    color: $color-white;
  }

  &:hover,
  &:focus {
    .c-upvote-button__icon {
      color: $c-upvote-button-icon-hover;
    }
  }
}

.c-upvote-button--has-votes {
  .c-upvote-button__label {
    background-color: $c-upvote-button-has-votes-bg;
  }
}

.c-upvote-button--highlight-votes {
  .c-upvote-button__label {
    background-color: $c-upvote-button-highlight-votes-bg;
  }

  .c-upvote-button__text {
    font-weight: 400;
  }

  .c-upvote-button__count + .c-upvote-button__text {
    border-left: 1px solid rgba($color-white, 0.5);
    padding-left: to-rem($c-upvote-button-padding);
  }
}

.has-voted {
  .c-upvote-button__icon {
    color: $c-upvote-button-icon-hover;
  }
}
