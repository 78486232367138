.c-nav-tabs-secondary::-webkit-scrollbar {
  display: none;
}

.c-nav-tabs-secondary {
  text-align: left;
  height: to-rem(50);
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.c-nav-tabs-secondary__outer {
  position: relative;
}

.c-nav-tabs-secondary__inner {
  display: flex;
  flex-wrap: nowrap;
  height: to-rem(48);
}

.c-nav-tabs-secondary__item {
  display: inline-block;
  padding: 0 to-rem($spacing-base) 0 0;
  @include font-size($font-size-xx-small, none);

  &:last-child {
    padding-right: to-rem($spacing-base);
  }
}

.c-nav-tabs-secondary__item--underlined {
  display: inline-block;
  position: relative;
  padding: 0 to-rem($spacing-base) 0 0;
  @include font-size($font-size-xx-small, none);

  &:last-child {
    padding-right: to-rem($spacing-base);

    &::before {
      right: to-rem($spacing-base);
    }
  }

  &::before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    height: 2px;
    background-color: rgba(0, 0, 0, 0.16);
    transform: translateY(100%);
  }
}

.c-nav-tabs-secondary__item__link {
  padding: to-rem($spacing-half) 0;
  display: block;
  position: relative;
  text-decoration: none;
  text-transform: capitalize;
  font-family: Lato;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.85;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.6);

  /**
  * Hover and active states.
  */

  &:hover,
  &:focus {
    outline-width: 0;
    color: rgba(0, 0, 0, 0.92);
  }

  &.is-active {
    color: rgba(0, 0, 0, 0.92);
    font-weight: 900;
  }

  /**
  * Line that appears below the links on active state when linear.
  */
  &::before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    height: 2px;
    color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transform: translateY(100%);
  }

  /**
  * Active state.
  */
  &.is-active::before {
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 1;
    transition: transform 0.19s linear, opacity 0.19s;
  }
}

.c-nav-tabs-secondary__gradient-left {
  position: absolute;
  width: to-rem($spacing-base);
  height: to-rem(48);
  left: 0;
  top: 0;
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    $color-white
  );
  z-index: 100;
}

.c-nav-tabs-secondary__gradient-right {
  position: absolute;
  width: to-rem($spacing-base);
  height: to-rem(48);
  right: 0;
  top: 0;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    $color-white
  );
  z-index: 100;
}
