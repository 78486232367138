/* ============================================================================
   @UTILITIES -> ROUNDED
   ========================================================================= */

/**
 * Apply rounded corners to elements.
 */

.u-rounded,
%u-rounded {
  border-radius: $border-radius * 1px;
}
