/* lato-italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/lato-v16-latin-italic.eot');
  /* IE9 Compat Modes */
  src: local('Lato Italic'), local('Lato-Italic'),
    url('../fonts/lato-v16-latin-italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/lato-v16-latin-italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/lato-v16-latin-italic.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/lato-v16-latin-italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/lato-v16-latin-italic.svg#Lato') format('svg');
  /* Legacy iOS */
}

/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/lato-v16-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('Lato Regular'), local('Lato-Regular'),
    url('../fonts/lato-v16-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/lato-v16-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/lato-v16-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/lato-v16-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/lato-v16-latin-regular.svg#Lato') format('svg');
  /* Legacy iOS */
}

/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/lato-v16-latin-900.eot');
  /* IE9 Compat Modes */
  src: local('Lato Black'), local('Lato-Black'),
    url('../fonts/lato-v16-latin-900.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/lato-v16-latin-900.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/lato-v16-latin-900.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/lato-v16-latin-900.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/lato-v16-latin-900.svg#Lato') format('svg');
  /* Legacy iOS */
}

/* lato bold */
@font-face {
  font-family: 'Lato';
  font-style: bold;
  font-weight: 700;
  src: url('../fonts/lato-bold.eot');
  /* IE9 Compat Modes */
  src: local('Lato Bold'), local('Lato-Bold'),
    url('../fonts/lato-bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/lato-bold.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/lato-bold.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/Lato-Bold.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/lato-bold.svg#Lato') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/lato-bolditalic.eot');
  /* IE9 Compat Modes */
  src: local('Lato Bold Italic'), local('Lato-Bold'),
    url('../fonts/lato-bolditalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/lato-bold-italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/lato-bolditalic.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/Lato-BoldItalic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/lato-bolditalic.svg#Lato') format('svg');
  /* Legacy iOS */
}

/* lato-900italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/lato-v16-latin-900italic.eot');
  /* IE9 Compat Modes */
  src: local('Lato Black Italic'), local('Lato-BlackItalic'),
    url('../fonts/lato-v16-latin-900italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/lato-v16-latin-900italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/lato-v16-latin-900italic.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/lato-v16-latin-900italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/lato-v16-latin-900italic.svg#Lato') format('svg');
  /* Legacy iOS */
}