/* ============================================================================
   @COMPONENTS -> HELP
   ========================================================================= */

/**
 * Help component that appears as a link that toggles help at small
 * breakpoints and becomes a box with a directional indicator at larger
 * breakpoints
 */

.c-help {
  font-size: to-rem($font-size-x-small);
  border: 3px solid $color-grey-mystic;
  border-radius: to-rem($border-radius);
  margin: 12px;
  position: relative;
}

.c-help__arrow,
.c-help__arrow::after {
  position: absolute;
  // offset from top of box
  top: 36px;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 12px;
}

.c-help__arrow::after {
  content: '';
  // offset width of arrow
  top: -12px;
}

/**
 * Arrow on the left of the help box
 */
.c-help__arrow--left {
  border-right-color: $color-grey-mystic;
  border-left-width: 0;
  // arrow width + box border width
  left: -15px;
}

.c-help__arrow--left::after {
  border-right-color: $color-white;
  border-left-width: 0;
  // box border width + 1
  left: 4px;
}

/**
 * Arrow on the right of the help box
 */

.c-help__arrow--right {
  border-left-color: $color-grey-mystic;
  border-right-width: 0;
  // arrow width - box border width
  right: -15px;
}

.c-help__arrow--right::after {
  border-left-color: $color-white;
  border-right-width: 0;
  left: -16px;
}

/**
 * Content
 */

.c-help__content {
  padding: to-rem(10);

  @include respond-to(lap) {
    background-color: $color-white;
  }
}
