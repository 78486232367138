/* ============================================================================
   @COMPONENTS -> DIVIDER
   ========================================================================= */

/**
 * Divider typically used to break up main sections of a layout. The divider is
 * applied to the bottom of the containing element with options for various
 * spacing.
 */

.c-divider {
  border-bottom: 1px solid $color-grey-mystic;
  margin-bottom: to-rem($spacing-base);
  padding-bottom: to-rem($spacing-base);
}

.c-divider-up-to-745 {
  border-bottom: 1px solid $color-grey-mystic;
  margin-bottom: to-rem($spacing-base);
  padding-bottom: to-rem($spacing-base);

  @include respond-to(745) {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
