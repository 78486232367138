/* ============================================================================
   @COMPONENTS -> LOADING
   ========================================================================= */

/**
 * An icon spinner that indicates a pending network request
 */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/**
 * Loading container
 */

.c-loading {
  text-align: center;

  .c-icon {
    color: $color-orange-flamingo;
    fill: $color-grey-loblolly;
    animation: spin 2s infinite;
    animation-timing-function: linear;
    margin-top: to-rem($spacing-quarter * 3);
    margin-bottom: to-rem($spacing-quarter);
  }
}
