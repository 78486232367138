/* ============================================================================
   @COMPONENTS -> BADGE
   ========================================================================= */

/**
 * Badges add information. They typically are used to indicate a status.
 */

/**
 * Settings
 */

$c-badge-padding-ends: 3;

$c-badge-padding-sides: 12;

$c-badge-padding-all: 8;

$c-badge-padding-ends-airy: 6;

$c-badge-padding-sides-airy: 18;

$c-badge-icon-padding-ends: 0;

$c-badge-border-radius: 20;

$c-badge-block-border-radius: 3;

$c-badge-border-radius-collapsed: 20;

$c-badge-icon-gutter-size: 3;

/**
 * Animations
 */

@keyframes zoomInCollapsibleBadge {
  from {
    opacity: 0.75;
    transform: scaleX(0.12);
  }

  to {
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/**
 * Badge
 */

.c-badge {
  background-color: $color-grey-slate;
  border: 1px solid $color-grey-slate;
  border-radius: to-rem($c-badge-border-radius);
  color: $color-white;
  display: block;
  font-size: to-rem($font-size-xxx-small);
  line-height: 1;
  padding: to-rem($c-badge-padding-ends) to-rem($c-badge-padding-sides);
  white-space: nowrap;
}

.c-badge__text {
  vertical-align: middle;
}

/**
 * Badge with icons on the left
 */

.c-badge--with-icon {
  .c-badge__text {
    margin-left: to-rem($spacing-half);
  }
}

/**
 * Modifier: airy
 */

.c-badge--airy {
  padding: to-rem($c-badge-padding-ends-airy)
    to-rem($c-badge-padding-sides-airy);
}

/**
 * Modifier: wire.
 */

.c-badge--wire {
  background-color: transparent;
  border-color: $color-grey-loblolly;
  color: $color-grey-slate;
}

/**
 * Modifier: inline
 */

.c-badge--inline {
  border-radius: to-rem($c-badge-block-border-radius);
  padding: to-rem($c-badge-padding-all);
  display: inline-block;
}

/**
 * Modifier: counter
 */

.c-badge--counter {
  font-style: italic;
  font-weight: $font-weight-light;
  color: $color-orange-flamingo;
}

/**
 * Modifier: solid coloured "new"
 */

.c-badge--new {
  background-color: $color-green-deep-sea;
  border-color: $color-green-deep-sea;
}

/**
 * Modifier: solid coloured "sought after"
 */

.c-badge--sought-after {
  background-color: $color-blue-danube;
  border-color: $color-blue-danube;
}

/**
 * Modifier: solid coloured "tier"
 */

.c-badge--tier {
  background-color: $color-blue-danube;
  border-color: $color-blue-danube;
  padding: to-rem(3);
  margin-right: to-rem($spacing-quarter);
}

/**
 * Modifier: Collapsible.
 *
 * Collapsible badges contain icons, at lap they collapse to only show the icon
 * and expand when their container is hovered. The expanded state is shown at
 * mobile. The text in a collapsible badge needs to be wrapped in a span with
 * a class of `c-badge__text`
 *
 * This should not be mixed with the `c-badge--with-icon modifier`
 */

.c-badge--collapsible {
  padding-bottom: $c-badge-icon-padding-ends;
  padding-top: $c-badge-icon-padding-ends;
}

.c-badge-collapsible-container {
  display: block;
  position: relative;
}

.c-badge-collapsible-container .c-badge--collapsible {
  // Collapse badge by default at lap.
  @include respond-to(lap) {
    animation-duration: 0.4s;
    animation-fill-mode: both;
    border-radius: to-rem($c-badge-border-radius-collapsed);
    padding: 0;
  }
}
.c-badge-collapsible-container .c-badge--collapsible:hover {
  @include respond-to(lap) {
    animation-name: zoomInCollapsibleBadge;
    border-radius: to-rem($c-badge-border-radius);
    padding: to-rem($c-badge-icon-padding-ends)
      to-rem($c-badge-padding-sides);
  }
}

.c-badge-collapsible-container .c-badge--collapsible .c-badge__text {
  @include respond-to(lap) {
    display: none;
  }
}

.c-badge-collapsible-container .c-badge--collapsible .c-badge__text:hover {
  @include respond-to(lap) {
    animation-delay: 0.2s;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-name: fadeIn;
    display: inline-block;
    margin-left: to-rem($c-badge-icon-gutter-size);
  }
}