/* ============================================================================
   @CORE -> BASE -> ROOT
   ========================================================================= */

/**
 * The root element: `html`.
 */

html {
  font-size: calc(strip-unit($font-size) / 16) * 1em;
  font-weight: $font-weight-light;
  font-family: $font-family-base;
  color: $color-text-base;
  background: $color-white;
  line-height: strip-unit($line-height-ratio);

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
