/* ============================================================================
   @LAYOUT -> TEMPLATE -> HEADER ALT
   ========================================================================= */

/**
 * Minimal header used on landing pages
 */

/**
 * Settings
 */

$l-header-alt-logo-width: 80;

$l-header-alt-spacing-ends: $l-flex-grid-gutter-base;

.l-header-alt {
  background-color: $color-grey-shark;
  font-size: to-rem($font-size-x-small);
  opacity: 0;
  padding-left: to-rem($l-header-alt-spacing-ends);
  padding-right: to-rem($l-header-alt-spacing-ends);
  position: fixed;
  transform: translateY(-100%);
  transition: all 0.5s;
  width: 100%;
  z-index: index($z-index-elements-global, header);

  @include respond-to(lap) {
    padding-left: to-rem(30);
    padding-right: to-rem(30);
  }

  &.is-sticky {
    opacity: 1;
    transform: translateY(0%);
  }
}

/**
 * Inner
 */

.l-header-alt__inner {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  height: to-rem($site-header-height);
}

/**
 * Left side
 */

.l-header-alt__left {
  flex: 1 1 0%;
  text-align: left;
}

/**
 * Right side
 */

.l-header-alt__right {
  display: none;
  text-align: right;

  &.is-sticky {
    display: block;
  }
}

/**
 * Logo
 */
.l-header-alt__logo {
  display: block;
}

.l-header-alt__logo-img {
  width: to-rem($l-header-alt-logo-width);
}

/**
 * Inline Links
 */

.l-header-alt__link {
  color: $color-white;
  font-weight: $font-weight-light;

  &:hover {
    color: $color-white;
  }
}
