/* ==========================================================================
   COMPONENTS -> CHECKBOX BUTTON
   ========================================================================= */

/*
* `.c-checkbox-button`, which is a custom checkbox similar to `.c-checkbox`,
* modifies the display of the checkbox to look like a button. It
* has a rounded border and includes an icon.
*
* @markup
  <label class="c-checkbox-button">
    <input class="c-checkbox-button__input" id="[checkbox id]" name="[checkbox id]" type="checkbox">
    <svg class="c-checkbox-button__icon">[svg]</svg>
    <span class="c-checkbox__label-text">[label text]</span>
  </label>
*
*/

/**
 * Settings.
 */

$c-checkbox-button-icon-dimensions: $font-size-x-large;

$c-checkbox-button-padding-ends: $spacing-half;

$c-checkbox-button-padding-sides: $c-checkbox-button-icon-dimensions;

$c-checkbox-button-padding-sides-double: $c-checkbox-button-padding-sides * 2;

$c-checkbox-button-text-padding: calc($c-checkbox-button-icon-dimensions / 2);

/**
 * Checkbox button
 *
 * 1. Double spacing to create space around the icon.
 * 2. Half spacing to accommodate for button padding.
 */

/**
 * Hide input default input
 */

.c-checkbox-button__input {
  @extend %u-hide-visually;
}

.c-checkbox-button__label {
  background-color: $color-grey-athens-2;
  border-radius: to-rem($border-radius);
  margin-left: 0;
  padding-top: to-rem($c-checkbox-button-padding-ends);
  padding-bottom: to-rem($c-checkbox-button-padding-ends);
  padding-left: to-rem($spacing-half); // [1]
  padding-right: to-rem($spacing-half);
  border: 1px solid $color-grey-loblolly;
  color: $color-white;
  display: flex;
  align-items: center;

  &:hover {
    background-color: $color-white;

    .c-checkbox-button__icon-container {
      border: 0;
      background-color: $color-blue-danube;
    }
  }

  &:focus {
    border-color: $color-blue-danube;
  }
}

/**
 * Modifier center align button contents
 */

.c-checkbox-button--align-center {
  .c-checkbox-button__label {
    justify-content: center;
  }
}

/**
 * Icon
 */
.c-checkbox-button__icon-container {
  background-color: transparent;
  border: 1px solid $color-grey-loblolly;
  border-radius: to-rem(25);
  vertical-align: top;
  width: to-rem($c-checkbox-button-icon-dimensions);
  height: to-rem($c-checkbox-button-icon-dimensions);
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-checkbox-button__icon {
  color: transparent;
  width: to-rem(10);
  height: to-rem(10);
}

/**
 * Label text
 */

.c-checkbox-button__label-text {
  padding-left: to-rem($spacing-half);
  font-size: to-rem($font-size-x-small);
  color: $color-grey-shark;
}

/**
 * Input checked
 */

.c-checkbox-button__input:checked + .c-checkbox-button__label {
  background-color: $color-white;
}

.c-checkbox-button__input:checked
  + .c-checkbox-button__label
  .c-checkbox-button__icon-container {
  background-color: $color-blue-danube;
  border: 0;
}

.c-checkbox-button__input:focus + .c-checkbox-button__label {
  border-color: $color-blue-danube;
}

.c-checkbox-button__input:checked
  + .c-checkbox-button__label
  .c-checkbox-button__icon {
  color: $color-white;
}

.c-checkbox-button--checkbox {
  .c-checkbox-button__icon-container {
    border-radius: to-rem(3);
  }
}
