/* ============================================================================
   @COMPONENTS -> FEEDBACK
   ========================================================================= */

/**
 * Give users feedback as they interact with the page, primarily used for form
 * validation and global messaging. Feedback typically has four possible states
 * 'success', 'error', 'warning' and 'info'.
 */

.c-feedback {
  display: none;
  background-color: $color-white;
  border: 3px solid $color-black;
  border-radius: to-rem($border-radius);
  padding: to-rem(10);
  font-size: to-rem($font-size-small);
}

.c-feedback.is-visible {
  display: block;

  /**
   * Feedback positioning if a form fails validation when it hits the server.
   */

  .c-feedback__global-form & {
    margin-bottom: to-rem(10);
  }
}

/**
 * Message text
 */

.c-feedback__message {
  text-align: center;
  margin: 0;
}

/**
 * Feedback messages have the option of being dismissable by the user
 */

.c-feedback__dismiss {
  @extend %o-button;
  position: absolute;
  right: 0;
  top: 50% !important;
  transform: translateY(-50%);
  // Increase the hit area
  padding: to-rem(12);
  fill: $color-black;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
}

/**
 * Modifier: inline error message.
 */

.c-feedback--error {
  color: $color-state-error;
  border-color: $color-state-error;
}

/**
 * Modifier: inline success message.
 */

.c-feedback--success {
  color: $color-state-success;
  border-color: $color-state-success;
  padding-top: to-rem(15);
  padding-bottom: to-rem(15);
}

/**
 * Global messages are styled to match the legacy system and do not use the new
 * colour palette
 */

/**
 * Modifier: global success.
 */

.c-feedback--success-global {
  color: $color-white;
  background-color: $color-green-asparagus;
  border: 0;
  border-radius: 0;
  padding: to-rem(10) to-rem(20);
  text-align: center;
  position: absolute;
  width: 100%;
}

/**
 * Modifier: info bar.
 */

.c-feedback--info {
  color: $color-blue-outer-space;
  background-color: $color-grey-athens;
  border: 0;
  border-radius: 0;
  text-align: center;
  width: 100%;
  padding: to-rem(10) to-rem(20);
}

/**
 * Modifier: global error.
 */

.c-feedback--error-global {
  color: $color-grey-shark;
  background-color: $color-red-soft-peach;
  border: 0;
  border-bottom: 1px solid $color-red-rose-fog;
  border-radius: 0;
  padding: to-rem(10) to-rem(20);
  text-align: center;
  position: absolute;
  width: 100%;
}

.c-feedback-enter {
  opacity: 0.01;
}

.c-feedback-enter.c-feedback-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.c-feedback-leave {
  opacity: 1;
}

.c-feedback-leave.c-feedback-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.c-feedback--sticky {
  position: fixed;
  z-index: index($z-index-elements-global, sticky);
}
