/* ============================================================================
   @COMPONENTS -> COLLAPSIBLE BUTTON
   ========================================================================= */

/**
 * Collapsible Button
 *
 * A collapsible button will typically be an icon on mobile,
 * and will expand to a normal size button on larger screens.
 */

/**
 * Settings
 */

/**
 * Colours.
 */

$c-collapsible-button-disabled-state: $color-grey-loblolly;

$c-collapsible-button-secondary-selected-background-color: $color-blue-bismark;

$c-collapsible-button-secondary-foreground-color: $color-blue-bismark;

$c-collapsible-button-secondary-selected-foreground-color: $color-white;

/**
 * Padding.
 */
$c-collapsible-button-padding-ends-base: 16;

$c-collapsible-button-padding-sides-base: $spacing-base-plus-half; // 36

/**
 * Borders.
 */

$c-collapsible-button-rounded-corners-size: $border-radius;

/**
 * Sizes
 */

$c-collapsible-button-icon-only-width: 46;
$c-collapsible-button-icon-only-height: 46;

/**
 * Icons
 */

$c-collapsible-button-icon-horizontal-spacing: 10;

/**
 * Collapsible Button
 */

.c-collapsible-button {
  @extend %o-button;
  border-radius: to-rem($c-collapsible-button-rounded-corners-size);
  box-shadow: none;
  color: $c-collapsible-button-secondary-foreground-color;
  font-weight: $font-weight-bold;
  font-size: to-rem($font-size-x-small);
  height: to-rem($c-collapsible-button-icon-only-height);
  line-height: 1;
  text-align: center;
  transition: color, background 0.3s ease;
  width: to-rem($c-collapsible-button-icon-only-width);

  @include respond-to(745) {
    box-shadow: inset 0 0 0 1px $c-collapsible-button-secondary-foreground-color;
    height: auto;
    padding-top: to-rem($c-collapsible-button-padding-ends-base);
    padding-bottom: to-rem($c-collapsible-button-padding-ends-base);
    padding-left: to-rem($c-collapsible-button-padding-sides-base);
    padding-right: to-rem($c-collapsible-button-padding-sides-base);
    width: auto;
  }

  &:hover,
  &.is-active,
  &.is-selected {
    color: $c-collapsible-button-secondary-selected-background-color;
    opacity: 1;

    @include respond-to(745) {
      box-shadow: none;
      background-color: $c-collapsible-button-secondary-foreground-color;
      color: $c-collapsible-button-secondary-selected-foreground-color;

      .c-collapsible-button__icon {
        fill: $color-white;
      }
    }
  }

  @include respond-to(745) {
    &:focus {
      box-shadow: inset 0 0 0 2px
        $c-collapsible-button-secondary-foreground-color;
    }
  }

  &[disabled],
  &.is-disabled {
    color: $c-collapsible-button-disabled-state;
    //override Scally
    opacity: 1 !important;
  }

  // N.B. ugly hack in order to increase the specificity to override the
  // removal of rounded corners that iOS applies set in Core -> Base ->
  // Forms
  html & {
    border-radius: strip-unit($c-collapsible-button-rounded-corners-size) * 1px;
  }
}

/**
 * Button text
 */

.c-collapsible-button__text {
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
  opacity: 0;

  @include respond-to(745) {
    overflow: visible;
    position: relative;
    width: auto;
    opacity: 1;
    vertical-align: middle;
  }
}

/**
 * Button Icon
 */

.c-collapsible-button__icon {
  margin-right: 0;

  @include respond-to(745) {
    margin-right: to-rem($c-collapsible-button-icon-horizontal-spacing);
  }
}

.c-collapsible-button__text + .c-collapsible-button__icon {
  margin-left: 0;

  @include respond-to(745) {
    margin-right: 0;
    margin-left: to-rem($c-collapsible-button-icon-horizontal-spacing);
  }
}

/**
 * Modifier: Stateful
 */

.c-collapsible-button--stateful {
  .c-collapsible-button-is-not-selected {
    display: block;
  }

  .c-collapsible-button-is-selected,
  .c-collapsible-button-is-selected-hover {
    display: none;
  }
}

/**
 * Stateful button is selected
 */

.c-collapsible-button--stateful.is-selected {
  .c-collapsible-button-is-selected {
    display: block;
  }

  .c-collapsible-button-is-not-selected,
  .c-collapsible-button-is-selected-hover {
    display: none;
  }

  .c-collapsible-button__text {
    /* stylelint-disable-next-line no-unknown-animations */
    animation-name: fadeInFromTop;
    animation-duration: $c-button-animation-duration;
  }

  .c-collapsible-button__icon {
    /* stylelint-disable-next-line no-unknown-animations */
    animation-name: fadeInGrow;
    animation-duration: $c-button-animation-duration;
  }
}

/**
 * Stateful button is selected and hovered
 */

.c-collapsible-button--stateful.is-selected-hover {
  .c-collapsible-button-is-selected-hover {
    display: block;
  }

  .c-collapsible-button-is-not-selected,
  .c-collapsible-button-is-selected {
    display: none;
  }

  .c-collapsible-button__text {
    /* stylelint-disable-next-line no-unknown-animations */
    animation-name: fadeInFromBottom;
    animation-duration: $c-button-animation-duration;
  }

  .c-collapsible-button__icon {
    /* stylelint-disable-next-line no-unknown-animations */
    animation-name: fadeInGrow;
    animation-duration: $c-button-animation-duration;
  }
}

.c-collapsible-button--full-bleed {
  width: 100%;
}
