/* ============================================================================
   @CORE -> BASE -> PRINT
   ========================================================================= */

/**
 * All print styles outside of Core -> Base live in context with their
 * corresponding rule sets.
 *
 * N.B. it is okay to use `!important` here as we're doing it preemptively i.e.
 * you know you will always want the rule it's applied too to take precedence.
 *
 * @credit
 * http://html5boilerplate.com/
*/

@media print {
  /**
   * Apply print friendly styles to ALL elements.
   */

  *,
  *::before,
  *::after {
    background: transparent !important;
    color: $color-black !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  /**
   * Hide elements not really useful on a piece of paper.
   */

  nav,
  video,
  audio {
    display: none !important;
  }

  /**
   * Ensure ALL images are responsive.
   */

  img {
    max-width: 100% !important;
    height: auto !important;
  }

  /**
   * Make links always stand out.
   */

  a {
    &,
    &:visited {
      text-decoration: underline !important;
      border-bottom: 0 !important;
    }
  }

  /**
   * Show the `href` and `title` attribute values of links and abbreviations
   * in a pseudo element.
   */

  a[href]::after {
    content: ' (' attr(href) ')';
  }

  abbr[title]::after {
    content: ' (' attr(title) ')';
  }

  // But don't show for the following links
  a[href^='javascript:']::after,
  a[href^='#']::after {
    content: '';
  }

  /**
   * h5bp.com/t.
   */

  thead {
    display: table-header-group;
  }

  /**
   * Set page breaks, orphans and widows.
   */

  // Page breaks
  h2 h3 {
    page-break-after: avoid;
  }

  tr,
  img,
  pre,
  blockquote {
    page-break-inside: avoid;
  }

  // Orphans and widows
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
}
