 /* ============================================================================
    @LAYOUT -> CONTAINER
    ========================================================================= */


 /**
  * The main container that wraps all major layout pieces so that we keep all
  * layout consistent. The container is responsive in that it will be a fluid
  * width using '%' up until it hits it's maximum width. And it's center
  * aligned by default.
  *
  * @markup
    <div class="l-container [modifier(s)]"> [...] </div>
  */


 /**
  * Settings.
  */

 /**
  * Apply at these breakpoints (turned off by default).
  */

 $l-container-apply-at-breakpoints:                $default-breakpoints !default;

 // From the above breakpoints choose what you wish to apply it too
 $l-container-apply-breakpoints-for-default:       false !default;

 $l-container-apply-breakpoints-for-full-bleed:    false !default;

 $l-container-apply-breakpoints-for-not-centered:  false !default;

 /**
  * Widths.
  */

 $l-container-width:                               92% !default;

 $l-container-minimum-width:                       220 !default;

 $l-container-maximum-width:                       1600 !default;


 .l-container {
   width: $l-container-width;
   @include to-rem(min-width, $l-container-minimum-width);
   @include to-rem(max-width, $l-container-maximum-width);
   margin: 0 auto;

   // Print
   @media print {
     width: auto;
   }
 }

 @if $l-container-apply-breakpoints-for-default {
   @include generate-at-breakpoints('.l-container',
     $l-container-apply-at-breakpoints) {
     width: $l-container-width;
     @include to-rem(min-width, $l-container-minimum-width);
     @include to-rem(max-width, $l-container-maximum-width);
     margin: 0 auto;

     // Print
     @media print {
       width: auto;
     }
   }
 }


 /**
  * Modifier: full bleed.
  */

 .l-container--full-bleed {
   width: auto;
   max-width: none;
 }

 @if $l-container-apply-breakpoints-for-full-bleed {
   @include generate-at-breakpoints('.l-container--full-bleed',
     $l-container-apply-at-breakpoints) {
     width: auto;
     max-width: none;
   }
 }


 /**
  * Modifier: not centered.
  */

 .l-container--not-centered {
   margin-left: 0;
   margin-right: 0;
 }

 @if $l-container-apply-breakpoints-for-not-centered {
   @include generate-at-breakpoints('.l-container--not-centered',
     $l-container-apply-at-breakpoints) {
     margin-left: 0;
     margin-right: 0;
   }
 }


/**
 * Narrow version.
 */

.l-container--narrow {
  @include to-rem(max-width, 770);
}

/**
 * Slim version.
 */

.l-container--slim {
  @include to-rem(max-width, 970);
}

/**
 * Mini
 */

.l-container--mini {
  @include to-rem(max-width, 600);
}

/**
 * Micro
 */

.l-container--micro {
  @include to-rem(max-width, 430);
}

/**
 * Fixed width form (iframed into dashboards)
 */

.l-container--fixed-width-form {
  @include to-rem(max-width, 735);
}

/**
 * Modifier: Banner negative offset.
 * When content needs to pulled up into the banner
 */

.l-container--banner-negative-offset {
  position: relative;
  top: to-rem(-82);
  margin-bottom: to-rem(-40);
}
