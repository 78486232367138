/* ============================================================================
   @OBJECTS -> LIST
   ========================================================================= */

/**
* Standard list styles for unordered and ordered lists as they're removed in
* Core -> Reset.
*
* @markup
  <ul class="o-list">
    <li>Lorem</li>
    <li>Aliquam</li>
    <li>Vestibulum</li>
  </ul>

  <ol class="o-list">
    <li>Lorem</li>
    <li>Aliquam</li>
    <li>Vestibulum</li>
  </ol>
*
* @demo
* http://codepen.io/chris-pearce/full/gbVLwy/
*/


/**
* Settings.
*/

/**
* Toggle on/off certain styles and treatments.
*/

/**
* Margins.
*/

$o-list-apply-bottom-margin:                  true !default;

$o-list-apply-top-bottom-margin-to-nested:    false !default;

/**
* Hanging bullets.
*/

$o-list-apply-hanging-bullets:                false !default;

/**
* Apply at these breakpoints (turned off by default).
*/

$o-list-apply-at-breakpoints:                 $default-breakpoints !default;

// From the above breakpoints choose what you wish to apply it too
$o-list-apply-at-breakpoints-for-default:     false !default;

$o-list-apply-at-breakpoints-for-unordered:   false !default;

$o-list-apply-at-breakpoints-for-ordered:     false !default;

$o-list-apply-at-breakpoints-for-nested:      false !default;

/**
* Margins.
*/

$o-list-bottom-margin:                        $spacing-base !default;

$o-list-nested-top-bottom-margin:             calc($o-list-bottom-margin / 2) !default;

/**
* Left indentation.
*/

$o-list-indent:                               $o-list-bottom-margin !default;

/**
* Bullets.
*/

$o-list-bullet-unordered-base:                disc !default;

$o-list-bullet-unordered-nested-once:         circle !default;

$o-list-bullet-unordered-nested-twice:        square !default;

$o-list-bullet-ordered:                       decimal !default;


%o-list,
.o-list {
 // Apply bottom spacing
 @if $o-list-apply-bottom-margin {
   @include to-rem(margin-bottom, $o-list-bottom-margin);

   // Remove from the last child
   &:last-child {
     margin-bottom: 0;
   }
 }

 // Apply hanging bullets
 @if $o-list-apply-hanging-bullets {
   list-style-position: outside;
 }
 @else {
   @include to-rem(margin-left, $o-list-indent);
 }
}

@if $o-list-apply-at-breakpoints-for-default {
 @include generate-at-breakpoints('.o-list', $o-list-apply-at-breakpoints) {
   @if $o-list-apply-bottom-margin {
     @include to-rem(margin-bottom, $o-list-bottom-margin);

     &:last-child {
       margin-bottom: 0;
     }
   }

   @if $o-list-apply-hanging-bullets {
     list-style-position: outside;
   }
   @else {
     @include to-rem(margin-left, $o-list-indent);
   }
 }
}


/**
* Bullets.
*
* For unordered lists we're replicating the native browser bullets:
* - disc
*  -- circle
*    --- square
*/

// Unordered lists
%ul.o-list,
ul.o-list {
 list-style-type: $o-list-bullet-unordered-base;
}

@if $o-list-apply-at-breakpoints-for-unordered {
 @include generate-at-breakpoints('ul.o-list', $o-list-apply-at-breakpoints) {
   list-style-type: $o-list-bullet-unordered-base;
 }
}

// Ordered lists
%ol.o-list,
ol.o-list {
 list-style-type: $o-list-bullet-ordered;
}

@if $o-list-apply-at-breakpoints-for-ordered {
 @include generate-at-breakpoints('ol.o-list', $o-list-apply-at-breakpoints) {
   list-style-type: $o-list-bullet-ordered;
 }
}


 /**
  * Nested lists.
  */

/* stylelint-disable no-duplicate-selectors */
%o-list,
.o-list {
/* stylelint-enable no-duplicate-selectors */

   // Apply spacing between the lists
   @if $o-list-apply-top-bottom-margin-to-nested {
     ul,
     ol {
       @include to-rem(margin-bottom margin-top, $o-list-nested-top-bottom-margin);
     }
   }

   // Left indentation
   ul,
   ol {
     @include to-rem(margin-left, $o-list-indent);
   }

   // Unordered list bullets
   ul {
     list-style-type: $o-list-bullet-unordered-nested-once;

     ul {
       list-style-type: $o-list-bullet-unordered-nested-twice;
     }
   }

   // Ordered list bullets
   ol {
     list-style-type: $o-list-bullet-ordered;
   }
 }

 @if $o-list-apply-at-breakpoints-for-nested {
   @include generate-at-breakpoints('.o-list',
     $o-list-apply-at-breakpoints) {
     @if $o-list-apply-top-bottom-margin-to-nested {
       ul,
       ol {
         @include to-rem(margin-bottom margin-top, $o-list-nested-top-bottom-margin);
       }
     }

     ul,
     ol {
       @include to-rem(margin-left, $o-list-indent);
     }

     ul {
       list-style-type: $o-list-bullet-unordered-nested-once;

       ul {
         list-style-type: $o-list-bullet-unordered-nested-twice;
       }
     }

     ol {
       list-style-type: $o-list-bullet-ordered;
     }
   }
 }

/**
* Extending List object
*/

ol.o-list--legal {
  list-style-type: lower-alpha;

  ol {
    list-style-type: lower-roman;
  }
}

/**
 * Introduction Fee policy has a number of nested lists with requirements
 * for a specific hierarchy
 */

ol.o-list--number-letter-roman {
  list-style-type: decimal;

  ol {
    list-style-type: lower-alpha;

    ol {
      list-style-type: lower-roman;
    }
  }
}

/**
 * FAQS have numbered lists
 */

ol.o-list--number {
  list-style-type: decimal;
}

/**
 * Inline list
 */

.o-list-inline--spacing-micro li {
  margin-right: to-rem(3);
}
