/* ============================================================================
   @COMPONENTS -> TOOLTIP
   ========================================================================= */
/**
 * A tooltip will render a message to help the user. When the user hovers
 * over the tooltip, the message will show relative to its container.
 */

/**
 * Settings
 */

$tooltip-spacer: to-rem($spacing-micro);
$c-tooltip-arrow-size: to-rem(10);
$c-tooltip-icon-size: to-rem(20);
$c-tooltip-message-position: $c-tooltip-icon-size + $c-tooltip-arrow-size +
  $tooltip-spacer;
$c-tooltip-arrow-position: $c-tooltip-icon-size + $tooltip-spacer;
$c-message-small-width: 200;
$c-message-medium-width: 400;
$c-message-large-width: 600;
$c-message-small-margin: calc($c-message-small-width/2);
$c-message-medium-margin: calc($c-message-medium-width/2);
$c-message-large-margin: calc($c-message-large-width/2);
$c-message-bg-color: $color-grey-shark;

/**
 * Tooltip Utilities
 *
 * Used to share the rules for positioning the tooltip to the left, right, bottom and top
 */

@mixin tooltip-top {
  .c-tooltip__container {
    &::before {
      content: '';
      position: absolute;
      left: calc(50% - #{$c-tooltip-arrow-size});
      bottom: $c-tooltip-arrow-position;
      border-left: $c-tooltip-arrow-size solid transparent;
      border-right: $c-tooltip-arrow-size solid transparent;
      border-top: $c-tooltip-arrow-size solid $c-message-bg-color;
    }

    .c-tooltip__message {
      left: to-rem(-10);
      bottom: $c-tooltip-message-position;
    }

    .c-tooltip__message--right {
      right: to-rem(-10);
      left: auto;
    }
  }
}

@mixin tooltip-bottom {
  .c-tooltip__container {
    &::before {
      content: '';
      position: absolute;
      bottom: initial;
      left: calc(50% - #{$c-tooltip-arrow-size});
      top: $c-tooltip-arrow-position;
      border-top: initial;
      border-left: $c-tooltip-arrow-size solid transparent;
      border-right: $c-tooltip-arrow-size solid transparent;
      border-bottom: $c-tooltip-arrow-size solid $c-message-bg-color;
    }

    .c-tooltip__message {
      bottom: initial;
      left: to-rem(-10);
      top: $c-tooltip-message-position;
    }
  }
}

@mixin tooltip-left {
  .c-tooltip__container {
    &::before {
      content: '';
      position: absolute;
      bottom: initial;
      left: initial;
      top: initial;
      right: $c-tooltip-arrow-position;
      border-right: initial;
      border-top: $c-tooltip-arrow-size solid transparent;
      border-bottom: $c-tooltip-arrow-size solid transparent;
      border-left: $c-tooltip-arrow-size solid $c-message-bg-color;
    }

    .c-tooltip__message {
      bottom: initial;
      left: initial;
      right: $c-tooltip-message-position;
      top: to-rem(-10);
    }
  }
}

@mixin tooltip-right {
  .c-tooltip__container {
    &::before {
      content: '';
      position: absolute;
      bottom: initial;
      top: initial;
      left: $c-tooltip-arrow-position;
      border-left: initial;
      border-top: $c-tooltip-arrow-size solid transparent;
      border-bottom: $c-tooltip-arrow-size solid transparent;
      border-right: $c-tooltip-arrow-size solid $c-message-bg-color;
    }

    .c-tooltip__message {
      bottom: initial;
      left: $c-tooltip-message-position;
      top: to-rem(-10);
    }
  }
}

@mixin show-tooltip {
  .c-tooltip__container {
    &::before {
      opacity: 1;
      transition: all 300ms cubic-bezier(0.8, 1.1, 0.8, 1.1);
      display: block;
    }
  }

  .c-icon {
    color: $color-white;
    fill: $color-blue-bismark;
  }

  .c-tooltip__message {
    opacity: 1;
    transition: all 300ms cubic-bezier(0.8, 1.1, 0.8, 1.1);
    display: block;
  }
}

/**
 * Tooltip
 */

.c-tooltip {
  display: inline-block;
  line-height: 1;

  @include tooltip-top;

  .c-tooltip__container {
    position: relative;

    &::before {
      z-index: index($z-index-elements-global, tooltip);
      opacity: 0;
      transition: all 200ms ease-out;
      display: none;
    }
  }
}

.c-tooltip--open {
  @include show-tooltip;
}

/**
 * Icon
 */
.c-tooltip .c-icon {
  color: $color-white;
  fill: $color-orange-flamingo;
}

/**
 * Message
 */

.c-tooltip__message {
  background-color: $c-message-bg-color;
  color: $color-white;
  font-size: to-rem($font-size-xxx-small);
  font-weight: $font-weight-light;
  opacity: 0;
  padding: to-rem($spacing-quarter);
  position: absolute;
  transition: all 200ms ease-out;
  text-align: center;
  line-height: 1.5;
  display: none;
  min-width: to-rem(100);
  min-height: to-rem(36);
  z-index: index($z-index-elements-global, tooltip);
}

/**
 * Modifiers: Positioning
 */

.c-tooltip--top {
  @include tooltip-top;
}

.c-tooltip--bottom {
  @include tooltip-bottom;
}

.c-tooltip--left {
  @include tooltip-left;
}

.c-tooltip--right {
  @include tooltip-right;
}

.c-tooltip__handle {
  display: inline-block;
}

// @TODO: Get rid of this when s-to-m modifier is published
/**
 * Modifier to layout the tooltip in the work history form
 */

.c-tooltip--work-history {
  .c-tooltip__message {
    min-width: to-rem($c-message-small-width);
  }

  @include respond-to(745) {
    .c-tooltip__message {
      min-width: to-rem($c-message-medium-width);
    }
  }
}

/**
 * Modifier to layout the tooltip in the discover filter panel
 */

.c-tooltip--discover-filter {
  .c-tooltip__message {
    min-width: to-rem($c-message-small-width);
  }

  @include respond-to(745) {
    .c-tooltip__message {
      min-width: to-rem($c-message-medium-width);
    }
  }
}

/**
 * Modifier to layout the tooltip from small to medium width
 */

.c-tooltip--s-to-m {
  .c-tooltip__message {
    min-width: to-rem($c-message-small-width);
  }

  @include respond-to(745) {
    .c-tooltip__message {
      min-width: to-rem($c-message-medium-width);
    }
  }
}
