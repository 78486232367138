/* ============================================================================
   @COMPONENTS -> STATUS LABEL
   ========================================================================= */

/**
 * A Status Label component is typically used to indicate a particular status,
 * and is colour-coded to each status individually.
 *
 * Status Labels can have different background colours and this is achieved by
 * generating colour modifiers from a background colour sass map.
 */

/**
 * Settings
 */

// colour map for different types of status labels
// FIXME `green` and `purple` keys conflict with linting. Could have better naming.
 /* stylelint-disable */
$c-status-label-background-colours: (
  accepting-applications: $color-green-apple,
  interviewing: $color-grey-mystic,
  contract-agreed: $color-green-asparagus,
  project-completed: $color-green-asparagus,
  draft: $color-grey-loblolly,
  "green": $color-green-apple,
  "purple": $color-purple-studio
);
 /* stylelint-enable */

/**
 * Status Label
 */

.c-status-label {
  background-color: $color-grey-loblolly;
  border-top-left-radius: to-rem($border-radius);
  border-top-right-radius: to-rem($border-radius);
  color: $color-white;
  display: block;
  font-size: to-rem($font-size-xxx-small);
  text-align: center;
  width: 100%;

  @include respond-to(lap) {
    border-bottom-right-radius: to-rem($border-radius);
    border-top-right-radius: 0;
    padding-left: to-rem($spacing-half);
    padding-right: to-rem($spacing-half);
    width: auto;
  }
}

/**
 * Modifier: Colours
 */

@each $status, $colour in $c-status-label-background-colours {
  .c-status-label--#{$status} {
    background-color: $colour;
  }
}

/**
 * Modifier: Corner
 */

.c-status-label--corner {
  width: auto;
  border-radius: 0 to-rem($border-radius) 0 to-rem($border-radius);
  padding: 0 to-rem($spacing-half);
}

/**
  * Modifier: Colours
  */
.c-status-label--interviewing {
  color: $color-blue-bismark;
}

.c-status-label--accepting-applications {
  color: $color-green-asparagus;
}

/**
  * Modifier: Colours & text formating
  */
.c-status-label--green {
  color: $color-green-asparagus;
}

.c-status-label--purple {
  color: $color-white;
}

/**
  * Modifier: Spacing and width
  */
.c-status-label--compact {
  text-transform: capitalize;
  font-weight: $font-weight-bold;
  padding-top: to-rem($u-spacing-micro);
  padding-bottom: to-rem($u-spacing-micro);
  padding-left: to-rem($u-spacing-tiny);
  padding-right: to-rem($u-spacing-tiny);
  width: auto;
}

/**
  * Modifier: Inline display
  */
.c-status-label--inline {
  display: inline-block !important;
}
