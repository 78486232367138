/* ============================================================================
   @COMPONENTS -> BANNER
   ========================================================================= */

/**
 * The banner for each marketing page consists of the page/section title (`h1`)
 * and a subtitle.
 */

/**
 * Settings
 */

//Mimic spacing of headings with full width layouts
$c-banner-spacing-ends: $l-flex-grid-gutter-base;

/**
  * Fall-back for non-supporting Flexbox browsers, using the Modernizr
  * feature detection technique via the `:not` CSS selector to get around
  * any lag of the Modernizr JS loading.
  */

html:not(.flexbox):not(.flexboxtweener) .c-banner {
  display: block;
}

.c-banner {
  align-items: center; // Fall-back if an image doesn't load for some reason
  background-color: $color-grey-nepal;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $color-white;
  display: flex;
  justify-content: center;
  min-height: to-rem(360);
}

/**
 * Inner content container.
 */

.c-banner__inner {
  padding-bottom: to-rem(50);
  padding-top: to-rem(50);
  text-align: center;
}

/**
 * Banner title.
 */

.c-banner__title {
  color: $color-white;
  font-size: to-rem(60);
  font-weight: $font-weight-light;
  line-height: 1.2;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.3);
}

.c-banner__inline-link {
  color: $color-white;
  &:hover {
    color: $color-orange-flamingo;
  }
}

/**
 * Banner subtitle.
 */

.c-banner__subtitle {
  color: $color-white;
  font-size: to-rem(24);
  font-weight: $font-weight-light;
}

/**
 * Modifier: offset banner the height of the header
 */

.c-banner--offset-site-header-height {
  padding-top: to-rem($site-header-height);
}

/**
 * Modifier: Alternate text treatments
 */

.c-banner--alt {
  flex-direction: column;
}

.c-banner--alt .c-banner__logo {
  align-items: center;
  display: flex;
  height: to-rem($site-header-height);
  padding-left: to-rem($c-banner-spacing-ends);
  padding-right: to-rem($c-banner-spacing-ends);
  justify-content: center;
  width: 100%;

  @include respond-to(lap) {
    justify-content: flex-start;
  }
}

.c-banner--alt .c-banner__logo-img {
  width: to-rem(110);

  @include respond-to(lap) {
    width: to-rem(80);
  }
}

.c-banner--alt .c-banner__inner--collapse-spacing-top {
  padding-top: 0;
}

.c-banner--alt .c-banner__title {
  font-size: to-rem(32);
  margin-bottom: to-rem($spacing-base);
}

.c-banner--alt .c-banner__title strong {
  display: block;
  font-size: to-rem(42);
  text-transform: uppercase;
  margin-top: to-rem($spacing-third);

  @include respond-to(lap) {
    font-size: to-rem(60);
  }
}

.c-banner--alt .c-banner__subtitle {
  font-size: to-rem(18);
}

/**
 * WIP: Left aligned parallax banner
 */

.c-banner-simple {
  background-attachment: fixed;
  background-color: $color-blue-bismark;
  min-height: to-rem(110);
  position: relative;
  /**
   * Patterned background
   */
  &::before {
    // TODO need to find an appropriate strategy for image loading
    // In consuming products.
    //background-image: url('images/patterns/hexagon-pattern.svg');
    background-repeat: repeat-x;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0.15;
    position: absolute;
    right: 0;
    top: 0;
  }

  @include respond-to(744) {
    min-height: to-rem(142);
  }
}

.c-banner-simple__inner {
  position: fixed;
  width: 100%;

  @include respond-to(744) {
    padding-top: to-rem(36);
  }

  @include respond-to(1020) {
    padding-top: to-rem(50);
  }
}

.c-banner-simple__title {
  color: $color-grey-nepal;
  display: none;

  @include respond-to(745) {
    display: block;
  }
}