/* ============================================================================
   @COMPONENTS -> AVATAR
   ========================================================================= */

/**
 * An Avatar can be either an image or text initials.
 */

/**
 * Settings
 */

$c-avatar-border-color: $color-grey-mystic;

$c-avatar-size: 100;

$c-avatar-size-small: 45;

$c-avatar-size-tiny: 34;

$c-avatar-text-size: 32;

$c-avatar-text-size-small: $font-size;

$c-avatar-text-size-tiny: 12;

/**
 * Avatar
 */

.c-avatar {
  align-items: center;
  background-color: $color-grey-athens-2;
  border: 6px solid $color-grey-mystic;
  border-radius: 50%;
  display: flex;
  height: to-rem($c-avatar-size);
  justify-content: center;
  margin: auto;
  overflow: hidden;
  width: to-rem($c-avatar-size);
}

/**
 * Avatar as an image
 */

.c-avatar__image {
  background-position: center;
  background-size: cover;
  background-color: $color-grey-athens-2;
  border-radius: 50%;
  width: to-rem($c-avatar-size);
}

/**
 * Avatar as a text (initials)
 */

.c-avatar__text {
  color: $color-grey-slate;
  font-size: to-rem($c-avatar-text-size);
}

/**
 * Avatar with link
 */

.c-avatar__link {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/**
 * Modifier: Small
 */

.c-avatar--small {
  border-width: 3px;
  height: to-rem($c-avatar-size-small);
  width: to-rem($c-avatar-size-small);

  .c-avatar__text {
    font-size: to-rem($c-avatar-text-size-small);
  }

  .c-avatar__image {
    width: to-rem($c-avatar-size-small);
  }
}

@include respond-to(palm, max) {
  .c-avatar--x-small-to-base {
    border-width: 3px;
    height: to-rem($c-avatar-size-small);
    width: to-rem($c-avatar-size-small);

    .c-avatar__text {
      font-size: to-rem($c-avatar-text-size-small);
    }

    .c-avatar__image {
      width: to-rem($c-avatar-size-small);
    }
  }
}

/**
 * Modifier: Tiny
 */

.c-avatar--tiny {
  border-width: 3px;
  height: to-rem($c-avatar-size-tiny);
  width: to-rem($c-avatar-size-tiny);

  .c-avatar__text {
    font-size: to-rem($c-avatar-text-size-tiny);
  }

  .c-avatar__image {
    width: to-rem($c-avatar-size-tiny);
  }
}

/**
 * Modifier: Background color filled
 */

.c-avatar--filled {
  background-color: $color-grey-mystic;

  .c-avatar__text {
    font-weight: $font-weight-bold;
    color: $color-grey-nepal;
  }
}

/**
 * Modifier: Alignments
 */
.c-avatar--align-left {
  margin: 0;
}
