/* ============================================================================
   @COMPONENTS -> SECONDARY NAV
   ========================================================================= */

/**
 * Secondary top level navigation.
 */

/**
 * Individual nav item
 */

.l-secondary-nav__nav-item {
  color: $color-blue-bismark;
  display: block;
  font-size: to-rem($font-size-x-small);
  padding-left: to-rem($spacing-half);
  padding-right: to-rem($spacing-half);

  /**
   * Allows the bottom border, used in hover and active states to only be the
   * width of the nav item label (text) and not the full width of the clickable
   * nav item.
   */

  span {
    display: block;
    padding-top: to-rem($spacing-half);
    padding-bottom: to-rem($spacing-half);
    border-bottom: 2px solid transparent;
  }

  /**
   * Hover and active states
   */

  &:hover,
  &:focus,
  &.is-active {
    color: $color-grey-shark;
  }

  &:hover span,
  &:focus span,
  &.is-active span {
    border-bottom-color: $color-orange-flamingo;
  }
}
