/* ============================================================================
   @COMPONENTS -> BUTTON
   ========================================================================= */

/**
 * Main button component.
 */

/**
 * Settings.
 */

/**
 * Colours.
 */

$c-button-disabled-state: $color-grey-loblolly;

$c-button-primary-background-color: $color-brand;

$c-button-primary-foreground-color: $color-white;

$c-button-primary-selected-background-color: $color-blue-bismark;

$c-button-secondary-foreground-color: $color-blue-bismark;

$c-button-secondary-selected-foreground-color: $color-white;

$c-button-alt-border-color: $color-grey-loblolly;

$c-button-color-text-underline: $color-blue-bismark;

$c-button-color-text-underline-hover: $color-orange-flamingo;

/**
 * Padding.
 */

$c-button-padding-ends-base: 16;

$c-button-padding-ends-large: 24;

$c-button-padding-ends-small: 12;

$c-button-padding-ends-tiny: 6;

$c-button-padding-sides-base: $spacing-base-plus-half; //36

$c-button-padding-sides-small: $spacing-base;

/**
 * Sizes
 */

$c-button-icon-only-width: 46;
$c-button-icon-only-height: 46;

/**
 * Icons
 */

$c-button-icon-horizontal-spacing: 10;

/**
 * Animation
 */

$c-button-animation-duration: 300ms;

/**
 * Keyframes
 */

/**
 * Fade in from the top
 */

@keyframes fadeInFromTop {
  from {
    position: relative;
    top: to-rem(-$c-button-padding-ends-large);
    opacity: 0;
  }

  to {
    position: relative;
    top: 0;
    opacity: 1;
  }
}

/**
 * Fade in and slide from top to bottom
 */

@keyframes fadeInFromBottom {
  from {
    position: relative;
    top: to-rem($c-button-padding-ends-large);
    opacity: 0;
  }

  to {
    position: relative;
    top: 0;
    opacity: 1;
  }
}

/**
 * Fade in and outwards
 */

@keyframes fadeInGrow {
  from {
    opacity: 0;
    transform: scale(0.3);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

/**
 * Borders.
 */

$c-button-rounded-corners-size: $border-radius;

/**
 * Default button
 */

.c-button {
  @extend %o-button;
  align-items: center;
  border-radius: to-rem($c-button-rounded-corners-size);
  display: inline-flex;
  font-size: to-rem($font-size-x-small);
  font-weight: $font-weight-bold;
  justify-content: center;
  line-height: 1;
  text-align: center;
  padding-bottom: to-rem($c-button-padding-ends-base);
  padding-top: to-rem($c-button-padding-ends-base);

  // N.B. ugly hack in order to increase the specificity to override the
  // removal of rounded corners that iOS applies set in Core -> Base ->
  // Forms
  html & {
    border-radius: strip-unit($c-button-rounded-corners-size) * 1px;

    &.c-button--sharp {
      border-radius: 0;
    }

    &.c-button--sharp-left {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.c-button--sharp-right {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &[disabled],
  &.is-disabled {
    pointer-events: none;
  }
}

/**
 * Modifier: Primary.
 */

.c-button--primary {
  background-color: $c-button-primary-background-color;
  color: $c-button-primary-foreground-color;
  padding-left: to-rem($c-button-padding-sides-base);
  padding-right: to-rem($c-button-padding-sides-base);
  transition: all 0.3s ease;

  &:hover,
  &:focus,
  &.is-active,
  &.is-selected {
    background-color: $c-button-primary-selected-background-color;
    color: $c-button-primary-foreground-color;
    opacity: 1;
  }

  &:focus:not(:hover) {
    box-shadow: inset 0 0 0 2px tint($c-button-primary-background-color, 30%);
  }

  &[disabled],
  &.is-disabled {
    background-color: $c-button-disabled-state;
    //override Scally
    opacity: 1 !important;
  }
}

/**
 * Modifier: Secondary.
 */

.c-button--secondary {
  background-color: $color-white;
  box-shadow: inset 0 0 0 1px $color-grey-loblolly;
  color: $c-button-secondary-foreground-color;
  padding-left: to-rem($c-button-padding-sides-base);
  padding-right: to-rem($c-button-padding-sides-base);
  transition: all 0.3s ease;

  &:hover,
  &.is-active,
  &.is-selected {
    background-color: $c-button-secondary-foreground-color;
    box-shadow: none;
    color: $c-button-secondary-selected-foreground-color;
    opacity: 1;
  }

  &:focus {
    box-shadow: inset 0 0 0 2px $c-button-secondary-foreground-color;
  }

  &[disabled],
  &.is-disabled {
    background-color: transparent;
    //override Scally
    box-shadow: inset 0 0 0 1px $c-button-disabled-state !important;
    color: $c-button-disabled-state;
    //override Scally
    opacity: 1 !important;
  }
}

/**
 * Modifier: tertiary.
 */

.c-button--tertiary {
  background-color: $color-white;
  color: $color-orange-flamingo;
  padding-left: to-rem($c-button-padding-sides-base);
  padding-right: to-rem($c-button-padding-sides-base);
  transition: all 0.3s ease;
  box-shadow: inset 0 0 0 1px $color-grey-loblolly;

  &:hover,
  &.is-active,
  &.is-selected {
    background-color: $color-orange-flamingo;
    box-shadow: none;
    color: $color-white;
    opacity: 1;
  }

  &:focus {
    box-shadow: inset 0 0 0 2px $color-orange-flamingo;
  }

  &[disabled],
  &.is-disabled {
    background-color: transparent;
    //override Scally
    box-shadow: inset 0 0 0 1px $c-button-disabled-state !important;
    color: $c-button-disabled-state;
    //override Scally
    opacity: 1 !important;
  }
}

.c-button--text-underline {
  color: $c-button-color-text-underline;
  text-decoration: underline;

  &:hover {
    color: $c-button-color-text-underline-hover;
    text-decoration: underline;
  }
}

.c-button--subtle-text {
  font-weight: $font-weight-light;
  text-decoration: underline;
  color: $link-color-hover;

  &:hover,
  &:focus {
    color: $link-color;
  }
}

/**
 * Modifier: Transparent background
 */

.c-button--transparent {
  background-color: transparent;
}

/**
 * Modifier: sizes.
 */

// Large
.c-button--large {
  font-size: to-rem($font-size);
  padding-bottom: to-rem($c-button-padding-ends-large);
  padding-top: to-rem($c-button-padding-ends-large);
}

// Small
.c-button--small {
  padding-bottom: to-rem($c-button-padding-ends-small);
  padding-left: to-rem($c-button-padding-sides-small);
  padding-top: to-rem($c-button-padding-ends-small);
  padding-right: to-rem($c-button-padding-sides-small);
}

// Tiny
.c-button--tiny-up-to-palm {
  padding-top: to-rem($c-button-padding-ends-tiny);
  padding-bottom: to-rem($c-button-padding-ends-tiny);

  @include respond-to(lap) {
    padding-top: to-rem($c-button-padding-ends-small);
    padding-bottom: to-rem($c-button-padding-ends-small);
  }
}

/**
 * Modifier: full bleed.
 */

.c-button--full-bleed {
  display: block;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

// Up to Palm max
.c-button--full-bleed-up-to-palm {
  padding-left: 0;
  padding-right: 0;
  width: 100%;

  @include respond-to(lap) {
    padding-left: to-rem($c-button-padding-sides-base);
    padding-right: to-rem($c-button-padding-sides-base);
    width: auto;
  }
}

/**
 * Modifier: Add Entry Button
 */

.c-button--add-entry {
  border: 1px dashed $color-grey-loblolly;
  background-color: $color-grey-athens-2;
  color: $color-orange-flamingo;
  display: block;
  font-weight: $font-weight-light;
  padding: to-rem($spacing-base);
  text-align: left;

  &:hover,
  &:focus {
    border-color: $color-orange-flamingo;
    color: $color-orange-flamingo;
  }
}

/**
 * Inner
 */

.c-button__inner {
  display: inline-flex;
  align-items: center;
}

/**
 * Icon
 */

.c-button__icon {
  margin-right: to-rem(10);
}

// icon on the right
.c-button__text + .c-button__icon {
  margin-left: to-rem(10);
}

/**
 * Toggle buttons
 *
 * These buttons are generally used to show and hide content.
 */

.c-button--toggle {
  .c-button-text-not-selected {
    align-items: center;
    display: inline-flex;
  }

  .c-button-text-selected {
    display: none;
  }
}

/**
 * Toggle buttons when the content is open
 */

.c-button--toggle.is-open {
  .c-button-text-not-selected {
    display: none;
  }

  .c-button-text-selected {
    align-items: center;
    display: inline-flex;
  }
}

/**
 * Modifier: Stateful Button
 * A button type with three (or more) states.
 */

.c-button--stateful {
  border: 0;

  .c-button-is-not-selected {
    display: block;
  }

  .c-button-is-selected,
  .c-button-is-selected-hover {
    display: none;
  }

  .c-button-text-not-selected {
    align-items: center;
    display: inline-flex;
  }

  .c-button-text-selected,
  .c-button-text-selected-hover {
    display: none;
  }

  /**
   * Extending `.c-button--icon-only` in `.c-button--stateful`
   */
  &.c-button--icon-only {
    border: 0;
    background-color: $color-white;
  }
}

/**
 * Stateful Button Selected
 */

.c-button--stateful.is-selected {
  .c-button__text {
    animation-duration: $c-button-animation-duration;
    animation-name: fadeInFromTop;
  }

  .c-button__icon {
    animation-duration: $c-button-animation-duration;
    animation-name: fadeInGrow;
  }

  .c-button-is-selected {
    display: block;
  }

  .c-button-is-not-selected {
    display: none;
  }

  .c-button-text-not-selected,
  .c-button-text-selected-hover {
    display: none;
  }

  .c-button-text-selected {
    align-items: center;
    display: inline-flex;
  }
}

/**
 * Stateful Button Selected and Hovered
 */

.c-button--stateful.is-selected:hover {
  .c-button-text-selected,
  .c-button-text-not-selected {
    display: none;
  }

  .c-button-text-selected-hover {
    align-items: center;
    display: inline-flex;
  }
}

/**
 * Is Selected and Hovered
 */

.c-button--stateful.is-selected-hover {
  .c-button-is-selected-hover {
    display: block;
  }

  .c-button-is-not-selected,
  .c-button-is-selected {
    display: none;
  }

  .c-button__text {
    animation-duration: $c-button-animation-duration;
    animation-name: fadeInFromBottom;
  }

  .c-button__icon {
    animation-duration: $c-button-animation-duration;
    animation-name: fadeInGrow;
  }
}

/**
 * Stateful invite buttons
 */

.c-button--stateful .c-button-is-not-invited {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.c-button--stateful .c-button-is-invited,
.c-button--stateful .c-button-is-invited-all {
  display: none;
}

.c-button--stateful.is-invited .c-button-is-invited {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.c-button--stateful.is-invited .c-button-is-not-invited,
.c-button--stateful.is-invited .c-button-is-invited-all {
  display: none;
}

/**
 * Stateful request intro buttons
 */

.c-button--stateful .c-button-has-not-requested {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.c-button--stateful .c-button-has-requested {
  display: none;
}

.c-button--stateful.has-requested {
  .c-button-has-requested {
    align-items: center;
    display: inline-flex;
    justify-content: center;
  }

  .c-button-has-not-requested {
    display: none;
  }
}

/**
 * Stateful Expandable Content buttons
 */

.c-button-faux--stateful {
  .c-button-faux-is-not-expanded {
    display: block;
  }

  .c-button-faux-is-expanded-preview,
  .c-button-faux-is-expanded {
    display: none;
  }
}

.c-button-faux--stateful.is-expanded-preview {
  .c-button-faux-is-expanded-preview {
    display: block;
  }

  .c-button-faux-is-not-expanded,
  .c-button-faux-is-expanded {
    display: none;
  }
}

.c-button-faux--stateful.is-expanded {
  .c-button-faux-is-expanded {
    display: block;
  }

  .c-button-faux-is-not-expanded,
  .c-button-faux-is-expanded-preview {
    display: none;
  }
}

/**
 * Modifier: Icon only button.
 */

.c-button--icon-only {
  border: 1px solid $color-grey-loblolly;
  background-color: $color-white;
  border-radius: to-rem($border-radius);
  height: to-rem($c-button-icon-only-height);
  padding: 0;
  width: to-rem($c-button-icon-only-width);

  &:focus {
    box-shadow: none;
  }
}

//Icon only button icon
.c-button--icon-only .c-button__icon {
  margin-right: 0;
}

/**
 * Modifier: Close button
 */

.c-button--close {
  border: 0;
  color: $color-blue-bismark;

  &:hover,
  &:focus {
    color: $color-brand;
  }
}

/**
 * Modifier: Linkedin
 */

.c-button--linkedin {
  background-color: $color-social-linkedin;
  color: $color-white;

  .c-icon {
    fill: transparent;
  }

  &:hover {
    background-color: $color-blue-bismark;
    color: $color-white;
  }
}

/**
 * Modifier: Rounded
 *
 * 1. Override border radius on base button which uses `html .c-button`
 */

.c-button--rounded {
  background-color: $color-white;
  color: $color-blue-bismark;
  border-radius: to-rem(25) !important; // 1
  padding: to-rem($spacing-half) to-rem($spacing-base);
  font-weight: $font-weight-bold;
  border-bottom: 1px solid $color-grey-loblolly;

  &:hover {
    color: $color-orange-flamingo;
  }

  &.is-selected {
    color: $color-orange-flamingo;
    border-bottom: 2px solid $color-grey-loblolly;
  }
}

/**
 * Footer button
 */

.c-button--footer {
  display: block;
  padding-left: 0;
  padding-right: 0;
  width: 100%;

  html & {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

/**
 * Small sides
 */

.c-button--small-sides {
  padding-left: to-rem($c-button-padding-sides-small);
  padding-right: to-rem($c-button-padding-sides-small);
}

/**
 * Button top
 */

.c-button--top-bottom-padding {
  padding-top: to-rem($c-button-padding-sides-small);
  padding-bottom: to-rem($c-button-padding-sides-small);
}
