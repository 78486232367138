/* ============================================================================
   @LAYOUT -> TEMPLATE -> MAIN CONTENT
   ========================================================================= */

// offset the height of the header, this is hard coded for now
.l-main-content-outer {
  padding-top: to-rem($site-header-height);
}

.l-main-content-outer--primary-header {
  padding-top: to-rem($site-header-height);
}

// offset height of the header when both the primary and secondary header are
// visible
.l-main-content-outer--secondary-header {
  padding-top: to-rem($site-header-height * 2);
}

// collapse padding for banners that are full viewport width and height.
.l-main-content-outer--collapse {
  padding-top: 0;
}

.l-main-content {
  position: relative;
}
