/* ============================================================================
   @COMPONENTS -> BUTTON TEXT ONLY
   ========================================================================= */

/**
 * A button rendered to look like a link with capital letters, wider spacing, and smaller font.
 * This button looks like a link, but is semantically a button.
 */

/**
 * Sizes
 */

$c-button-text-only-height: 24;

/**
 * Button text only
 */

.c-button-text-only {
  @extend %o-button;
  align-items: center;
  display: inline-flex;
  font-weight: $font-weight-bold;
  line-height: 1;
  border: none;
  text-transform: uppercase;
  padding: 0;
  letter-spacing: to-rem(1);
  font-size: to-rem($font-size-xxx-small);
  color: $color-grey-slate;
  height: to-rem($c-button-text-only-height);
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    color: $color-orange-flamingo;
  }

  /**
   * Add spacing after an icon placed before text content
   */
  .c-icon:first-child {
    margin-right: to-rem($spacing-quarter);
  }
}
