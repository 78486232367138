/* ============================================================================
   @COMPONENTS -> PULLQUOTE
   ========================================================================= */

/**
 * Pullquotes with attribution. The background color of the pullquote and
 * associated triangle is set in the page section theme. This allows the
 * pullquote to appear to have a transparent background while being portable.
 */

/**
 * Settings
 */

$c-pullquote-border-color: $color-grey-loblolly;

$c-pullquote-arrow-size: 20px;

.c-pullquote {
  font-size: to-rem($font-size-x-small);
  text-align: left;
}

.c-pullquote__inner {
  border: 1px solid $c-pullquote-border-color;
  border-radius: $border-radius * 1px;
  margin-bottom: to-rem($spacing-base);
  padding: to-rem($spacing-half);
  position: relative;

  /**
   * Quote triangle bottom
   */

  &::before,
  &::after {
    content: '';
    background-color: $c-pullquote-border-color;
    bottom: -(calc($c-pullquote-arrow-size/2));
    height: $c-pullquote-arrow-size;
    left: 20px;
    position: absolute;
    transform: rotate(45deg);
    width: $c-pullquote-arrow-size;
  }

  &::after {
    bottom: -(calc($c-pullquote-arrow-size/2) - 1);
  }
}

/**
 * Quote text
 */

.c-pullquote__quote {
  font-style: italic;
  font-weight: $font-weight-light;
  text-align: center;
}

/**
 * Quote source
 */

.c-pullquote__attribution {
  color: $color-grey-slate;
}

.c-pullquote__company {
  display: block;
  font-weight: $font-weight-bold;
}
