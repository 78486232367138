/* ============================================================================
   @COMPONENTS -> PANEL
   ========================================================================= */

/**
 * Panels are simple containers usually split into a header and body, with
 * various spacing and border styles
 */

/**
 * Modifier: Key point styles.
 */
.c-panel--keypoint {
  background-color: $color-white;
  border-bottom: 2px solid $color-grey-mystic;
  height: 100%;
  padding: to-rem($spacing-base);

  @include respond-to(lap) {
    padding: 0 to-rem($spacing-base) to-rem($spacing-base-plus-third)
      to-rem($spacing-base);
  }
}

/**
 * Panel Header
 */

.c-panel__header {
  background-color: $color-blue-danube;

  &,
  .heading {
    color: $color-white;
  }
}

/**
 * Pay per project
 */

.c-panel__header--light {
  background: linear-gradient(
      rgba(110, 165, 215, 0.95),
      rgba(110, 165, 215, 0.95)
    );
    // TODO need to find an appropriate strategy for image loading
    // In consuming products.
    //url('images/homepage/payPerProjectBgx2.jpg');

  @include respond-to(lap) {
    min-height: 350px;
  }
}

/**
 * Enterprise Solutions
 */

.c-panel__header--dark {
  background: linear-gradient(rgba(33, 40, 46, 0.9), rgba(33, 40, 46, 0.9));
   // TODO need to find an appropriate strategy for image loading
   // In consuming products.
   // url('images/homepage/enterpriseBgx2.jpg');

  @include respond-to(lap) {
    min-height: 350px;
  }
}

/**
 * Modifier: Header spacing ends
 */

.c-panel__header--spacing-ends {
  padding-bottom: to-rem($spacing-base);
  padding-top: to-rem($spacing-base);
}

.c-panel__header--spacing-top-large {
  padding-top: to-rem($spacing-double);
}

/**
 * Modifier: Header spacing sides
 */

.c-panel__header--spacing-sides {
  padding-left: to-rem($spacing-half);
  padding-right: to-rem($spacing-half);
}

.c-panel__header--spacing-sides-large {
  padding-left: to-rem($spacing-base);
  padding-right: to-rem($spacing-base);

  @include respond-to(lap) {
    padding-left: to-rem($spacing-double);
    padding-right: to-rem($spacing-double);
  }
}

/**
 * Modifier: Header spacing base all around
 */

.c-panel__header--spacing-base {
  padding-top: to-rem($spacing-base);
  padding-bottom: to-rem($spacing-base);
  padding-left: to-rem($spacing-base);
  padding-right: to-rem($spacing-base);
}

/**
 * Modifier: Header top rounded corners
 */

.c-panel__header--rounded-corner-top {
  border-top-left-radius: $border-radius * 1px;
  border-top-right-radius: $border-radius * 1px;
}

/**
 * Panel body
 */

.c-panel__body {
  background-color: $color-white;
  border-left: 1px solid $color-grey-mystic;
  border-right: 1px solid $color-grey-mystic;
  border-bottom: 2px solid $color-grey-mystic;
}

.c-panel__body--graphic {
  font-size: to-rem($font-size-x-small);
}

.c-panel__body--border-thin {
  border-bottom-width: 1px;
}

.c-panel__body--border-collapse {
  border-width: 0;
}

.c-panel__body--rounded-corner-bottom {
  border-bottom-left-radius: $border-radius * 1px;
  border-bottom-right-radius: $border-radius * 1px;
}

.c-panel__body--rounded-corner-all {
  border-radius: $border-radius * 1px;
}

.c-panel__body--spacing {
  padding: to-rem($spacing-base);

  @include respond-to(lap) {
    padding: to-rem($spacing-double);
  }
}

.c-panel__body--spacing-base {
  padding-top: to-rem($spacing-base);
  padding-bottom: to-rem($spacing-base);
  padding-left: to-rem($spacing-base);
  padding-right: to-rem($spacing-base);
}

.c-panel__body--spacing-small {
  padding: to-rem($spacing-base);
  padding-left: to-rem($spacing-base);
  padding-right: to-rem($spacing-base);

  @include respond-to(lap) {
    padding-top: to-rem($spacing-double);
    padding-bottom: to-rem($spacing-double);
  }
}

.c-panel__body--spacing-ends {
  padding-top: to-rem($spacing-half);
  padding-bottom: to-rem($spacing-half);
}

.c-panel__body--fill-light {
  background-color: $color-grey-athens;
  color: $color-grey-slate;
}

.c-panel__body--fill-medium {
  background-color: $color-grey-mystic;
}

.c-panel__header--graphic {
  position: relative;
  padding-top: to-rem(65);
}

.c-panel__header-tagline--graphic {
  position: absolute;
  top: to-rem($spacing-two-thirds);
  left: 50%;
  transform: translateX(-50%);
  font-size: to-rem($font-size-x-small);
}
