.react-footer footer,
.react-footer nav {
  display: block
}

.react-footer a {
  background-color: transparent
}

.react-footer a:active,
.react-footer a:hover {
  outline: 0
}

.react-footer small {
  font-size: 80%
}

.react-footer a,
.react-footer div,
.react-footer footer,
.react-footer li,
.react-footer nav,
.react-footer small,
.react-footer span,
.react-footer ul {
  margin: 0;
  padding: 0;
  border: 0
}

.react-footer *,
.react-footer ::after,
.react-footer ::before {
  box-sizing: inherit
}

.react-footer ul {
  list-style-type: none
}

.react-footer ::-webkit-input-placeholder {
  color: #999
}

.react-footer ::-moz-placeholder {
  color: #999;
  opacity: 1
}

.react-footer :-ms-input-placeholder {
  color: #999
}

.react-footer :input-placeholder {
  color: #999
}

.react-footer ::input-placeholder {
  color: #999
}

.react-footer a {
  color: #f16439;
  text-decoration: none;
  transition: all .15s ease;
  font-weight: 900
}

.react-footer a:focus,
.react-footer a:hover {
  color: #41617d
}

.react-footer :focus {
  outline-style: dotted;
  outline-width: 1px
}

@media print {

  .react-footer *,
  .react-footer ::after,
  .react-footer ::before {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important
  }

  .react-footer nav {
    display: none !important
  }

  .react-footer a,
  .react-footer a:visited {
    text-decoration: underline !important;
    border-bottom: 0 !important
  }

  .react-footer a[href]::after {
    content: " ("attr(href) ")"
  }
}

.react-footer .l-container {
  width: 92%;
  min-width: 13.75rem;
  max-width: 76.5rem;
  margin: 0 auto
}

@media print {
  .react-footer .l-container {
    width: auto
  }
}

.react-footer .l-footer {
  background-color: #f7f8fa;
  padding: 1.5625rem;
  position: relative;
  z-index: 3
}

.react-footer .l-footer .l-flex-container {
  display: flex;
  justify-content: space-between
}

.react-footer .l-footer__nav-item {
  font-size: .875rem;
  margin: 0 .3125rem
}

.react-footer .l-footer__nav-link {
  display: block;
  font-weight: 400;
  text-decoration: none;
  padding-top: .1875rem;
  padding-bottom: .1875rem;
  color: #21282e
}

.react-footer .l-footer__nav-link:focus,
.react-footer .l-footer__nav-link:hover {
  color: #f16439
}

.react-footer .l-footer--dark {
  background-color: #2b333b;
  color: #97b1c5
}

.react-footer .l-footer--dark .bullet-separator {
  display: block;
  width: 5px;
  height: 5px;
  background-color: #fff;
  border-radius: 100%;
  margin-left: 10px
}

.react-footer .l-footer--dark .l-footer__nav-item-inner {
  display: flex;
  align-items: center
}

.react-footer .l-footer--dark .l-footer__nav-link {
  color: #fff
}

.react-footer .l-footer--dark .l-footer__nav-link:focus,
.react-footer .l-footer--dark .l-footer__nav-link:hover {
  color: #f16439
}

.react-footer .text-button {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.react-footer .text-button span {
  color: #97b1c5 !important;
  transition: all .15s ease;
}

.react-footer .text-button:focus span,
.react-footer .text-button span:hover {
  color: #f16439 !important;
}

@media (min-width: 768px)
{
  #block-footersecondary {
    display: flex;
  }

  .react-footer .text-button {
    margin-right: 1.5rem;
  }
}