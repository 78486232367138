/* ============================================================================
   @CORE -> LAYOUT -> STRAP
   ========================================================================= */

/**
 * Straps are a generic layout container. A strap can be nested.
 */

.l-strap {
  padding: to-rem(50) 0;
}

/**
 * Modifer: large
 */

.l-strap--large {
  padding: to-rem(36) 0;

  @include respond-to(lap) {
    padding: to-rem(90) 0;
  }
}

/**
 * Modifier: Border
 */

.l-strap--border {
  border: 1px solid $color-grey-mystic;
}

/**
 * Modifier: Border Top
 */

.l-strap--border-top {
  border-top: 1px solid $color-grey-mystic;
}

/**
 * Modifier: Border Bottom
 */

.l-strap--border-bottom {
  border-bottom: 1px solid $color-grey-mystic;
}

/**
 * Modifier: Main content
 */

.l-strap--main {
  padding-top: to-rem($spacing-base-plus-half);
}

/**
 * Modifier: Thin (no padding)
 */

.l-strap--thin {
  padding: 0;
}

/**
 * Modifier: White background
 */

.l-strap--light {
  background-color: $color-white;
}
