/* ============================================================================
   @CORE -> BASE -> LINKS
   ========================================================================= */

/**
 * Base link styles. At the bare minimum a colour is applied for both default
 * and hover states. There is the option to remove the underline for the
 * default state and apply it on the hover state instead, and an option to
 * apply a bottom border as the underline instead of the browser default
 * `text-decoration: underline` plus an option to remove the underline
 * altogether. You can also choose to apply a transition to the link colour
 * and its underline—if the bottom border option is selected—when
 * hovering/focusing the link and this transition will apply to ALL properties
 * that you add in your project specific styles.
 *
 * N.B. a mixin is used to contain the base link styles so that it can be
 * easily shared with other parts of Scally e.g.
 *
 * - Link complex object
 * - Button faux component
 */

/**
 * Settings.
 */

// Colours
$link-color: $color-brand !default;

$link-color-hover: $color-blue-bismark;

// Transition parameters
$link-transition-duration: 0.15s !default;

$link-transition-timing-function: ease !default;

@mixin base-link {
  color: $link-color;
  text-decoration: none;
  transition: all $link-transition-duration $link-transition-timing-function;

  /**
   * Hover and focus states.
   */

  &:hover,
  &:focus {
    color: $link-color-hover;
  }
}

a {
  @include base-link();
  font-weight: $font-weight-bold;

  &.is-disabled {
    pointer-events: none;
  }
}

.link-inverse {
  color: $link-color-hover;

  &:hover,
  &:focus {
    color: $link-color;
  }
}

/**
 * Sets the solid opacity for hover state
 */

.link-hover-opaque {
  color: rgba($color-white, 0.64);

  &:hover,
  &:focus,
  &.is-active,
  &.is-selected {
    color: rgba($color-white, 1);
  }
}
