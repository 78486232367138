/* ============================================================================
   @LAYOUOT -> TEMPLATE -> FOOTER
   ========================================================================= */

/**
 * The site footer. With a modifier to make it dark.
 */

.l-footer {
  background-color: $color-grey-athens;
  padding: to-rem(25);
  position: relative;
  z-index: index($z-index-elements-global, footer);

  .l-flex-container {
    display: flex;
    justify-content: space-between;
  }
}

.l-footer__section-heading {
  margin-bottom: to-rem(14);
}

/**
 * Nav list items
 */

.l-footer__nav-item {
  font-size: to-rem($font-size-x-small);
  margin: 0 to-rem(5);
}

/**
 * Links.
 */

.l-footer__nav-link {
  display: block;
  font-weight: $font-weight-light;
  text-decoration: none;
  padding-top: to-rem(3);
  padding-bottom: to-rem(3);
  color: $color-text-base;

  &:hover,
  &:focus {
    color: $color-brand;
  }
}

/**
 * Social Follow
 */

.l-footer__social-follow {
  position: relative;
}

.l-footer__social-follow-inner {
  @include respond-to(lap-large) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.l-footer__social-follow-heading {
  font-size: to-rem(20);
  font-weight: $font-weight-light;
  white-space: nowrap;
}

/**
 * Copyright Bar
 */

.l-footer__copyright {
  padding-top: to-rem(20);
  padding-bottom: to-rem(20);
  margin-top: to-rem(50);
  background-color: $color-grey-athens-2;
}

/**
 * Modifer: Dark Theme
 */

.l-footer--dark {
  background-color: $color-blue-outer-space;
  color: $color-grey-nepal;

  // This is necessary because the :after pseudo element wouldn't work in some pages.
  .bullet-separator {
    display: block;
    width: 5px;
    height: 5px;
    background-color: $color-white;
    border-radius: 100%;
    margin-left: 10px;
  }

  .l-footer__nav-item-inner {
    display: flex;
    align-items: center;
  }

  .l-footer__nav-inline-link {
    &:hover,
    &:focus {
      color: $color-white;
    }
  }

  .l-footer__nav-link {
    color: $color-white;

    &:hover,
    &:focus {
      color: $color-brand;
    }
  }

  .l-footer__copyright {
    background-color: $color-blue-outer-space;
  }
}
