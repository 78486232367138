/* ============================================================================
   @CORE -> RESET
   ========================================================================= */

/**
 * In addition to normalize.css we also apply a reset which mainly removes all
 * margins, paddings, and borders, from all elements, and applies the nice
 * `border-box` value for `box-sizing` amongst a few other things.
 */

/**
 * Settings.
 */

// Reset the `box-sizing` property to the more friendly `border-box` value
$apply-friendly-box-model: true !default;

/**
 * Remove all margins, paddings, and borders.
 *
 * N.B. the complete list of HTML5 elements
 * https://developer.mozilla.org/en/docs/Web/Guide/HTML/HTML5/HTML5_element_list
 */

a,
abbr,
acronym,
address,
applet,
article,
aside,
b,
blockquote,
body,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
dialog,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
main,
menu,
nav,
object,
ol,
p,
pre,
q,
s,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
u,
ul,
var {
  margin: 0;
  padding: 0;
  border: 0;
}

/**
 * Reset the `box-sizing` property to the more friendly `border-box` value.
 *
 * @credit
 * http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */

@if $apply-friendly-box-model {
  html {
    box-sizing: border-box;
  }

  * {
    &,
    &::before,
    &::after {
      box-sizing: inherit;
    }
  }
}

/**
 * Reset lists.
 */

ul,
ol {
  list-style-type: none;
}

/**
 * Suppress the focus outline on links that cannot be accessed via a keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 *
 * N.B. it is okay to use `!important` here as we're doing it preemptively
 * i.e. you know you will always want the rule it's applied too to take
 * precedence.
 *
 * @credit
 * https://github.com/suitcss/
 */

[tabindex='-1']:focus {
  outline: none !important;
}

/**
 * Remove underlines from potentially troublesome elements.
 */

u,
ins {
  text-decoration: none;
}

/**
 * Apply faux underlines to inserted text via `border-bottom`.
 */

ins {
  border-bottom: 1px solid;
}
