/* ============================================================================
   @COMPONENTS -> LETTERBOX
   ========================================================================= */

/**
    * Designed for page layouts where the main content should expand regardless
    * of its content so that the footer stays at the bottom of the screen even
    * when there is no content.
    *
    * This flexbox technique doesn't work well for small screens, so we also set
    * a min-heightso that the top of the footer is just visible enough to let
    * the user know it's there.
    */

.c-letterbox {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.c-letterbox__main {
  flex-grow: 1;
  background-color: $color-grey-athens-2;
  min-height: 80vh;
}
