/* ============================================================================
   @CORE -> LAYOUT -> STRAP BACKGROUND
   ========================================================================= */

/**
 * Sets the background image for a strap
 */

.l-strap-background {
  padding: to-rem(50) 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/**
 * Modifier: Sets the background image for the Stats module on the homepage
 */

.l-strap-background--stats-image {
  // TODO need to find an appropriate strategy for image loading
  // In consuming products.
  //background: url('images/homepageStats-2500withfade.jpg');
  padding-bottom: to-rem(46);
}

/**
 * Modifier: Sets the background image for the Why Join module on the homepage
 */

.l-strap-background--why-join-image {
  background-size: contain;
  background-position: bottom left;
  padding: to-rem(36) 0 to-rem(90) 0;

  @include respond-to(lap) {
    background-position: bottom;
    padding: to-rem(90) 0 to-rem(150) 0;
  }

  @include respond-to(desk) {
    padding: to-rem(90) 0 to-rem(210) 0;
  }

  @include respond-to(desk-large) {
    padding: to-rem(90) 0 to-rem(280) 0;
  }
}
