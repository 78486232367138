/* ============================================================================
   @COMPONENTS -> STRUCTURED CONTENT
   ========================================================================= */

/**
 * Structured Content is the output of content entered in by the user
 * using the wysiwyg editor
 */

.c-structured-content {
  font-size: to-rem($font-size-x-small);
  line-height: 1.8;
}

/**
  * Unordered List
  */

.c-structured-content ul {
  margin-bottom: to-rem($spacing-base);
  margin-top: to-rem(-$spacing-base);

  &:first-child {
    margin-top: 0;
  }
  
  &:last-child {
    margin-bottom: 0;
  }

  & ul {
    margin-top: 0;
  }
}

.c-structured-content ul > li {
  list-style-type: disc;
  margin-left: to-rem($spacing-base);
}
