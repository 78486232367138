/* ============================================================================
   @UTILITIES -> WIDTHS
   ========================================================================= */

/**
 * Apply different width treatments to elements. There are two different
 * treatments you can apply:
 *
 * - Intrinsic widths
 * - Percentage widths
 *
 * Percentage based width classes are generated by the
 * `generate-percentage-classes-at-breakpoints()` mixin found here:
 * Core -> Settings -> Mixins. Each class gets its value and is named after
 * the Sass settings defined here: Core -> Settings -> Widths with the
 * addition of the utility namespace: `u-`, e.g. `$one-half` = `.u-one-half`.
 *
 * The default format for these classes is:
 *
    .u-one-whole
    .u-one-half
    .u-one-third
    .u-one-quarter
 *
 * ... and so on.
 *
 * Or in the more terse format:
 *
    .u-100pc
    .u-50pc
    .u-33pc
    .u-25pc
 *
 * ... and so on.
 *
 * The more terse format is turned off by default, to turn it on change this
 * setting: `$mixin-apply-terse-percentage-class-names` to 'true' above the
 * `@import` for this mixin in your master stylesheet e.g.
 *
   $mixin-apply-terse-percentage-class-names: true;
   @import "core/mixins/generate-percentage-classes-at-breakpoints";
 *
 * All the width classes can be applied at different breakpoints with the
 * format being:
 *
 * Using a `min-width` media query (default):
 *
   .u-[setting-name]-from-[breakpoint]
 *
 * E.g.
 *
   .u-one-half-from-lap
 *
 * Using a `max-width` media query:
 *
   .u-[setting-name]-up-to-[breakpoint]
 *
 * E.g.
 *
   .u-one-third-up-to-desk
 *
 * All the utilities (available as silent placeholder selectors also):
 *
   .u-width-shrink-wrap
   .u-width-shrink-wrap--right
   .u-width-fill-remaining-space
   .u-width-fill-remaining-space-alt
   .u-width-full-bleed
   .u-one-whole
   .u-one-half
   .u-one-third
   .u-two-thirds
   .u-one-quarter
   .u-two-quarters
   .u-three-quarters
   .u-one-fifth
   .u-two-fifths
   .u-three-fifths
   .u-four-fifths
   .u-one-sixth
   .u-two-sixths
   .u-three-sixths
   .u-four-sixths
   .u-five-sixths
   .u-one-seventh
   .u-two-sevenths
   .u-three-sevenths
   .u-four-sevenths
   .u-five-sevenths
   .u-six-sevenths
   .u-one-eighth
   .u-two-eighths
   .u-three-eighths
   .u-four-eighths
   .u-five-eighths
   .u-six-eighths
   .u-seven-eighths
   .u-one-ninth
   .u-two-ninths
   .u-three-ninths
   .u-four-ninths
   .u-five-ninths
   .u-six-ninths
   .u-seven-ninths
   .u-eight-ninths
   .u-one-tenth
   .u-two-tenths
   .u-three-tenths
   .u-four-tenths
   .u-five-tenths
   .u-six-tenths
   .u-seven-tenths
   .u-eight-tenths
   .u-nine-tenths
   .u-one-eleventh
   .u-two-elevenths
   .u-three-elevenths
   .u-four-elevenths
   .u-five-elevenths
   .u-six-elevenths
   .u-seven-elevenths
   .u-eight-elevenths
   .u-nine-elevenths
   .u-ten-elevenths
   .u-one-twelfth
   .u-two-twelfths
   .u-three-twelfths
   .u-four-twelfths
   .u-five-twelfths
   .u-six-twelfths
   .u-seven-twelfths
   .u-eight-twelfths
   .u-nine-twelfths
   .u-ten-twelfths
   .u-eleven-twelfths
 */

/**
 * Settings.
 */

/**
 * Apply at these breakpoints (turned off by default).
 */

$u-width-intrinsic-apply-at-breakpoints: $default-breakpoints !default;

$u-width-percentage-apply-at-breakpoints: $default-breakpoints !default;

// From the above breakpoints choose which intrinsic widths you want to apply it too?
$u-width-apply-at-breakpoints-for-shrink-wrap: false !default;

$u-width-apply-at-breakpoints-for-fill-remaining-space: false !default;

$u-width-apply-at-breakpoints-for-fill-remaining-space-alt: false !default;

$u-width-apply-at-breakpoints-for-full-bleed: false !default;

/**
 * Box sizing.
 *
 * Here we set a variable assuming that `box-sizing: border-box;` is not set
 * globally. If it has been previously been defined, the following variable
 * will be overridden and will be set to `true`.
 */

$apply-friendly-box-model: false !default;

/**
 * Intrinsic widths.
 */

/**
 * Make an element shrink wrap its content.
 */

%u-width-shrink-wrap,
.u-width-shrink-wrap {
  display: block !important;
  float: left !important;
  width: auto !important;
}

@if $u-width-apply-at-breakpoints-for-shrink-wrap {
  @include generate-at-breakpoints('.u-width-shrink-wrap',
    $u-width-intrinsic-apply-at-breakpoints) {
    display: block !important;
    float: left !important;
    width: auto !important;
  }
}

// Float it right
%u-width-shrink-wrap--right,
.u-width-shrink-wrap--right {
  float: right !important;
}

@if $u-width-apply-at-breakpoints-for-shrink-wrap {
  @include generate-at-breakpoints('.u-width-shrink-wrap--right',
    $u-width-intrinsic-apply-at-breakpoints) {
    float: right !important;
  }
}

/**
 * Make an element fill the remaining space.
 *
 * N.B. for the alternative method do not use if child elements might be wider
 * than the remaining space because in Chrome, Safari, and Firefox it results
 * in undesired layout.
 */

%u-width-fill-remaining-space,
.u-width-fill-remaining-space {
  display: block !important;
  overflow: hidden !important;
  width: auto !important;
}

@if $u-width-apply-at-breakpoints-for-fill-remaining-space {
  @include generate-at-breakpoints('.u-width-fill-remaining-space',
    $u-width-intrinsic-apply-at-breakpoints) {
    display: block !important;
    overflow: hidden !important;
    width: auto !important;
  }
}

// Alternative method
%u-width-fill-remaining-space-alt,
.u-width-fill-remaining-space-alt {
  display: table-cell !important;
  max-width: 100% !important;
  width: 10000px !important;
}

@if $u-width-apply-at-breakpoints-for-fill-remaining-space-alt {
  @include generate-at-breakpoints('.u-width-fill-remaining-space-alt',
    $u-width-intrinsic-apply-at-breakpoints) {
    display: table-cell !important;
    max-width: 100% !important;
    width: 10000px !important;
  }
}

/**
 * Make an element the width of its parent.
 */

%u-width-full-bleed,
.u-width-full-bleed {
  display: block !important;
  width: 100% !important;

  @if $apply-friendly-box-model == false {
    box-sizing: border-box !important;
  }
}

@if $u-width-apply-at-breakpoints-for-full-bleed {
  @include generate-at-breakpoints('.u-width-full-bleed',
    $u-width-intrinsic-apply-at-breakpoints) {
    display: block !important;
    width: 100% !important;

    @if $apply-friendly-box-model == false {
      box-sizing: border-box !important;
    }
  }
}

/**
 * Percentage widths.
 */

@include generate-percentage-classes-at-breakpoints($u-width-percentage-apply-at-breakpoints);

/**
 * Apply different width treatments to elements.
 *
 * N.B. this extends the Scally width utilities.
 */

/**
 * Settings
 */

$u-width-apply-at-breakpoints-for-auto: false !default;

/**
 * Width auto
 */

.u-width-auto {
  width: auto;
}

@if $u-width-apply-at-breakpoints-for-auto {
  @include generate-at-breakpoints('.u-width-auto{bp}',
  $l-flex-grid-apply-at-breakpoints) {
    width: auto;
  }
}
