
$spacing-tiny: 8;
$spacing-small: 16;
$spacing-medium: 24;
$spacing-large: 32;
$spacing-huge: 40;
$color-paper-110: #f6f3f1;
$color-red-100: #f6c8d9;
$color-red-500: #d81b60;
$color-red-700: #a61d54;
$color-blue-600: #2e59c9;
$color-purple-100: #cab4f1;
$color-purple-400: #8b54f0;
$color-purple-800: #58349a;
$color-teal-100: #d5fafa;
$color-teal-200: #2bcfce;
$color-teal-500: #047d8c;
$color-teal-900: #014f59;
$color-volcano-900: #2d2c34;
$color-neutral-dark-040: rgba(0, 0, 0, 0.04);
$color-neutral-dark-080: rgba(0, 0, 0, 0.08);
$color-neutral-dark-100: rgba(0, 0, 0, 0.12);
$color-neutral-dark-150: rgba(0, 0, 0, 0.16);
$color-neutral-dark-200: rgba(0, 0, 0, 0.28);
$color-neutral-dark-300: rgba(0, 0, 0, 0.32);
$color-neutral-dark-400: rgba(0, 0, 0, 0.4);
$color-neutral-dark-500: rgba(0, 0, 0, 0.52);
$color-neutral-dark-600: rgba(0, 0, 0, 0.6);
$color-neutral-dark-700: rgba(0, 0, 0, 0.72);
$color-neutral-dark-800: rgba(0, 0, 0, 0.8);
$color-neutral-dark-900: rgba(0, 0, 0, 0.92);
$color-neutral-dark-1000: rgb(0, 0, 0);
$color-neutral-light-040: rgba(255, 255, 255, 0.04);
$color-neutral-light-080: rgba(255, 255, 255, 0.08);
$color-neutral-light-100: rgba(255, 255, 255, 0.12);
$color-neutral-light-150: rgba(255, 255, 255, 0.16);
$color-neutral-light-200: rgba(255, 255, 255, 0.28);
$color-neutral-light-300: rgba(255, 255, 255, 0.32);
$color-neutral-light-400: rgba(255, 255, 255, 0.4);
$color-neutral-light-500: rgba(255, 255, 255, 0.52);
$color-neutral-light-600: rgba(255, 255, 255, 0.6);
$color-neutral-light-700: rgba(255, 255, 255, 0.72);
$color-neutral-light-800: rgba(255, 255, 255, 0.8);
$color-neutral-light-900: rgba(255, 255, 255, 0.92);
$color-neutral-light-1000: rgb(255, 255, 255);
$color-legacy-orange-300: #f16439;
$color-legacy-orange-400: #d0582d;
$breakpoint-huge: 1280;
$breakpoint-large: 1128;
$breakpoint-medium: 601;
$font-size-body-copy-1: 10;
$font-size-body-copy-2: 11;
$font-size-body-copy-3: 12;
$font-size-body-copy-4: 13;
$font-size-body-copy-5: 14;
$font-size-body-copy-6: 16;
$font-size-body-copy-7: 20;
$font-size-body-copy-8: 24;
$line-height-body-copy-1: 16;
$line-height-body-copy-2: 18;
$line-height-body-copy-3: 20;
$line-height-body-copy-4: 24;
$line-height-body-copy-5: 24;
$line-height-body-copy-6: 28;
$line-height-body-copy-7: 36;
$line-height-body-copy-8: 40;
$font-size-title-1: 14;
$font-size-title-2: 16;
$font-size-title-3: 20;
$font-size-title-4: 24;
$font-size-title-5: 28;
$font-size-title-6: 32;
$font-size-title-7: 36;
$font-size-title-8: 40;
$line-height-title-1: 24;
$line-height-title-2: 28;
$line-height-title-3: 36;
$line-height-title-4: 40;
$line-height-title-5: 44;
$line-height-title-6: 48;
$line-height-title-7: 52;
$line-height-title-8: 56;
$font-size-section-title-1: 10;
$font-size-section-title-2: 11;
$font-size-section-title-3: 12;
$font-size-section-title-4: 14;
$line-height-section-title-1: 16;
$line-height-section-title-2: 18;
$line-height-section-title-3: 20;
$line-height-section-title-4: 24;
$font-weight-light: 400;
$font-weight-bold: 900;