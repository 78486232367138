$c-availability-size: 8;

.c-availability {
  padding-left: to-rem($c-availability-size * 2);
  position: relative;
  font-size: to-rem($font-size-xxx-small);
  line-height: 1.67;

  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    display: inline-block;
    content: '';
    height: to-rem($c-availability-size);
    width: to-rem($c-availability-size);
    margin-top: to-rem(- calc($c-availability-size / 2));
    border-radius: 100%;
    background-color: $color-blue-bismark;
  }

  &.c-availability--active::before {
    background-color: $color-green-asparagus;
  }

  &.c-availability--unavailable::before {
    background-color: $color-grey-loblolly;
  }

  &.c-availability--open::before {
    background-color: $color-blue-danube;
  }
}
