/* ============================================================================
   @CORE -> MIXINS -> TARGET HEADINGS
   ========================================================================= */

/**
 * Style any number of headings in one fell swoop, supports double stranded
 * headings.
 *
 * @example
   .foo {
     @include headings(1, 3) {
       color: red;
     }
   }
 *
 * @credit
 * https://github.com/csswizardry/inuit.css/blob/master/generic/_mixins.scss
 */

@mixin headings($from: 1, $to: 6, $double-stranded: false) {
  $selector-list: ();
  @if $from >= 1 and $to <= 6 {
    @for $i from $from through $to {
      $selector: 'h#{$i}';

      @if $double-stranded {
        $selector: '#{$selector}, .#{$selector}';
      }

      $selector-list: append($selector-list, unquote($selector), comma);
    }

    #{$selector-list} {
      @content;
    }
  }
  @else {
    @warn 'You can only target headings 1 to 6';
  }
}
