/* ============================================================================
   @COMPONENTS -> BUTTON GROUP
   ========================================================================= */

/**
 * Group buttons together to create a navigation bar
 */

/**
 * Button Group
 */

.c-button-group {
  display: flex;
}

/**
 * Modifier: Button Group Inline
 */

.c-button-group--inline {
  display: inline-flex;
}

/**
 * Button Group Item
 *
 * 1. Stop child elements from overflowing the border radius
 */

.c-button-group__item {
  border-right: 1px solid $color-grey-mystic;
  flex: 1 1 0%;
  overflow-x: hidden; // [1]

  @include respond-to(lap) {
    flex: auto;
  }

  /**
   * Add rounded corners to left side of first button
   */

  &:first-child {
    border-bottom-left-radius: to-rem($border-radius);
    border-top-left-radius: to-rem($border-radius);
  }

  /**
   * Remove border and add rounded corners to right side on last button in group
   */

  &:last-child {
    border-bottom-right-radius: to-rem($border-radius);
    border-right: none;
    border-top-right-radius: to-rem($border-radius);
  }
}

/**
 * Modifer: Spacing on the ends
 */

.c-button-group__item--spacing-ends {
  padding-top: to-rem($spacing-half);
  padding-bottom: to-rem($spacing-half);
}

/**
 * Modifer: Spacing on the sides
 */

.c-button-group__item--spacing-sides {
  padding-left: to-rem($spacing-half);
  padding-right: to-rem($spacing-half);
  overflow-x: visible;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}
