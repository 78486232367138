/* ============================================================================
    @UTILITIES -> TEXT
    ========================================================================= */

/**
  * Various handy utilities for text.
  *
  * All the utilities (available as silent placeholder selectors also):
  *
    .u-text-size-small
    .u-text-size-x-small
    .u-text-size-xx-small
    .u-text-size-xxx-small
    .u-text-size-large
    .u-text-size-x-large
    .u-text-size-xx-large
    .u-text-size-xxx-large
    .u-text-size-base
    .u-text-size-inherit
    .u-text-align-center
    .u-text-align-left
    .u-text-align-right
    .u-text-align-inherit
    .u-text-style-italic
    .u-text-style-normal
    .u-text-style-inherit
    .u-text-weight-lighter
    .u-text-weight-bold
    .u-text-weight-inherit
    .u-text-weight-normal
    .u-text-transform-uppercase
    .u-text-transform-lowercase
    .u-text-transform-capitalise
    .u-text-transform-inherit
    .u-text-transform-none
    .u-text-whitespace-normal
    .u-text-whitespace-pre
    .u-text-whitespace-nowrap
    .u-text-truncate
    .u-text-kern
    .u-text-break-word
    .u-text-hyphenate
    .u-text-osx-font-smoothing
  */

/**
  * Settings.
  */

/**
  * Apply at these breakpoints (turned off by default).
  */

$u-text-apply-at-breakpoints: $default-breakpoints !default;

// From the above breakpoints choose which utilities you wish to apply it too
$u-text-apply-at-breakpoints-for-size-small: false !default;

$u-text-apply-at-breakpoints-for-size-x-small: false !default;

$u-text-apply-at-breakpoints-for-size-xx-small: false !default;

$u-text-apply-at-breakpoints-for-size-xxx-small: false !default;

$u-text-apply-at-breakpoints-for-size-large: false !default;

$u-text-apply-at-breakpoints-for-size-x-large: false !default;

$u-text-apply-at-breakpoints-for-size-xx-large: false !default;

$u-text-apply-at-breakpoints-for-size-xxx-large: false !default;

$u-text-apply-at-breakpoints-for-size-base: false !default;

$u-text-apply-at-breakpoints-for-size-inherit: false !default;

$u-text-apply-at-breakpoints-for-align-left: false !default;

$u-text-apply-at-breakpoints-for-align-center: false !default;

$u-text-apply-at-breakpoints-for-align-right: false !default;

$u-text-apply-at-breakpoints-for-align-inherit: false !default;

$u-text-apply-at-breakpoints-for-style-italic: false !default;

$u-text-apply-at-breakpoints-for-style-normal: false !default;

$u-text-apply-at-breakpoints-for-style-inherit: false !default;

$u-text-apply-at-breakpoints-for-weight-lighter: false !default;

$u-text-apply-at-breakpoints-for-weight-bold: false !default;

$u-text-apply-at-breakpoints-for-weight-inherit: false !default;

$u-text-apply-at-breakpoints-for-weight-normal: false !default;

$u-text-apply-at-breakpoints-for-transform-uppercase: false !default;

$u-text-apply-at-breakpoints-for-transform-lowercase: false !default;

$u-text-apply-at-breakpoints-for-transform-capitalise: false !default;

$u-text-apply-at-breakpoints-for-transform-inherit: false !default;

$u-text-apply-at-breakpoints-for-transform-none: false !default;

$u-text-apply-at-breakpoints-for-whitespace-normal: false !default;

$u-text-apply-at-breakpoints-for-whitespace-pre: false !default;

$u-text-apply-at-breakpoints-for-whitespace-nowrap: false !default;

$u-text-apply-at-breakpoints-for-truncate: false !default;

$u-text-apply-at-breakpoints-for-break-word: false !default;

$u-text-apply-at-breakpoints-for-hyphenate: false !default;

$u-text-apply-at-breakpoints-for-osx-font-smoothing: false !default;

/**
  * Size.
  */

// Smaller
%u-text-size-small,
.u-text-size-small {
  @include font-size($font-size-small, auto, !important);
}

@if $u-text-apply-at-breakpoints-for-size-small {
  @include generate-at-breakpoints('.u-text-size-small',
     $u-text-apply-at-breakpoints) {
    @include font-size($font-size-small, auto, !important);
  }
}

%u-text-size-x-small,
.u-text-size-x-small {
  @include font-size($font-size-x-small, auto, !important);
}

@if $u-text-apply-at-breakpoints-for-size-x-small {
  @include generate-at-breakpoints('.u-text-size-x-small',
     $u-text-apply-at-breakpoints) {
    @include font-size($font-size-x-small, auto, !important);
  }
}

%u-text-size-xx-small,
.u-text-size-xx-small {
  @include font-size($font-size-xx-small, auto, !important);
}

@if $u-text-apply-at-breakpoints-for-size-xx-small {
  @include generate-at-breakpoints('.u-text-size-xx-small',
     $u-text-apply-at-breakpoints) {
    @include font-size($font-size-xx-small, auto, !important);
  }
}

%u-text-size-xxx-small,
.u-text-size-xxx-small {
  @include font-size($font-size-xxx-small, auto, !important);
}

@if $u-text-apply-at-breakpoints-for-size-xxx-small {
  @include generate-at-breakpoints('.u-text-size-xxx-small',
     $u-text-apply-at-breakpoints) {
    @include font-size($font-size-xxx-small, auto, !important);
  }
}

// Larger
%u-text-size-large,
.u-text-size-large {
  @include font-size($font-size-large, auto, !important);
}

@if $u-text-apply-at-breakpoints-for-size-large {
  @include generate-at-breakpoints('.u-text-size-large',
     $u-text-apply-at-breakpoints) {
    @include font-size($font-size-large, auto, !important);
  }
}

%u-text-size-x-large,
.u-text-size-x-large {
  @include font-size($font-size-x-large, auto, !important);
}

@if $u-text-apply-at-breakpoints-for-size-x-large {
  @include generate-at-breakpoints('.u-text-size-x-large',
     $u-text-apply-at-breakpoints) {
    @include font-size($font-size-x-large, auto, !important);
  }
}

%u-text-size-xx-large,
.u-text-size-xx-large {
  @include font-size($font-size-xx-large, auto, !important);
}

@if $u-text-apply-at-breakpoints-for-size-xx-large {
  @include generate-at-breakpoints('.u-text-size-xx-large',
     $u-text-apply-at-breakpoints) {
    @include font-size($font-size-xx-large, auto, !important);
  }
}

%u-text-size-xxx-large,
.u-text-size-xxx-large {
  @include font-size($font-size-xxx-large, auto, !important);
}

@if $u-text-apply-at-breakpoints-for-size-xxx-large {
  @include generate-at-breakpoints('.u-text-size-xxx-large',
     $u-text-apply-at-breakpoints) {
    @include font-size($font-size-xxx-large, auto, !important);
  }
}

// Base
%u-text-size-base,
.u-text-size-base {
  @include font-size($font-size, auto, !important);
}

@if $u-text-apply-at-breakpoints-for-size-base {
  @include generate-at-breakpoints('.u-text-size-base',
     $u-text-apply-at-breakpoints) {
    @include font-size($font-size, auto, !important);
  }
}

// Inherit
%u-text-size-inherit,
.u-text-size-inherit {
  font-size: inherit !important;
}

@if $u-text-apply-at-breakpoints-for-size-inherit {
  @include generate-at-breakpoints('.u-text-size-inherit',
     $u-text-apply-at-breakpoints) {
    font-size: inherit !important;
  }
}

/**
  * Alignment.
  */

%u-text-align-center,
.u-text-align-center {
  text-align: center !important;
}

@if $u-text-apply-at-breakpoints-for-align-center {
  @include generate-at-breakpoints('.u-text-align-center',
     $u-text-apply-at-breakpoints) {
    text-align: center !important;
  }
}

%u-text-align-left,
.u-text-align-left {
  text-align: left !important;
}

@if $u-text-apply-at-breakpoints-for-align-left {
  @include generate-at-breakpoints('.u-text-align-left',
     $u-text-apply-at-breakpoints) {
    text-align: left !important;
  }
}

%u-text-align-right,
.u-text-align-right {
  text-align: right !important;
}

@if $u-text-apply-at-breakpoints-for-align-right {
  @include generate-at-breakpoints('.u-text-align-right',
     $u-text-apply-at-breakpoints) {
    text-align: right !important;
  }
}

%u-text-align-inherit,
.u-text-align-inherit {
  text-align: inherit !important;
}

@if $u-text-apply-at-breakpoints-for-align-inherit {
  @include generate-at-breakpoints('.u-text-align-inherit',
     $u-text-apply-at-breakpoints) {
    text-align: inherit !important;
  }
}

/**
  * Style.
  */

%u-text-style-italic,
.u-text-style-italic {
  font-style: italic !important;
}

@if $u-text-apply-at-breakpoints-for-style-italic {
  @include generate-at-breakpoints('.u-text-style-italic',
     $u-text-apply-at-breakpoints) {
    font-style: italic !important;
  }
}

%u-text-style-normal,
.u-text-style-normal {
  font-style: normal !important;
}

@if $u-text-apply-at-breakpoints-for-style-normal {
  @include generate-at-breakpoints('.u-text-style-normal',
     $u-text-apply-at-breakpoints) {
    font-style: normal !important;
  }
}

%u-text-style-inherit,
.u-text-style-inherit {
  font-style: inherit !important;
}

@if $u-text-apply-at-breakpoints-for-style-inherit {
  @include generate-at-breakpoints('.u-text-style-inherit',
     $u-text-apply-at-breakpoints) {
    font-style: inherit !important;
  }
}

/**
  * Weight.
  */

// Lighter
%u-text-weight-lighter,
.u-text-weight-lighter {
  font-weight: lighter !important;
}

@if $u-text-apply-at-breakpoints-for-weight-lighter {
  @include generate-at-breakpoints('.u-text-weight-lighter',
     $u-text-apply-at-breakpoints) {
    font-weight: lighter !important;
  }
}

// Bolder
%u-text-weight-bold,
.u-text-weight-bold {
  font-weight: bold !important;
}

@if $u-text-apply-at-breakpoints-for-weight-bold {
  @include generate-at-breakpoints('.u-text-weight-bold',
     $u-text-apply-at-breakpoints) {
    font-weight: bold !important;
  }
}

// Inherit
%u-text-weight-inherit,
.u-text-weight-inherit {
  font-weight: inherit !important;
}

@if $u-text-apply-at-breakpoints-for-weight-inherit {
  @include generate-at-breakpoints('.u-text-weight-inherit',
     $u-text-apply-at-breakpoints) {
    font-weight: inherit !important;
  }
}

// Normal
%u-text-weight-normal,
.u-text-weight-normal {
  font-weight: normal !important;
}

@if $u-text-apply-at-breakpoints-for-weight-normal {
  @include generate-at-breakpoints('.u-text-weight-normal',
     $u-text-apply-at-breakpoints) {
    font-weight: normal !important;
  }
}

/**
  * Transform.
  */

%u-text-transform-uppercase,
.u-text-transform-uppercase {
  text-transform: uppercase !important;
}

@if $u-text-apply-at-breakpoints-for-transform-uppercase {
  @include generate-at-breakpoints('.u-text-transform-uppercase',
     $u-text-apply-at-breakpoints) {
    text-transform: uppercase !important;
  }
}

%u-text-transform-lowercase,
.u-text-transform-lowercase {
  text-transform: lowercase !important;
}

@if $u-text-apply-at-breakpoints-for-transform-lowercase {
  @include generate-at-breakpoints('.u-text-transform-lowercase',
     $u-text-apply-at-breakpoints) {
    text-transform: lowercase !important;
  }
}

%u-text-transform-capitalise,
.u-text-transform-capitalise {
  text-transform: capitalize !important;
}

@if $u-text-apply-at-breakpoints-for-transform-capitalise {
  @include generate-at-breakpoints('.u-text-transform-capitalise',
     $u-text-apply-at-breakpoints) {
    text-transform: capitalize !important;
  }
}

%u-text-transform-inherit,
.u-text-transform-inherit {
  text-transform: inherit !important;
}

@if $u-text-apply-at-breakpoints-for-transform-inherit {
  @include generate-at-breakpoints('.u-text-transform-inherit',
     $u-text-apply-at-breakpoints) {
    text-transform: inherit !important;
  }
}

%u-text-transform-none,
.u-text-transform-none {
  text-transform: none !important;
}

@if $u-text-apply-at-breakpoints-for-transform-none {
  @include generate-at-breakpoints('.u-text-transform-none',
     $u-text-apply-at-breakpoints) {
    text-transform: none !important;
  }
}

/**
  * Whitespace.
  */

%u-text-whitespace-normal,
.u-text-whitespace-normal {
  white-space: normal !important;
}

@if $u-text-apply-at-breakpoints-for-whitespace-normal {
  @include generate-at-breakpoints('.u-text-whitespace-normal',
     $u-text-apply-at-breakpoints) {
    white-space: normal !important;
  }
}

%u-text-whitespace-pre,
.u-text-whitespace-pre {
  white-space: pre !important;
}

@if $u-text-apply-at-breakpoints-for-whitespace-pre {
  @include generate-at-breakpoints('.u-text-whitespace-pre',
     $u-text-apply-at-breakpoints) {
    white-space: pre !important;
  }
}

%u-text-whitespace-nowrap,
.u-text-whitespace-nowrap {
  white-space: nowrap !important;
}

@if $u-text-apply-at-breakpoints-for-whitespace-nowrap {
  @include generate-at-breakpoints('.u-text-whitespace-nowrap',
     $u-text-apply-at-breakpoints) {
    white-space: nowrap !important;
  }
}

/**
  * Truncation.
  *
  * Prevent text from wrapping onto multiple lines, and truncate with an
  * ellipsis.
  *
  * 1. Ensure that the node has a maximum width after which truncation can
  *    occur.
  * 2. Fix for IE 8/9 if `word-wrap: break-word` is in effect on ancestor nodes.
  *
  * @credit
  * https://github.com/suitcss/utils-text/blob/master/lib/text.css
  */

%u-text-truncate,
.u-text-truncate {
  max-width: 100% !important; // [1]
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  word-wrap: normal !important; // [2]
}

@if $u-text-apply-at-breakpoints-for-truncate {
  @include generate-at-breakpoints('.u-text-truncate',
     $u-text-apply-at-breakpoints) {
    max-width: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
  }
}

/**
  * Enables font kerning in all browsers.
  *
  * 1. Chrome (not Windows), Firefox, Safari 6+, iOS, Android.
  * 2. Chrome (not Windows), Firefox, IE 10+.
  * 3. Safari 7 and future browsers.
  *
  * @credit
  * http://blog.typekit.com/2014/02/05/kerning-on-the-web/
  */

%u-text-kern,
.u-text-kern {
  text-rendering: optimizeLegibility !important; // [1]
  font-feature-settings: 'kern' 1 !important; // [2]
  font-kerning: normal !important; // [3]
}

/**
  * Word breaking.
  *
  * Break strings when their length exceeds the width of their container.
  */

%u-text-break-word,
.u-text-break-word {
  word-wrap: break-word !important;
}

@if $u-text-apply-at-breakpoints-for-break-word {
  @include generate-at-breakpoints('.u-text-break-word',
     $u-text-apply-at-breakpoints) {
    word-wrap: break-word !important;
  }
}

/**
  * Hyphenation.
  */

%u-text-hyphenate,
.u-text-hyphenate {
  hyphens: auto !important;
}

@if $u-text-apply-at-breakpoints-for-hyphenate {
  @include generate-at-breakpoints('.u-text-hyphenate',
     $u-text-apply-at-breakpoints) {
    hyphens: auto !important;
  }
}

.u-text-transform-capitalise-first-letter::first-letter {
  text-transform: capitalize !important;
}

.u-text-whitespace-prewrap {
  white-space: pre-wrap;
}

// Right align the text for various breakpoints
@include generate-at-breakpoints('.u-text-align-right{bp}',
  $u-text-apply-at-breakpoints) {
  text-align: right;
}
