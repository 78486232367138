/* ============================================================================
   @LAYOUT -> TEMPLATE -> TWO COLUMN
   ========================================================================= */

/**
 * A two column layout - a sidebar fixed to either side of the screen
 * and the main section on the other.
 */

/**
 * Settings
 */

$l-two-column-sidebar-width: 100;

$l-two-column-lap-large: 1020;

/**
 * Sidebar
 */

.l-two-column__sidebar {
  display: none;

  @include respond-to($l-two-column-lap-large) {
    background-color: $color-white;
    border-right: 2px solid $color-grey-loblolly;
    cursor: pointer;
    display: block;
    height: 100%;
    position: fixed;
    width: to-rem($l-two-column-sidebar-width);
  }
}

/**
 * Modifier: Padding
 */

.l-two-column__sidebar--spacing {
  padding: to-rem($spacing-half) to-rem($spacing-base);
}

/**
 * Layout
 */

.l-two-column__layout {
  @include respond-to($l-two-column-lap-large) {
    padding-left: to-rem($l-two-column-sidebar-width);
  }
}

/**
 * Offset an element
 */

/**
 * Right
 */

.l-two-column__layout-offset-right {
  @include respond-to($l-two-column-lap-large) {
    right: to-rem($l-two-column-sidebar-width);
    position: relative;
  }
}

/**
 * Modifier: Sidebar
 */

.l-two-column__sidebar--right {
  @include respond-to($l-two-column-lap-large) {
    right: 0;
    border-right: none;
    border-left: 2px solid $color-grey-loblolly;
  }
}

/**
 * Modifier: Layout
 */

.l-two-column__layout--left {
  @include respond-to($l-two-column-lap-large) {
    padding-left: 0;
    padding-right: to-rem($l-two-column-sidebar-width);
  }
}
