/* ============================================================================
   @COMPONENTS -> SUMMARY CARD
   ========================================================================= */

/**
 * Information is displayed in a card component, typically in a list.
 * It can include stats, expandable content and actions.
 */

.c-summary-card {
  box-shadow: 0 2px 0 0 $color-grey-mystic;
  background-color: $color-white;
  border-radius: to-rem($border-radius);
  position: relative;
}

/**
 * Status
 */

.c-summary-card__status {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  @include respond-to(745) {
    width: auto;
  }
}

/**
 * Header
 */

.c-summary-card__header {
  padding-left: to-rem($spacing-base);
  padding-right: to-rem($spacing-base);
  padding-top: to-rem($spacing-half);
  padding-bottom: to-rem($spacing-half);
  border-bottom: 1px solid $color-grey-mystic;
}

/**
 * Body
 */

.c-summary-card__body {
  position: relative;
  margin-top: to-rem($spacing-half);

  @include respond-to(745) {
    padding-bottom: to-rem($spacing-base-plus-half);
    padding-left: to-rem($spacing-base);
    padding-right: to-rem($spacing-base);
    padding-top: to-rem($spacing-half);
  }
}

/**
 * Avatar
 */

.c-summary-card__avatar {
  text-align: center;
  margin-bottom: to-rem($spacing-base);

  @include respond-to(745) {
    margin-bottom: 0;
  }
}

/**
 * Position label below avatar image
 */

.c-summary-card__avatar-label {
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
}

/**
 * Summary card section
 */

.c-summary-card__section {
  padding-left: to-rem($spacing-base);
  padding-right: to-rem($spacing-base);
  padding-top: to-rem($spacing-base);
  padding-bottom: to-rem($spacing-base);

  @include respond-to(745) {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

/**
 * Modifier: Spacing small
 */

.c-summary-card__section--spacing-small {
  padding-bottom: to-rem($spacing-half);
  padding-top: to-rem($spacing-half);

  @include respond-to(745) {
    padding-bottom: 0;
    padding-top: 0;
  }
}

/**
 * Modifier: No spacing
 */

.c-summary-card__section--no-spacing {
  padding-bottom: 0;
  padding-top: 0;
}

/**
 * Modifier: Divider
 */

.c-summary-card__section--divider {
  border-bottom: 1px solid $color-grey-mystic;

  @include respond-to(745) {
    border-bottom: 0;
  }
}

/**
 * Modifier: Equal height columns
 */

.c-summary-card__section--equal-height {
  display: flex;
  flex: 1 1 0%;
  height: 100%;
}

/**
 * Modifier: Align text center.
 */

.c-summary-card__section--align-text-center {
  text-align: center;

  @include respond-to(745) {
    text-align: left;
  }
}

/**
 * Skills List
 */

.c-summary-card__skills-list {
  text-align: center;

  @include respond-to(745) {
    text-align: left;
  }
}

/**
 * Tier box
 */

.c-summary-card__tier-box-service {
  color: $color-grey-slate;
  font-size: to-rem($font-size-x-small);
}

/**
 * Jobs list
 * List of jobs which are separated by a middle dot
 */

.c-summary-card__jobs-list {
  color: $color-grey-slate;
  line-height: 1;
}

.c-summary-card__jobs-list__item {
  display: inline;
  font-size: to-rem($font-size-xx-small);

  &::after {
    content: '\b7';
    display: inline-block;
    margin-left: to-rem($spacing-quarter);
    margin-right: to-rem($spacing-quarter);
    font-size: to-rem($font-size-x-large);
    vertical-align: sub;
  }

  &:last-child::after {
    display: none;
  }
}

/**
 * Expandable section
 */

.c-summary-card__expandable {
  @include respond-to(745) {
    padding-top: to-rem($spacing-base);
  }
}

/**
 * Card Actions
 */

.c-summary-card__action {
  @include respond-to(745) {
    padding-bottom: 0;
    padding-right: 0;
    padding-left: to-rem($spacing-base);
    padding-top: to-rem($spacing-base);
  }
}

/**
  * Extending `.c-collapsible-button` in `.c-summary-card`
  */

.c-summary-card__action .c-collapsible-button {
  @include respond-to(745) {
    width: 100%;
    max-width: to-rem(210);
    padding-left: to-rem($spacing-half);
    padding-right: to-rem($spacing-half);
  }
}

/**
  * Temporarily override colour of secondary button
  */

.c-summary-card__action .c-button--full-profile {
  box-shadow: inset 0 0 0 1px $color-brand;
  color: $color-brand;
  max-width: to-rem(200);
  margin: 0 auto;
  margin-bottom: to-rem($spacing-base);

  &:hover,
  &:focus,
  &.is-active {
    background-color: $color-brand;
    color: $color-white;
  }

  @include respond-to(745) {
    margin-bottom: 0;
  }
}

/**
 * Action Container
 */

.c-summary-card__action-container {
  position: absolute;
  top: 0;
  right: to-rem($spacing-quarter);

  @include respond-to(745) {
    position: static;
  }
}

/**
  * Card actions
  */

.c-summary-card__actions li {
  display: inline-block;
  margin-right: to-rem($spacing-quarter);
  margin-bottom: to-rem($spacing-quarter);
}

.c-summary-card__actions li:last-child {
  margin-bottom: 0;
}

/**
  * Extend `.c-button--primary` in `.c-summary-card`
  */

.c-summary-card .c-button--primary {
  width: 100%;
}
