/* ============================================================================
   @UTILITIES -> CIRCLE
   ========================================================================= */

/**
 * Make element a circle.
 */

.u-circle,
%u-circle {
  border-radius: 50%;
}
