/* ============================================================================
   @COMPONENTS -> DIVIDED LIST
   ========================================================================= */

/**
 * Divided list component is for vertical lists with dividing lines between list
 * items. By default the component removes the divider from the last list item.
 */

/**
 * Settings
 */

$c-divided-list-spacing-bottom: $spacing-base-plus-half;

$c-divided-list-spacing-sides: $spacing-base;

$c-divided-list-spacing-bottom-small: $spacing-half;

$c-divided-list-spacing-sides-small: $spacing-half;

$c-divided-list-spacing-bottom-small: $spacing-half;

/**
 * Divided list
 */

.c-divided-list .c-divided-list__item {
  border-bottom: 1px solid $color-grey-mystic;
  margin-bottom: to-rem($c-divided-list-spacing-bottom);
  padding-bottom: to-rem($c-divided-list-spacing-bottom);
  padding-left: to-rem($c-divided-list-spacing-sides);
  padding-right: to-rem($c-divided-list-spacing-sides);

  &:last-child {
    border: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.c-divided-list--compact .c-divided-list__item {
  border-bottom: 1px solid $color-grey-mystic;
  margin-bottom: to-rem($c-divided-list-spacing-bottom-small);
  padding-bottom: to-rem($c-divided-list-spacing-bottom-small);
  padding-left: 0;
  padding-right: 0;
}
