/* ============================================================================
   @COMPONENTS -> PAGINATION
   ========================================================================= */

/**
 * Pagination.
 */

/**
 * Settings.
 */

.pagination {
  height: 36px;
  margin: 18px 0;
}

.pagination ul {
  display: inline-block;
  margin-left: 0;
  margin-bottom: 0;
  border-radius: 3px;
}

.pagination li {
  display: inline;
}

.pagination a,
.pagination span {
  float: left;
  padding: 0 14px;
  text-decoration: none;
  border: 1px solid #ddd;
  border-left-width: 0;
}

.pagination a:hover,
.pagination .active a {
  background-color: #f5f5f5;
}

.pagination .active a {
  color: #999;
  cursor: default;
}

.pagination .disabled span,
.pagination .disabled a,
.pagination .disabled a:hover {
  color: #999;
  background-color: transparent;
  cursor: default;
}

.pagination li:first-child a {
  border-left-width: 1px;
  border-radius: 3px 0 0 3px;
}

.pagination li:last-child a {
  border-radius: 0 3px 3px 0;
}

.pagination-centered {
  text-align: center;
}

.pagination-right {
  text-align: right;
}
