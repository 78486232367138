/* ============================================================================
   @CORE -> BASE -> PARAGRAPHS
   ========================================================================= */

p {
  @extend %bottom-spacing;
}

.body-copy {
  font-size: to-rem($font-size);
  line-height: 1.8;
}

/**
 * Modifiers: size.
 */

.body-copy--size-small {
  font-size: to-rem($font-size-x-small);
}

.body-copy--size-x-small {
  font-size: to-rem($font-size-xx-small);
}

/**
 * Modifiers: color.
 */

.body-copy--color-light {
  color: $color-grey-slate;
}

.body-copy--color-blue {
  color: $color-blue-danube;
}

.body-copy--color-blue-shark {
  color: $color-grey-shark;
}

.body-copy--color-white {
  color: $color-white;
}

/**
 * Modifiers: emphasis.
 */

.body-copy--emphasis {
  color: $color-grey-slate;
  font-size: to-rem($font-size-x-small);
  font-style: italic;
  font-weight: $font-weight-bold;
}
