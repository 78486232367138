/* ============================================================================
   @CORE -> SETTINGS -> COLOURS
   ========================================================================= */

/**
 * Brand.
 */

$color-brand: #2aa198 !default;

/**
 * Black.
 */

$color-black: #000 !default;

/**
 * White.
 */

$color-white: #fff !default;

/**
 * Text.
 */

// Base
$color-text-base: $color-black !default;

/**
 * Primary / Secondary.
 */

$color-primary: $color-brand !default;

$color-secondary: lighten($color-brand, 10%) !default;

/**
 * States.
 */

// Error
$color-state-error: #dc322f !default;

// Success
$color-state-success: #859900 !default;

// Warning
$color-state-warning: #b58900 !default;

// Information
$color-state-information: #268bd2 !default;

/**
 * Text input placeholder colour.
 */

$color-text-input-placeholder: #999 !default;
