@import 'legacy/tokens';

/* ============================================================================
   @LAYOUT -> TEMPLATE -> HEADER
   ========================================================================= */

/**
 * Settings.
 */

$l-header-logo-width: 80;

$l-header-linear-bp: 1020;

$l-header-collapse-bp: 1019;

$l-header-background-colour: $color-grey-shark;

$l-header-foreground-colour: $color-grey-nepal;

$l-header-nav-divider-colour: $color-grey-shark;

.l-header {
  position: fixed;
  width: 100%;
  z-index: index($z-index-elements-global, header);
}

/**
 * Main header (dark blue, fixed)
 */

.l-header__main {
  background-color: $l-header-background-colour;
  color: $l-header-foreground-colour;
  height: to-rem($site-header-height);
}

/**
 * Secondary header when a secondary nav is utilised
 */

.l-header__secondary {
  background-color: $color-white;
  // simulate a border to allow nav items aligned to the bottom of the container
  // to have the active state border sit on top of the container border
  box-shadow: inset 0 -2px 0 $color-grey-mystic;
  height: to-rem($site-header-height);
}

.l-header__inner {
  position: relative;
  display: block;
  height: 100%;
}

/**
 * Logo.
 */

.l-header__logo {
  display: table;
  height: 100%;

  @include respond-to($l-header-linear-bp) {
    display: inline;
  }
}

.l-header__logo__inner {
  padding-left: to-rem($spacing-half);
  display: table-cell;
  vertical-align: middle;

  @include respond-to($l-header-linear-bp) {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 0;
  }
}

.l-header__logo__img {
  width: to-rem($l-header-logo-width);
}

/**
 * Navigation menu container.
 */

.l-header__nav {
  @include respond-to($l-header-collapse-bp, max) {
    // needs to change when we add transitions
    display: none;
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    background-color: $l-header-background-colour;
    border-top: 1px solid $l-header-nav-divider-colour;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    -webkit-font-smoothing: none;
  }

  // Menu open
  &.is-visible {
    display: block;
  }

  @include respond-to($l-header-linear-bp) {
    //currently matching width of logo
    margin-left: to-rem($l-header-logo-width) + to-rem($spacing-base);
    font-size: 0;
  }
}

/**
 * Nav menu item
 *
 * N.B. can be a link or a button.
 */

.l-header__nav__item {
  position: relative;
  vertical-align: top;
  font-size: to-rem($font-size-x-small);

  @include respond-to($l-header-linear-bp) {
    display: inline-block;
  }
}

.l-header__nav__item__link {
  display: block;
  padding: to-rem($spacing-half);
  color: $l-header-foreground-colour;
  font-weight: $font-weight-light;
  text-transform: capitalize;
  line-height: normal;

  &:hover,
  &:focus {
    color: $color-brand;
  }

  @include respond-to($l-header-linear-bp) {
    padding-bottom: to-rem($spacing-base);
    padding-top: to-rem($spacing-base);
  }

  // Active state
  &.is-active {
    color: $color-white;
    font-weight: $font-weight-bold;
  }

  &.is-client {
    display: none;

    @include respond-to($l-header-linear-bp) {
      display: block;
      padding-bottom: to-rem($spacing-base);
      padding-top: to-rem($spacing-base);
    }
  }
}

.l-header__nav__list_client {
  .l-header__nav__item__link {
    padding-left: to-rem($spacing-small);
  }
}

/**
 * Modifier: Alternate nav item
 */

.l-header__nav__item--alt {
  color: $color-brand;

  &:hover,
  &:focus {
    color: $color-white;
  }
}

/**
 * Modifier: Button nav item positioned next to the avatar
 * and inline at linear layout
 */

@include respond-to($l-header-linear-bp) {
  .l-header__nav__item--cta {
    position: absolute;
    top: 50%;
    right: 0;
    margin-right: to-rem(60);
    -webkit-transform-origin-y: -50%;
    transform: translateY(-50%);
  }
}

/**
 * Modifier: Account button
 *
 * Open the account subnav on hover or focus.
 * Currently used in the monolith to bypass
 * writing js for click events
 */

.l-header__nav__item--account {
  text-align: left;
  height: to-rem($site-header-height);

  &:hover,
  &:focus {
    .l-header__nav__sub-nav--account {
      display: block;
    }
  }
}

/**
 * Modifier: button
 *
 * Sign up button is styled like a button at linear layout
 */

@include respond-to($l-header-linear-bp) {
  .l-header__nav__item--button {
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: to-rem($border-radius);
    padding-top: to-rem($spacing-half);
    padding-bottom: to-rem($spacing-half);
    margin-top: to-rem($spacing-half);
    margin-bottom: to-rem($spacing-half);

    &:hover,
    &:focus {
      background-color: $color-brand;
      color: $color-white;
    }
  }
}

/**
 * Modifier: button
 *
 * Sign up button is styled like a primary button at linear layout
 */
@include respond-to($l-header-linear-bp) {
  .l-header__nav__item--button--primary {
    border-radius: to-rem($border-radius);
    padding-top: to-rem($spacing-third);
    padding-bottom: to-rem($spacing-third);
    margin-top: to-rem($spacing-half);
    margin-bottom: to-rem($spacing-half);
    background-color: $color-brand;
    color: $color-white;

    &:hover,
    &:focus {
      background-color: $color-blue-bismark;
      color: $color-white;
    }
  }
}

/**
 * Sub nav menu container.
 */

.l-header__nav__sub-nav {
  //may need to change if we have transitions
  display: none;
  padding-left: to-rem($spacing-base);
  padding-top: to-rem($spacing-small);
  padding-bottom: to-rem($spacing-small);
  @include respond-to($l-header-linear-bp) {
    position: absolute;
    top: 100%;
    left: 0;
    padding-left: 0;
    //temporary
    width: 190px;
    background-color: $color-blue-outer-space;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    z-index: index($z-index-elements-header, sub-nav);
    text-align: left;
  }

  // Menu open
  &.is-visible {
    display: block;
  }

  &.is-client {
    padding-left: 0;
    display: block;

    @include respond-to($l-header-linear-bp) {
      display: none;
    }
  }
}

/**
 * Modifier: right.
 */

.l-header__nav__sub-nav--right {
  left: auto;
  right: 0;
}

/**
 * Sub nav menu item
 */

.l-header__sub-nav-item {
  display: block;
  padding-top: to-rem($spacing-small);
  color: $l-header-foreground-colour;
  font-weight: $font-weight-light;
  text-transform: capitalize;

  @include respond-to($l-header-linear-bp) {
    padding-left: to-rem($spacing-small);
  }

  &:hover,
  &:focus {
    color: $color-brand;
  }

  // Active state
  &.is-active {
    color: $color-white;
    font-weight: $font-weight-bold;
  }

  &.is-client {
    padding-left: to-rem($spacing-medium);
  }
}

/**
 * Sub nav menu override for organisation names
 */
.l-header__sub-nav-item-brand-override {
  text-transform: none;
}

/**
 * Sub nav menu separator text
 */

.l-header--sub-nav-text {
  display: block;
  color: $color-neutral-light-1000;
  line-height: to-rem($line-height-section-title-1);
  font-size: to-rem(14);
  padding-left: to-rem($spacing-small);
}
/**
 * Modifier: sub nav item with a divider.
 */

.l-header__sub-nav-item--divider {
  margin-top: to-rem($spacing-small);
  margin-bottom: to-rem($spacing-small);
  border-bottom: 1px solid $color-neutral-light-150;

  &.is-top {
    margin-top: 0;

    @include respond-to($l-header-linear-bp) {
      display: none;
    }
  }
}

/**
 * Action nav
 */

.l-header__nav__action {
  min-height: 100%;

  @include respond-to($l-header-linear-bp) {
    position: absolute;
    right: 0;
    top: 0;
  }

  // removes any defaut settings for svg
  a svg {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
}

/**
 * Nav trigger (burger menu)
 */

.l-header__nav-trigger {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  @include respond-to($l-header-linear-bp) {
    display: none;
  }
}
