/* ============================================================================
   @CORE -> MIXINS -> GENERATE PERCENTAGE CLASSES AT BREAKPOINTS
   ========================================================================= */

/**
 * Generate percentage classes defined by the settings here: Core -> Settings
 * -> Widths.
 *
 * The default format for these classes is:
 *
    .u-one-whole
    .u-one-half
    .u-one-third
    .u-one-quarter
 *
 * ... and so on.
 *
 * Or in the more terse format:
 *
    .u-100pc
    .u-50pc
    .u-33pc
    .u-25pc
 *
 * ... and so on.
 *
 * The more terse format is turned off by default, to turn it on change this
 * setting: `$mixin-apply-terse-percentage-class-names` to 'true' above the
 * `@import` for this mixin in your master stylesheet e.g.
 *
   $mixin-apply-terse-percentage-class-names: true;
   @import "core/mixins/generate-percentage-classes-at-breakpoints";
 *
 * The percentage classes can be applied at any breakpoint or any of the set
 * breakpoints defined here: Core -> Settings -> Breakpoints. If the breakpoint
 * is set via a `min-width` media query (the default)—say the 'desk'
 * breakpoint—then the format will be: `.u-one-half-from-desk`, but if the
 * breakpoint is set via a `max-width` media query then the format will be:
 * `.u-one-half-up-to-desk`. For more info on how applying the breakpoints
 * work see the `generate-at-breakpoints()` mixin.
 *
 * @example
   @include generate-percentage-classes-at-breakpoints(
     $l-grid-push-apply-at-breakpoints,
     $scally-type: "l",
     $class-name: "push",
     $css-property: "left"
   );
 */

/**
 * Settings.
 */

// The fractions to be used for the classes
$mixin-denominator-numbers: (1 2 3 4 5 6 7 8 9 10 11 12) !default;

$mixin-numerators: (
    one two three four five six seven eight nine ten eleven twelve
  ) !default;

$mixin-denominator-words: (
    whole half third quarter fifth sixth seventh eighth ninth tenth eleventh
      twelfth
  ) !default;

// Apply the more terse format e.g. `.u-50pc`
$mixin-apply-terse-percentage-class-names: false !default;

@mixin generate-percentage-classes-at-breakpoints($breakpoint-names,
  $scally-type: 'u', $class-name: null, $css-property: 'width') {
  @if $class-name {
    $class-name: '#{$class-name}-';
  }

  $all-breakpoint-names: map-keys($breakpoints);

  @if $breakpoint-names == all {
    $breakpoint-names: $all-breakpoint-names;
  }

  @include generate-percentage-classes('', $scally-type, $class-name,
    $css-property);

  @if length($breakpoint-names) == 2 and index((min max),
    nth($breakpoint-names, 2)) {
    $breakpoint-names-copy: $breakpoint-names;
    $breakpoint-names: ();
    $breakpoint-names: append(
      $breakpoint-names,
       (nth($breakpoint-names-copy, 1) nth($breakpoint-names-copy, 2))
    );
  }

  @each $breakpoint-name in $breakpoint-names {
    $limit: 'min';
    $joiner: 'from';

    @if type-of($breakpoint-name) == list {
      $limit: nth($breakpoint-name, 2);
      $breakpoint-name: nth($breakpoint-name, 1);
    }
    @else {
      // Palm is a special case where it uses a `max-width` media query
      $limit: if($breakpoint-name == 'palm', 'max', 'min');
    }

    @if $limit == max {
      $joiner: 'up-to';
    }

    @include respond-to($breakpoint-name, $limit) {
      $breakpoint: '';

      @if $breakpoint-name {
        $suffix: if($limit == 'min', '-from', '-up-to');
        $breakpoint: '#{$suffix}-#{$breakpoint-name}';
      }

      @include generate-percentage-classes($breakpoint, $scally-type,
        $class-name, $css-property);
    }
  }
}

@mixin generate-percentage-classes($breakpoint: '', $scally-type: 'u',
  $class-name: null, $css-property: 'width') {
  @each $denominator in $mixin-denominator-numbers {
    @for $numerator from 1 through max($denominator - 1, 1) {
      $denominator-suffix: if($numerator == 1, '', 's');

      @if $mixin-apply-terse-percentage-class-names {
        $percent-width-class: (calc($numerator/$denominator)) * 100;
        @if round($percent-width-class) == ceil($percent-width-class) {
          $percent-width-class: ceil($percent-width-class);
        }
        @else {
          $percent-width-class: floor($percent-width-class);
        }

        .#{$scally-type}-#{$class-name}#{nth($mixin-numerators,
          $numerator)}-#{nth($mixin-denominator-words,
            $denominator)}#{$denominator-suffix}#{$breakpoint},
        .#{$scally-type}-#{$class-name}#{$percent-width-class}pc#{$breakpoint} {
          #{$css-property}: percentage(calc($numerator/$denominator));
        }
      }
      @else {
        .#{$scally-type}-#{$class-name}#{nth($mixin-numerators,
          $numerator)}-#{nth($mixin-denominator-words,
            $denominator)}#{$denominator-suffix}#{$breakpoint} {
          #{$css-property}: percentage(calc($numerator/$denominator));
        }
      }
    }
  }
}
