/* ============================================================================
   @CORE -> PLACEHOLDERS -> BOTTOM SPACING
   ========================================================================= */

/**
 * Applies bottom spacing in order to try to keep a consistent vertical rhythm.
 */

/**
 * Base.
 */

%bottom-spacing {
  @include to-rem(margin-bottom, $spacing-base);
}

/**
 * Half.
 */

%bottom-spacing-half {
  @include to-rem(margin-bottom, $spacing-half);
}

/**
 * Remove from the last children.
 */

%bottom-spacing,
%bottom-spacing-half {
  &:last-child {
    margin-bottom: 0;
  }
}
