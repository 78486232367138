/* ============================================================================
   @COMPONENTS -> ICON
   ========================================================================= */

/**
 * An icon component for inline SVG's, all modifiers are pretty much different
 * types of icons with one type being the 'default' set which are the new
 * icons we're starting to use.
 *
 * N.B. this component may need to be broken down if it gets too unwieldy.
 */

/**
 * Settings
 */

$c-icon-size-base: 18;

$c-icon-size-small: 16;

$c-icon-size-x-small: 14;

$c-icon-size-xx-small: 12;

$c-icon-size-large: 20;

$c-icon-size-x-large: 24;

$c-icon-size-xx-large: 28;

$c-icon-size-xxx-large: 32;

$c-icon-size-huge: 48;

$c-icon-size-mega: 64;

$c-icon-size-enormous: 80;

.c-icon {
  //display: block;

  width: to-rem($c-icon-size-base);
  height: to-rem($c-icon-size-base);
  fill: currentColor;
}

/**
 * Modifier: sizes
 */

.c-icon--size-xx-small {
  width: to-rem($c-icon-size-xx-small);
  height: to-rem($c-icon-size-xx-small);
}

.c-icon--size-x-small {
  width: to-rem($c-icon-size-x-small);
  height: to-rem($c-icon-size-x-small);
}

.c-icon--size-small {
  width: to-rem($c-icon-size-small);
  height: to-rem($c-icon-size-small);
}

.c-icon--size-large {
  width: to-rem($c-icon-size-large);
  height: to-rem($c-icon-size-large);
}

.c-icon--size-x-large {
  width: to-rem($c-icon-size-x-large);
  height: to-rem($c-icon-size-x-large);
}

.c-icon--size-xx-large {
  width: to-rem($c-icon-size-xx-large);
  height: to-rem($c-icon-size-xx-large);
}

.c-icon--size-xxx-large {
  width: to-rem($c-icon-size-xxx-large);
  height: to-rem($c-icon-size-xxx-large);
}

.c-icon--size-huge {
  width: to-rem($c-icon-size-huge);
  height: to-rem($c-icon-size-huge);
}

.c-icon--size-mega {
  width: to-rem($c-icon-size-mega);
  height: to-rem($c-icon-size-mega);
}

.c-icon--size-enormous {
  width: to-rem($c-icon-size-enormous);
  height: to-rem($c-icon-size-enormous);
}

/**
 * Modifier: orientation.
 */

.c-icon--rotate-180 {
  transform: rotate(180deg);
}

/**
 * Social Icons.
 */

// Facebook
.c-icon--facebook {
  fill: $color-social-facebook;
  color: $color-white;
}

// Twitter
.c-icon--twitter {
  fill: $color-social-twitter;
  color: $color-white;
}

// Linkedin
.c-icon--linkedin {
  fill: $color-social-linkedin;
  color: $color-white;
}

// Googleplus
.c-icon--googleplus {
  fill: $color-social-googleplus;
  color: $color-white;
}

// Blog
.c-icon--blog {
  width: to-rem(38);
  height: to-rem(32);
  color: $color-white;
}

// Bookmark
.c-icon--bookmark {
  color: $color-blue-bismark;
  fill: $color-white;

  &.is-solid {
    fill: $color-blue-bismark;
  }
}

// Star
.c-icon--star {
  color: $color-grey-loblolly;
  fill: $color-white;

  &.is-solid {
    color: $color-yellow-anzac;
    fill: $color-yellow-anzac;
  }
}

.c-icon--star-rating {
  color: $color-blue-danube;
  fill: $color-white;

  &.is-solid {
    fill: $color-blue-danube;
  }
}

.c-icon--star-fav {
  color: $color-yellow-anzac;
}

.c-icon--blue-white {
  color: $color-white;
  fill: $color-blue-danube;
}

/***************************
 * OLD NEED TO BE REPLACED
 ***************************/

/**
 * Modifiers: default set.
 */

.c-icon--default-set {
  width: to-rem(32);
  height: to-rem(32);
}

// Small
.c-icon--default-set-size-small {
  width: to-rem(18);
  height: to-rem(18);
}

// X Small
.c-icon--default-set-size-x-small {
  width: to-rem(12);
  height: to-rem(12);
}

// Medium
.c-icon--default-set-size-medium {
  width: to-rem(24);
  height: to-rem(24);
}

// X Large
.c-icon--default-set-size-x-large {
  width: to-rem(64);
  height: to-rem(64);
}

/**
 * Modifiers: colours.
 */

 // Black
 .c-icon--color-black {
   fill: $color-black;
 }

// White
.c-icon--color-white {
  fill: $color-white;
}

// Brand
.c-icon--color-brand {
  fill: $color-brand;
}

// Orange flamingo (floating action button)
.c-icon--color-orange-flamingo {
  color: $color-orange-flamingo;
}

// Blue Danube (homepage, tick in block-badge)
.c-icon--color-blue-danube {
  color: $color-blue-danube;
}

// Ship cove
.c-icon--color-blue-ship-cove {
  color: $color-grey-nepal;
}

// Grey loblolly (disabled stars)
.c-icon--color-grey-loblolly {
  color: $color-grey-loblolly;
}

// Error
.c-icon--color-error {
  fill: $color-state-error;
}

// Success
.c-icon--color-success {
  fill: $color-state-success;
}

/**
 * Modifier.
 *
 * Refactor these to use a better color naming system.
 */

//Bismark?
.c-icon--color-bismark {
  fill: $color-blue-bismark;
  color: $color-blue-bismark;
}

//Bermuda Grey
.c-icon--color-blue-nepal {
  color: $color-grey-nepal;
}

/**
 * Wire Icons.
 */

.c-icon--wire-dollar {
  width: auto;
  height: to-rem(19);
}

.c-icon--wire-pin {
  width: to-rem(12);
  height: auto;
}

.c-icon--wire-error {
  width: to-rem(24);
  height: to-rem(24);
}

.c-icon--wire-exclamation {
  transform: rotate(180deg);
}

.c-icon--meet {
  fill: $color-grey-nepal;
}

.c-icon--inbox {
  fill: $color-white;
  color: $color-blue-danube;
}
