/* ============================================================================
   @LAYOUT -> TEMPLATE -> SERVER ERROR
   ========================================================================= */

/**
 * Customized template for 404 error page.
 */

.l-server-error-404 {
  padding-top: to-rem($spacing-double);
  padding-bottom: to-rem($spacing-double);
  max-width: to-rem(450);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

/**
 * Custom logo for 404 pages
 */

.l-server-error-404__logo {
  max-width: to-rem(350);
  margin: 0 auto;
  display: block;
}

/**
 * Background image
 */

.l-server-error-404-background-image {
  max-width: to-rem(240);
  width: 100%;
  margin: auto;
  display: block;
}
