.react-nav header,
.react-nav nav {
  display: block
}

.react-nav a {
  background-color: transparent
}

.react-nav a:active,
.react-nav a:hover {
  outline: 0
}

.react-nav img {
  border: 0
}

.react-nav svg:not(:root) {
  overflow: hidden
}

.react-nav button {
  color: inherit;
  font: inherit;
  margin: 0
}

.react-nav button {
  overflow: visible
}

.react-nav button {
  text-transform: none
}

.react-nav button {
  -webkit-appearance: button;
  cursor: pointer
}

.react-nav button::-moz-focus-inner {
  border: 0;
  padding: 0
}

.react-nav a,
.react-nav div,
.react-nav header,
.react-nav img,
.react-nav li,
.react-nav nav,
.react-nav span,
.react-nav ul {
  margin: 0;
  padding: 0;
  border: 0
}

.react-nav *,
.react-nav ::after,
.react-nav ::before {
  box-sizing: inherit
}

.react-nav ul {
  list-style-type: none
}

.react-nav ::-webkit-input-placeholder {
  color: #999
}

.react-nav ::-moz-placeholder {
  color: #999;
  opacity: 1
}

.react-nav :-ms-input-placeholder {
  color: #999
}

.react-nav :input-placeholder {
  color: #999
}

.react-nav ::input-placeholder {
  color: #999
}

.react-nav a {
  color: #f16439;
  text-decoration: none;
  transition: all .15s ease;
  font-weight: 900
}

.react-nav a:focus,
.react-nav a:hover {
  color: #41617d
}

.react-nav .link-hover-opaque {
  color: rgba(255, 255, 255, .64)
}

.react-nav .link-hover-opaque:focus,
.react-nav .link-hover-opaque:hover {
  color: #fff
}

.react-nav img,
.react-nav svg {
  vertical-align: middle
}

.react-nav img {
  max-width: 100%;
  height: auto;
  font-style: italic
}

.react-nav :focus {
  outline-style: dotted;
  outline-width: 1px
}

[data-print="true"] {
  display: none;
}

@media print {
  .react-footer {
    display: none;
  }

  .react-nav *,
  .react-nav ::after,
  .react-nav ::before {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important
  }

  .react-nav nav {
    display: none !important
  }

  .react-nav img {
    max-width: 100% !important;
    height: auto !important
  }

  .react-nav a,
  .react-nav a:visited {
    text-decoration: underline !important;
    border-bottom: 0 !important
  }

  .react-nav a[href]::after {
    content: " (" attr(href) ")"
  }

  .react-nav img {
    page-break-inside: avoid
  }
}

@media (min-width:63.75em) {
  .react-nav .l-container-from-1020 {
    width: 92%;
    min-width: 13.75rem;
    max-width: 76.5rem;
    margin: 0 auto
  }
}

@media print {

  .react-nav {
    display: none;
  }

  .l-main-content>div:first-child,
  .l-main-content-outer {
    padding-top: 0 !important;
  }

  [data-print="true"] {
    display: block;
  }

  [data-print="false"] {
    display: none !important;
  }

  [data-print="true"] * {
    font-family: Lato !important;
  }


  /* main styles */
  [data-print="true"] h1 {
    font-size: 2em !important;
    line-height: 29pt !important;
  }

  [data-print="true"] p {
    font-size: 12pt !important;
    line-height: 18pt !important;
    margin-bottom: 8px !important;
  }

  footer.l-flex-container,
  footer.l-container {
    margin: 0 !important;
    color: #000 !important;
  }

  .react-footer .l-footer {
    padding: 0 !important;
  }

  .react-footer .l-footer {
    padding: 0 !important;
  }

  .react-footer .l-container {
    margin: 0 !important;
    margin-top: 40px !important;
  }

  footer.l-flex-container,
  footer.l-container {
    margin: 0 !important;
    color: #000 !important;
  }

  /* remaining styles are found in index.module.css for PrintProfile.tsx */
}



@page {
  size: A4;
  margin: 14.816666667mm 14.816666667mm 14.816666667mm 14.816666667mm;
}

.react-nav .l-flex-grid {
  display: flex;
  flex-flow: row wrap;
  margin-left: -1.25rem
}

.react-nav .l-flex-grid__item {
  flex-basis: auto;
  padding-left: 1.25rem
}

.react-nav .l-flex-grid__item--align-middle {
  align-self: center
}

.react-nav .c-icon-only-button,
.react-nav .o-button {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: normal;
  display: inline-block;
  vertical-align: middle;
  font-family: inherit;
  font-size: 100%;
  line-height: normal;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  background: 0 0
}

.react-nav .c-icon-only-button,
.react-nav .c-icon-only-button:active,
.react-nav .c-icon-only-button:focus,
.react-nav .c-icon-only-button:hover,
.react-nav .o-button,
.react-nav .o-button:active,
.react-nav .o-button:focus,
.react-nav .o-button:hover {
  text-decoration: none
}

@media (min-width:63.75em) {
  .react-nav .o-list-inline-from-1020>li {
    display: inline-block
  }
}

@media (min-width:63.6875em) {
  .react-nav .u-hide-from-1019 {
    display: none !important
  }
}

@media (max-width:63.6875em) {
  .react-nav .u-hide-up-to-1019 {
    display: none !important
  }
}

.react-nav .u-hide-visually {
  position: absolute !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
  margin: -1px !important;
  padding: 0 !important;
  border: 0 !important
}

.react-nav .u-position-relative {
  position: relative !important
}

.react-nav .u-position-top-left {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important
}

.react-nav .u-s-pt-tiny {
  padding-top: .375rem !important
}

.react-nav .u-s-pr-tiny {
  padding-right: .375rem !important
}

.react-nav .u-s-pr-small {
  padding-right: .75rem !important
}

.react-nav .u-s-ml-base {
  margin-left: 1.5rem !important
}

.react-nav .u-s-pl {
  padding-left: 0.5rem;
  padding-top: 0.4rem;
}

.react-nav .u-s-pl-large {
  padding-left: 2.25rem !important
}

.react-nav .u-s-pe-tiny {
  padding-top: .375rem !important;
  padding-bottom: .375rem !important
}

@media (max-width:63.6875em) {
  .react-nav .u-one-whole-up-to-1019 {
    width: 100%
  }
}

@media (max-width:63.6875em) {
  .react-nav .u-one-whole-up-to-1019 {
    width: 100%
  }
}

.react-nav .l-header {
  position: fixed;
  width: 100%;
  z-index: 8;
}

.react-nav .l-header__main {
  background-color: #21282e;
  color: #97b1c5;
  height: 4.1875rem
}

.react-nav .l-header__inner {
  position: relative;
  display: block;
  height: 100%
}

.react-nav .l-header__logo {
  display: table;
  height: 100%
}

@media (min-width:63.75em) {
  .react-nav .l-header__logo {
    display: inline
  }
}

.react-nav .l-header__logo__inner {
  padding-left: .75rem;
  display: table-cell;
  vertical-align: middle
}

@media (min-width:63.75em) {
  .react-nav .l-header__logo__inner {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 0
  }
}

.react-nav .l-header__logo__img {
  width: 5rem
}

@media (max-width:63.6875em) {
  .react-nav .l-header__nav {
    display: none;
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    background-color: #21282e;
    border-top: 1px solid #21282e;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
    -webkit-font-smoothing: none
  }
}

@media (min-width:63.75em) {
  .react-nav .l-header__nav {
    margin-left: 6.5rem;
    font-size: 0
  }
}

.react-nav .l-header__nav__item {
  position: relative;
  vertical-align: top;
  font-size: .875rem
}

@media (min-width:63.75em) {
  .react-nav .l-header__nav__item {
    display: inline-block
  }
}

.react-nav .l-header__nav__item__link {
  display: block;
  padding: .75rem;
  color: #97b1c5;
  font-weight: 400;
  text-transform: capitalize;
  line-height: normal
}

.react-nav .l-header__nav__item__link:focus,
.react-nav .l-header__nav__item__link:hover {
  color: #f16439
}

@media (min-width:63.75em) {
  .react-nav .l-header__nav__item__link {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem
  }
}

@media (min-width:63.75em) {
  .react-nav .l-header__nav__item__link.avatar {
    padding: 1.2rem 0 0 0;
  }
}

.react-nav .l-header__nav__item__link.is-active {
  color: #fff;
  font-weight: 900
}

.react-nav .l-header__nav__item__link.is-client {
  display: none
}

@media (min-width:63.75em) {
  .react-nav .l-header__nav__item__link.is-client {
    display: block;
    padding-bottom: 1.5rem;
    padding-top: 1.2rem
  }
}

.react-nav .l-header__nav__list_client .l-header__nav__item__link {
  padding-left: 1rem
}

.react-nav .l-header__nav__item--account {
  text-align: left;
  height: 4.1875rem
}

.react-nav .l-header__nav__item--account:focus .l-header__nav__sub-nav--account,
.react-nav .l-header__nav__item--account:hover .l-header__nav__sub-nav--account {
  display: block
}

@media (min-width:63.75em) {
  .react-nav .l-header__nav__item--button--primary {
    border-radius: .1875rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-top: .75rem;
    margin-bottom: .75rem;
    background-color: #047d8c;
    color: #fff
  }

  .react-nav .l-header__nav__item--button--primary:focus,
  .react-nav .l-header__nav__item--button--primary:hover {
    background-color: #014F59;
    color: #fff
  }
}

.react-nav .l-header__nav__sub-nav {
  display: none;
  padding-left: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem
}

@media (min-width:63.75em) {
  .react-nav .l-header__nav__sub-nav {
    position: absolute;
    top: 100%;
    left: 0;
    padding-left: 0;
    width: 190px;
    background-color: #2b333b;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    z-index: 1;
    text-align: left
  }
}

.react-nav .l-header__nav__sub-nav.is-client {
  padding-left: 0;
  display: block
}

@media (min-width:63.75em) {
  .react-nav .l-header__nav__sub-nav.is-client {
    display: none
  }
}

.react-nav .l-header__nav__sub-nav--right {
  left: auto;
  right: 0
}

.react-nav .l-header__sub-nav-item {
  display: block;
  padding-top: 1rem;
  color: #97b1c5;
  font-weight: 400;
  text-transform: capitalize
}

@media (min-width:63.75em) {
  .react-nav .l-header__sub-nav-item {
    padding-left: 1rem
  }
}

.react-nav .l-header__sub-nav-item:focus,
.react-nav .l-header__sub-nav-item:hover {
  color: #f16439
}

.react-nav .l-header__sub-nav-item.is-client {
  padding-left: 1.5rem
}

.react-nav .l-header--sub-nav-text {
  display: block;
  color: #fff;
  line-height: 1rem;
  font-size: .875rem;
  padding-left: 1rem
}

.react-nav .l-header__sub-nav-item--divider {
  height: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, .16)
}

.react-nav .l-header__sub-nav-item--divider.is-top {
  margin-top: 0
}

@media (min-width:63.75em) {
  .react-nav .l-header__sub-nav-item--divider.is-top {
    display: none
  }
}

@media (min-width:63.75em) {
  .react-nav .l-header__nav__action {
    position: absolute;
    right: 0;
    top: 0
  }
}

.react-nav .l-header__nav__action a svg {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0
}

.react-nav .l-header__nav-trigger {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%)
}

@media (min-width:63.75em) {
  .react-nav .l-header__nav-trigger {
    display: none
  }
}

.react-nav .c-icon {
  width: 1.125rem;
  height: 1.125rem;
  fill: currentColor
}

.react-nav .c-icon--size-x-large {
  width: 1.5rem;
  height: 1.5rem
}

.react-nav .c-icon-only-button {
  align-items: center;
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #bec3cc;
  color: #41617d;
  display: inline-flex;
  font-weight: 900;
  font-size: .875rem;
  justify-content: center;
  text-align: center;
  line-height: 1;
  border-radius: .1875rem;
  height: 2.875rem;
  transition: all .3s ease;
  width: 2.875rem
}

html .react-nav .c-icon-only-button {
  border-radius: 3px
}

.react-nav .c-icon-only-button:focus {
  box-shadow: none
}

.react-nav .c-icon-only-button:hover {
  background-color: #41617d;
  box-shadow: none;
  color: #fff
}

.react-nav .c-icon-only-button--menu {
  box-shadow: none;
  background-color: transparent;
  padding-left: .75rem;
  padding-right: .75rem
}

.react-nav .c-icon-only-button--menu:hover {
  background-color: transparent
}

.react-nav .c-icon-only-button--menu span {
  background-color: #97b1c5;
  display: block;
  height: 2px;
  position: relative;
  width: 100%
}

.react-nav .c-icon-only-button--menu span::after,
.react-nav .c-icon-only-button--menu span::before {
  background-color: #97b1c5;
  content: attr(x);
  height: 2px;
  left: 0;
  position: absolute;
  width: 100%
}

.react-nav .c-icon-only-button--menu span::before {
  top: -5.8px
}

.react-nav .c-icon-only-button--menu span::after {
  bottom: -5.8px
}

.react-nav .u-circle {
  border-radius: 50%
}

.react-nav .l-header__nav.is-visible {
  display: block
}

.react-nav .c-notification-badge {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important
}

.react-nav .c-notification-badge {
  width: .875rem;
  height: .875rem;
  border-radius: 50%;
  background: #fff;
  border: solid 5px #f16439;
  box-shadow: 0 0 0 2px #21282e;
  display: none
}

.react-nav .c-notification-badge.c-notification-badge--active {
  display: block
}

.react-nav.with-notification .notification-banner a {
  color: #014f59;
}

.react-nav.with-notification div.notification-banner {
  border-top: 2px solid #014f59;
  background-color: #baeeee;
  height: 60%;
  padding: 0 2px;
  font-size: 10px;
}

.react-nav.with-notification {
  height: 4vh;
}

.react-nav .l-header__main.with-notification {
  height: 8.4rem;
}

.react-nav.with-notification .l-header__inner {
  position: relative;
  display: block;
  height: 40%;
}

@media (min-width:63.75em) {
  .react-nav .l-header__main.with-notification {
    height: 7rem;
  }

  .react-nav.with-notification div.notification-banner {
    border-top: 4px solid #014f59;
    height: 45%;
    padding: 2px 0;
  }

  .react-nav.with-notification .l-header__inner {
    height: 55%;
  }
}