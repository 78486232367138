/* ============================================================================
   @COMPONENTS -> ICON ONLY BUTTON
   ========================================================================= */

/**
 * Settings
 */

$c-icon-only-button-size-small: 32;

$c-icon-only-button-size-small-icon: 14;

$c-icon-only-button-size-x-small: 16;

$c-icon-only-button-size-x-small-icon: 8;

// Menu settings
$menu-bar-width: 2;

$menu-bar-position: -6;

$menu-bar-color: $color-grey-nepal;

/**
 * Icon only button
 */

.c-icon-only-button {
  @extend %o-button;
  align-items: center;
  background-color: $color-white;
  box-shadow: inset 0 0 0 1px $color-grey-loblolly;
  color: $color-blue-bismark;
  display: inline-flex;
  font-weight: $font-weight-bold;
  font-size: to-rem($font-size-x-small);
  justify-content: center;
  text-align: center;
  line-height: 1;
  border-radius: to-rem($c-button-rounded-corners-size);
  height: to-rem($c-button-icon-only-height);
  transition: all 0.3s ease;
  width: to-rem($c-button-icon-only-width);

  // N.B. ugly hack in order to increase the specificity to override the
  // removal of rounded corners that iOS applies set in Core -> Base ->
  // Forms
  html & {
    border-radius: strip-unit($c-button-rounded-corners-size) * 1px;
  }

  &:focus {
    box-shadow: none;
  }

  &:hover {
    background-color: $color-blue-bismark;
    box-shadow: none;
    color: $color-white;
  }
}

/**
 * Modifier: Size small
 */

.c-icon-only-button--size-small {
  height: to-rem($c-icon-only-button-size-small);
  width: to-rem($c-icon-only-button-size-small);

  .c-icon {
    height: to-rem($c-icon-only-button-size-small-icon);
    width: to-rem($c-icon-only-button-size-small-icon);
  }
}

@include respond-to(745) {
  .c-icon-only-button--size-small-from-745 {
    height: to-rem($c-icon-only-button-size-small);
    width: to-rem($c-icon-only-button-size-small);

    .c-icon {
      height: to-rem($c-icon-only-button-size-small-icon);
      width: to-rem($c-icon-only-button-size-small-icon);
    }
  }
}

/**
 * Modifier: Size extra small
 */

.c-icon-only-button--size-x-small {
  height: to-rem($c-icon-only-button-size-x-small);
  width: to-rem($c-icon-only-button-size-x-small);

  .c-icon {
    height: to-rem($c-icon-only-button-size-x-small-icon);
    width: to-rem($c-icon-only-button-size-x-small-icon);
  }
}

/**
 * Borderless
 */

.c-icon-only-button--borderless {
  box-shadow: none;
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
}

/**
 * Primary button
 */

.c-icon-only-button--primary {
  box-shadow: none;
  background-color: $color-brand;
  color: $color-white;
}

/**
 * Clear Background
 */

.c-icon-only-button--no-background {
  background-color: transparent;

  &:hover {
    background-color: transparent;
    color: $color-orange-flamingo;
  }
}

/**
 * Social Icons
 */

// Twitter
.c-icon-only-button--twitter {
  box-shadow: inset 0 0 0 1px $color-social-twitter;
  color: $color-social-twitter;

  &:hover {
    background-color: $color-social-twitter;

    /**
     * Extend `.c-icon into `.c-icon-only-buttons` for social icons
     */
    .c-icon {
      color: $color-white;
    }
  }
}

// LinkedIn
.c-icon-only-button--linkedin {
  box-shadow: inset 0 0 0 1px $color-social-linkedin;
  color: $color-social-linkedin;

  &:hover {
    background-color: $color-social-linkedin;

    /**
     * Extend `.c-icon into `.c-icon-only-buttons` for social icons
     */

    .c-icon {
      color: $color-white;
    }
  }
}

// Facebook
.c-icon-only-button--facebook {
  box-shadow: inset 0 0 0 1px $color-social-facebook;
  color: $color-social-facebook;

  &:hover {
    background-color: $color-social-facebook;

    /**
     * Extend `.c-icon into `.c-icon-only-buttons` for social icons
     */

    .c-icon {
      color: $color-white;
    }
  }
}

// E-Mail
.c-icon-only-button--mail {
  box-shadow: inset 0 0 0 1px $color-blue-bismark;
  color: $color-blue-bismark;

  &:hover {
    background-color: $color-blue-bismark;

    /**
     * Extend `.c-icon into `.c-icon-only-buttons` for social icons
     */

    .c-icon {
      color: $color-white;
    }
  }
}

/**
 * Menu Button
 */

.c-icon-only-button--menu {
  box-shadow: none;
  background-color: transparent;
  padding-left: to-rem($spacing-half);
  padding-right: to-rem($spacing-half);

  &:hover {
    background-color: transparent;
  }
}

/**
 * Menu button bar
 */

.c-icon-only-button--menu span {
  background-color: $menu-bar-color;
  display: block;
  height: $menu-bar-width * 1px;
  position: relative;
  width: 100%;

  &::before,
  &::after {
    background-color: $menu-bar-color;
    content: '';
    height: $menu-bar-width * 1px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  // Top bar
  &::before {
    top: $menu-bar-position * 1px;
  }

  // Bottom bar
  &::after {
    bottom: $menu-bar-position *1px;
  }
}
