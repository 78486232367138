/* ============================================================================
   @CORE -> BASE -> OUTLINE
   ========================================================================= */

/**
 * Customising the outline to be less obtrusive.
 */

:focus {
  outline-style: dotted;
  outline-width: 1px;
}
